import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
             

const DefinitionsList = () => {
  const classes = useStyles();

  return ( <Paper className={classes.paper}>
              <Typography id="definitions" variant="h5" paragraph>
                Definitions
              </Typography>
              <Table>
                  <TableBody>
                      <TableRow>
                        <TableCell className={classes.defName}>Acceptance Criteria.</TableCell><TableCell>A set of conditions that is required to be met before deliverables are accepted.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Accepted Deliverables.</TableCell><TableCell>Products, results, or capabilities produced by a project and validated by the project customer or sponsors as meeting their specified acceptance criteria.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Accuracy.</TableCell><TableCell>Within the quality management system, accuracy is an assessment of correctness.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Acquire Resources.</TableCell><TableCell>The process of obtaining team members, facilities, equipment, materials, supplies, and other resources necessary to complete project work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Acquisition.</TableCell><TableCell>Obtaining human and material resources necessary to perform project activities. Acquisition implies a cost of resources, and is not necessarily financial.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Activity.</TableCell><TableCell>A distinct, scheduled portion of work performed during the course of a project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Activity Attributes.</TableCell><TableCell>Multiple attributes associated with each schedule activity that can be included within the activity list. Activity attributes include activity codes, predecessor activities, successor activities, logical relationships, leads and lags, resource requirements, imposed dates, constraints, and assumptions.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Activity Duration.</TableCell><TableCell>The time in calendar units between the start and finish of a schedule activity. See also duration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Activity Duration Estimates.</TableCell><TableCell>The quantitative assessments of the likely number of time periods that are required to complete an activity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Activity List.</TableCell><TableCell>A documented tabulation of schedule activities that shows the activity description, activity identifier, and a sufficiently detailed scope of work description so project team members understand what work is to be performed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Activity-on-Node (AON).</TableCell><TableCell>See precedence diagramming method (PDM).</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Actual Cost (AC).</TableCell><TableCell>The realized cost incurred for the work performed on an activity during a specific time period.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Actual Duration.</TableCell><TableCell>The time in calendar units between the actual start date of the schedule activity and either the data date of the project schedule if the schedule activity is in progress or the actual finish date if the schedule activity is complete.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Adaptive Life Cycle.</TableCell><TableCell>A project life cycle that is iterative or incremental.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Affinity Diagrams.</TableCell><TableCell>A technique that allows large numbers of ideas to be classified into groups for review and analysis.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Agreements.</TableCell><TableCell>Any document or communication that defines the initial intentions of a project. This can take the form of a contract, memorandum of understanding (MOU), letters of agreement, verbal agreements, email, etc.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Alternative Analysis.</TableCell><TableCell>A technique used to evaluate identified options in order to select the options or approaches to use to execute and perform the work of the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Analogous Estimating.</TableCell><TableCell>A technique for estimating the duration or cost of an activity or a project using historical data from a similar activity or project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Analytical Techniques.</TableCell><TableCell>Various techniques used to evaluate, analyze, or forecast potential outcomes based on possible variations of project or environmental variables and their relationships with other variables.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Assumption.</TableCell><TableCell>A factor in the planning process that is considered to be true, real, or certain, without proof or demonstration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Assumption Log.</TableCell><TableCell>A project document used to record all assumptions and constraints throughout the project life cycle.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Attribute Sampling.</TableCell><TableCell>Method of measuring quality that consists of noting the presence (or absence) of some characteristic (attribute) in each of the units under consideration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Authority.</TableCell><TableCell>The right to apply project resources, expend funds, make decisions, or give approvals.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Backward Pass.</TableCell><TableCell>A critical path method technique for calculating the late start and late finish dates by working backward through the schedule model from the project end date.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Bar Chart.</TableCell><TableCell>A graphic display of schedule-related information. In the typical bar chart, schedule activities or work breakdown structure components are listed down the left side of the chart, dates are shown across the top, and activity durations are shown as date-placed horizontal bars. See also Gantt chart.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Baseline.</TableCell><TableCell>The approved version of a work product that can be changed only through formal change control procedures and is used as a basis for comparison to actual results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Basis of Estimates.</TableCell><TableCell>Supporting documentation outlining the details used in establishing project estimates such as assumptions, constraints, level of detail, ranges, and confidence levels.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Benchmarking.</TableCell><TableCell>Benchmarking is the comparison of actual or planned products, processes, and practices to those of comparable organizations to identify best practices, generate ideas for improvement, and provide a basis for measuring performance.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Benefits Management Plan.</TableCell><TableCell>The documented explanation defining the processes for creating, maximizing, and sustaining the benefits provided by a project or program.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Bid Documents.</TableCell><TableCell>All documents used to solicit information, quotations, or proposals from prospective sellers.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Bidder Conference.</TableCell><TableCell>The meetings with prospective sellers prior to the preparation of a bid or proposal to ensure all prospective vendors have a clear and common understanding of the procurement. Also known as contractor conferences, vendor conferences, or pre-bid conferences.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Bottom-Up Estimating.</TableCell><TableCell>A method of estimating project duration or cost by aggregating the estimates of the lower-level components of the work breakdown structure (WBS).</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Budget.</TableCell><TableCell>The approved estimate for the project or any work breakdown structure component or any schedule activity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Budget at Completion (BAC).</TableCell><TableCell>The sum of all budgets established for the work to be performed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Buffer.</TableCell><TableCell>See reserve.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Business Case.</TableCell><TableCell>A documented economic feasibility study used to establish validity of the benefits of a selected component lacking sufficient definition and that is used as a basis for the authorization of further project management activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Business Value.</TableCell><TableCell>The net quantifiable benefit derived from a business endeavor. The benefit may be tangible, intangible, or both.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cause and Effect Diagram.</TableCell><TableCell>A decomposition technique that helps trace an undesirable effect back to its root cause.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change.</TableCell><TableCell>A modification to any formally controlled deliverable, project management plan component, or project document.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Control.</TableCell><TableCell>A process whereby modifications to documents, deliverables, or baselines associated with the project are identified, documented, approved, or rejected.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Control Board (CCB).</TableCell><TableCell>A formally chartered group responsible for reviewing, evaluating, approving, delaying, or rejecting changes to the project, and for recording and communicating such decisions.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Control System.</TableCell><TableCell>A set of procedures that describes how modifications to the project deliverables and documentation are managed and controlled.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Control Tools.</TableCell><TableCell>Manual or automated tools to assist with change and/or configuration management. At a minimum, the tools should support the activities of the CCB.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Log.</TableCell><TableCell>A comprehensive list of changes submitted during the project and their current status.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Management Plan.</TableCell><TableCell>A component of the project management plan that establishes the change control board, documents the extent of its authority, and describes how the change control system will be implemented.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Change Request.</TableCell><TableCell>A formal proposal to modify a document, deliverable, or baseline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Charter. See project charter.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Checklist Analysis.</TableCell><TableCell>A technique for systematically reviewing materials using a list for accuracy and completeness.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Checksheets.</TableCell><TableCell>A tally sheet that can be used as a checklist when gathering data.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Claim.</TableCell><TableCell>A request, demand, or assertion of rights by a seller against a buyer, or vice versa, for consideration, compensation, or payment under the terms of a legally binding contract, such as for a disputed change.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Claims Administration.</TableCell><TableCell>The process of processing, adjudicating, and communicating contract claims.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Close Project or Phase.</TableCell><TableCell>The process of finalizing all activities for the project, phase, or contract.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Closing Process Group.</TableCell><TableCell>The process(es) performed to formally complete or close a project, phase, or contract.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Code of Accounts.</TableCell><TableCell>A numbering system used to uniquely identify each component of the work breakdown structure (WBS).</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Collect Requirements.</TableCell><TableCell>The process of determining, documenting, and managing stakeholder needs and requirements to meet project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Colocation.</TableCell><TableCell>An organizational placement strategy where the project team members are physically located close to one another in order to improve communication, working relationships, and productivity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Communication Methods.</TableCell><TableCell>A systematic procedure, technique, or process used to transfer information among project stakeholders.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Communication Models.</TableCell><TableCell>A description, analogy, or schematic used to represent how the communication process will be performed for the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Communication Requirements Analysis.</TableCell><TableCell>An analytical technique to determine the information needs of the project stakeholders through interviews, workshops, study of lessons learned from previous projects, etc.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Communications Management Plan.</TableCell><TableCell>A component of the project, program, or portfolio management plan that describes how, when, and by whom information about the project will be administered and disseminated.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Communication Styles Assessment.</TableCell><TableCell>A technique to identify the preferred communication method, format, and content for stakeholders for planned communication activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Communication Technology.</TableCell><TableCell>Specific tools, systems, computer programs, etc., used to transfer information among project stakeholders.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Conduct Procurements.</TableCell><TableCell>The process of obtaining seller responses, selecting a seller, and awarding a contract.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Configuration Management Plan.</TableCell><TableCell>A component of the project management plan that describes how to identify and account for project artifacts under configuration control, and how to record and report changes to them.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Configuration Management System.</TableCell><TableCell>A collection of procedures used to track project artifacts and monitor and control changes to these artifacts.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Conformance.</TableCell><TableCell>Within the quality management system, conformance is a general concept of delivering results that fall within the limits that define acceptable variation for a quality requirement.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Constraint.</TableCell><TableCell>A limiting factor that affects the execution of a project, program, portfolio, or process.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Context Diagrams.</TableCell><TableCell>A visual depiction of the product scope showing a business system (process, equipment, computer system, etc.), and how people and other systems (actors) interact with it.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Contingency.</TableCell><TableCell>An event or occurrence that could affect the execution of the project that may be accounted for with a reserve.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Contingency Reserve.</TableCell><TableCell>Time or money allocated in the schedule or cost baseline for known risks with active response strategies.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Contingent Response Strategies.</TableCell><TableCell>Responses provided which may be used in the event that a specific trigger occurs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Contract.</TableCell><TableCell>A contract is a mutually binding agreement that obligates the seller to provide the specified product or service or result and obligates the buyer to pay for it.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Contract Change Control System.</TableCell><TableCell>The system used to collect, track, adjudicate, and communicate changes to a contract.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control.</TableCell><TableCell>Comparing actual performance with planned performance, analyzing variances, assessing trends to effect process improvements, evaluating possible alternatives, and recommending appropriate corrective action as needed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Account.</TableCell><TableCell>A management control point where scope, budget, actual cost, and schedule are integrated and compared to earned value for performance measurement.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Chart.</TableCell><TableCell>A graphic display of process data over time and against established control limits, which has a centerline that assists in detecting a trend of plotted values toward either control limit.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Costs.</TableCell><TableCell>The process of monitoring the status of the project to update the project costs and manage changes to the cost baseline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Limits.</TableCell><TableCell>The area composed of three standard deviations on either side of the centerline or mean of a normal distribution of data plotted on a control chart, which reflects the expected variation in the data. See also specification limits.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Procurements.</TableCell><TableCell>The process of managing procurement relationships, monitoring contract performance, making changes and corrections as appropriate, and closing out contracts.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Quality.</TableCell><TableCell>The process of monitoring and recording results of executing the quality management activities to assess performance and ensure the project outputs are complete, correct, and meet customer expectations.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Resources.</TableCell><TableCell>The process of ensuring that the physical resources assigned and allocated to the project are available as planned, as well as monitoring the planned versus actual utilization of resources and performing corrective action as necessary.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Schedule.</TableCell><TableCell>The process of monitoring the status of the project to update the project schedule and manage changes to the schedule baseline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Control Scope.</TableCell><TableCell>The process of monitoring the status of the project and product scope and managing changes to the scope baseline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Corrective Action.</TableCell><TableCell>An intentional activity that realigns the performance of the project work with the project management plan.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Aggregation.</TableCell><TableCell>Summing the lower-level cost estimates associated with the various work packages for a given level within the project’s WBS or for a given cost control account.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Baseline.</TableCell><TableCell>The approved version of the time-phased project budget, excluding any management reserves, which can be changed only through formal change control procedures and is used as a basis for comparison to actual results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost-Benefit Analysis.</TableCell><TableCell>A financial analysis tool used to determine the benefits provided by a project against its costs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Management Plan.</TableCell><TableCell>A component of a project or program management plan that describes how costs will be planned, structured, and controlled.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost of Quality (CoQ).</TableCell><TableCell>All costs incurred over the life of the product by investment in preventing nonconformance to requirements, appraisal of the product or service for conformance to requirements, and failure to meet requirements.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Performance Index (CPI).</TableCell><TableCell>A measure of the cost efficiency of budgeted resources expressed as the ratio of earned value to actual cost.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Plus Award Fee Contract (CPAF).</TableCell><TableCell>A category of contract that involves payments to the seller for all legitimate actual costs incurred for completed work, plus an award fee representing seller profit.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Plus Fixed Fee Contract (CPFF).</TableCell><TableCell>A type of cost-reimbursable contract where the buyer reimburses the seller for the seller’s allowable costs (allowable costs are defined by the contract) plus a fixed amount of profit (fee).</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Plus Incentive Fee Contract (CPIF).</TableCell><TableCell>A type of cost-reimbursable contract where the buyer reimburses the seller for the seller’s allowable costs (allowable costs are defined by the contract), and the seller earns its profit if it meets defined performance criteria.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost-Reimbursable Contract.</TableCell><TableCell>A type of contract involving payment to the seller for the seller’s actual costs, plus a fee typically representing the seller’s profit.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cost Variance (CV).</TableCell><TableCell>The amount of budget deficit or surplus at a given point in time, expressed as the difference between the earned value and the actual cost.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Crashing.</TableCell><TableCell>A technique used to shorten the schedule duration for the least incremental cost by adding resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Create WBS.</TableCell><TableCell>The process of subdividing project deliverables and project work into smaller, more manageable components.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Criteria.</TableCell><TableCell>Standards, rules, or tests on which a judgment or decision can be based or by which a product, service, result, or process can be evaluated.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Critical Path.</TableCell><TableCell>The sequence of activities that represents the longest path through a project, which determines the shortest possible duration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Critical Path Activity.</TableCell><TableCell>Any activity on the critical path in a project schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Critical Path Method (CPM).</TableCell><TableCell>A method used to estimate the minimum project duration and determine the amount of schedule flexibility on the logical network paths within the schedule model.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Data.</TableCell><TableCell>Discrete, unorganized, unprocessed measurements or raw observations.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Data Analysis Techniques.</TableCell><TableCell>Techniques used to organize, assess, and evaluate data and information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Data Date.</TableCell><TableCell>A point in time when the status of the project is recorded.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Data Gathering Techniques.</TableCell><TableCell>Techniques used to collect data and information from a variety of sources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Data Representation Techniques.</TableCell><TableCell>Graphic representations or other methods used to convey data and information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Decision-Making Techniques.</TableCell><TableCell>Techniques used to select a course of action from different alternatives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Decision Tree Analysis.</TableCell><TableCell>A diagramming and calculation technique for evaluating the implications of a chain of multiple options in the presence of uncertainty.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Decomposition.</TableCell><TableCell>A technique used for dividing and subdividing the project scope and project deliverables into smaller, more manageable parts.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Defect.</TableCell><TableCell>An imperfection or deficiency in a project component where that component does not meet its requirements or specifications and needs to be either repaired or replaced.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Defect Repair.</TableCell><TableCell>An intentional activity to modify a nonconforming product or product component.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Define Activities.</TableCell><TableCell>The process of identifying and documenting the specific actions to be performed to produce the project deliverables.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Define Scope.</TableCell><TableCell>The process of developing a detailed description of the project and product.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Deliverable.</TableCell><TableCell>Any unique and verifiable product, result, or capability to perform a service that is required to be produced to complete a process, phase, or project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Dependency.</TableCell><TableCell>See logical relationship.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Determine Budget.</TableCell><TableCell>The process of aggregating the estimated costs of individual activities or work packages to establish an authorized cost baseline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Development Approach.</TableCell><TableCell>The method used to create and evolve the product, service, or result during the project life cycle, such as predictive, iterative, incremental, agile, or a hybrid method.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Develop Project Charter.</TableCell><TableCell>The process of developing a document that formally authorizes the existence of a project and provides the project manager with the authority to apply organizational resources to project activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Develop Project Management Plan.</TableCell><TableCell>The process of defining, preparing, and coordinating all plan components and consolidating them into an integrated project management plan.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Develop Schedule.</TableCell><TableCell>The process of analyzing activity sequences, durations, resource requirements, and schedule constraints to create the project schedule model for project execution and monitoring and controlling.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Develop Team.</TableCell><TableCell>The process of improving competences, team member interaction, and overall team environment to enhance project performance.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Diagramming Techniques.</TableCell><TableCell>Approaches to presenting information with logical linkages that aid in understanding.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Direct and Manage Project Work.</TableCell><TableCell>The process of leading and performing the work defined in the project management plan and implementing approved changes to achieve the project’s objectives. Discrete Effort. An activity that can be planned and measured and that yields a specific output. [Note: Discrete effort is one of three earned value management (EVM) types of activities used to measure work performance.]</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Discretionary Dependency.</TableCell><TableCell>A relationship that is established based on knowledge of best practices within a particular application area or an aspect of the project where a specific sequence is desired.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Documentation Reviews.</TableCell><TableCell>The process of gathering a corpus of information and reviewing it to determine accuracy and completeness.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Duration.</TableCell><TableCell>The total number of work periods required to complete an activity or work breakdown structure component, expressed in hours, days, or weeks. Contrast with effort.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Early Finish Date (EF).</TableCell><TableCell>In the critical path method, the earliest possible point in time when the uncompleted portions of a schedule activity can finish based on the schedule network logic, the data date, and any schedule constraints.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Early Start Date (ES).</TableCell><TableCell>In the critical path method, the earliest possible point in time when the uncompleted portions of a schedule activity can start based on the schedule network logic, the data date, and any schedule constraints.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Earned Value (EV).</TableCell><TableCell>The measure of work performed expressed in terms of the budget authorized for that work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Earned Value Management.</TableCell><TableCell>A methodology that combines scope, schedule, and resource measurements to assess project performance and progress.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Effort.</TableCell><TableCell>The number of labor units required to complete a schedule activity or work breakdown structure component, often expressed in hours, days, or weeks. Contrast with duration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Emotional Intelligence.</TableCell><TableCell>The ability to identify, assess, and manage the personal emotions of oneself and other people, as well as the collective emotions of groups of people.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Enterprise Environmental Factors.</TableCell><TableCell>Conditions, not under the immediate control of the team, that influence, constrain, or direct the project, program, or portfolio.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Estimate.</TableCell><TableCell>A quantitative assessment of the likely amount or outcome of a variable, such as project costs, resources, effort, or durations.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Estimate Activity Durations.</TableCell><TableCell>The process of estimating the number of work periods needed to complete individual activities with the estimated resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Estimate Activity Resources.</TableCell><TableCell>The process of estimating team resources and the type and quantities of material, equipment, and supplies necessary to perform project work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Estimate at Completion (EAC).</TableCell><TableCell>The expected total cost of completing all work expressed as the sum of the actual cost to date and the estimate to complete.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Estimate Costs.</TableCell><TableCell>The process of developing an approximation of the monetary resources needed to complete project work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Estimate to Complete (ETC).</TableCell><TableCell>The expected cost to finish all the remaining project work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Execute.</TableCell><TableCell>Directing, managing, performing, and accomplishing the project work; providing the deliverables; and providing work performance information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Executing Process Group.</TableCell><TableCell>Those processes performed to complete the work defined in the project management plan to satisfy the project requirements.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Expert Judgment.</TableCell><TableCell>Judgment provided based upon expertise in an application area, knowledge area, discipline, industry, etc., as appropriate for the activity being performed. Such expertise may be provided by any group or person with specialized education, knowledge, skill, experience, or training.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Explicit Knowledge.</TableCell><TableCell>Knowledge that can be codified using symbols such as words, numbers, and pictures.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>External Dependency.</TableCell><TableCell>A relationship between project activities and non-project activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fallback Plan.</TableCell><TableCell>Fallback plans include an alternative set of actions and tasks available in the event that the primary plan needs to be abandoned because of issues, risks, or other causes.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fast Tracking.</TableCell><TableCell>A schedule compression technique in which activities or phases normally done in sequence are performed in parallel for at least a portion of their duration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fee.</TableCell><TableCell>Represents profit as a component of compensation to a seller.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Finish Date.</TableCell><TableCell>A point in time associated with a schedule activity’s completion. Usually qualified by one of the following: actual, planned, estimated, scheduled, early, late, baseline, target, or current.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Finish-to-Finish (FF).</TableCell><TableCell>A logical relationship in which a successor activity cannot finish until a predecessor activity has finished.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Finish-to-Start (FS).</TableCell><TableCell>A logical relationship in which a successor activity cannot start until a predecessor activity has finished.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Firm Fixed Price Contract (FFP).</TableCell><TableCell>A type of fixed price contract where the buyer pays the seller a set amount (as defined by the contract), regardless of the seller’s costs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fishbone diagram.</TableCell><TableCell>See Cause and Effect Diagram.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fixed-Price Contract.</TableCell><TableCell>An agreement that sets the fee that will be paid for a defined scope of work regardless of the cost or effort to deliver it.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fixed Price Incentive Fee Contract (FPIF).</TableCell><TableCell>A type of contract where the buyer pays the seller a set amount (as defined by the contract), and the seller can earn an additional amount if the seller meets defined performance criteria.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Fixed Price with Economic Price Adjustment Contract (FPEPA).</TableCell><TableCell>A fixed-price contract, but with a special provision allowing for predefined final adjustments to the contract price due to changed conditions, such as inflation changes, or cost increases (or decreases) for specific commodities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Float.</TableCell><TableCell>Also called slack. See total float and free float.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Flowchart.</TableCell><TableCell>The depiction in a diagram format of the inputs, process actions, and outputs of one or more processes within a system.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Focus Groups.</TableCell><TableCell>An elicitation technique that brings together prequalified stakeholders and subject matter experts to learn about their expectations and attitudes about a proposed product, service, or result.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Forecast.</TableCell><TableCell>An estimate or prediction of conditions and events in the project’s future based on information and knowledge available at the time of the forecast.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Forward Pass.</TableCell><TableCell>A critical path method technique for calculating the early start and early finish dates by working forward through the schedule model from the project start date or a given point in time.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Free Float.</TableCell><TableCell>The amount of time that a schedule activity can be delayed without delaying the early start date of any successor or violating a schedule constraint.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Functional Organization.</TableCell><TableCell>An organizational structure in which staff is grouped by areas of specialization and the project manager has limited authority to assign work and apply resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Funding Limit Reconciliation.</TableCell><TableCell>The process of comparing the planned expenditure of project funds against any limits on the commitment of funds for the project to identify any variances between the funding limits and the planned expenditures.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Gantt Chart.</TableCell><TableCell>A bar chart of schedule information where activities are listed on the vertical axis, dates are shown on the horizontal axis, and activity durations are shown as horizontal bars placed according to start and finish dates.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Grade.</TableCell><TableCell>A category or rank used to distinguish items that have the same functional use but do not share the same requirements for quality.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Ground Rules.</TableCell><TableCell>Expectations regarding acceptable behavior by project team members.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Histogram.</TableCell><TableCell>A bar chart that shows the graphical representation of numerical data.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Historical Information.</TableCell><TableCell>Documents and data on prior projects including project files, records, correspondence, closed contracts, and closed projects.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Identify Risks.</TableCell><TableCell>The process of identifying individual risks as well as sources of overall risk and documenting their characteristics.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Identify Stakeholders.</TableCell><TableCell>The process of identifying project stakeholders regularly and analyzing and documenting relevant information regarding their interests, involvement, interdependencies, influence, and potential impact on project success.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Implement Risk Responses.</TableCell><TableCell>The process of implementing agreed-upon risk response plans.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Imposed Date.</TableCell><TableCell>A fixed date imposed on a schedule activity or schedule milestone, usually in the form of a “start no earlier than” and “finish no later than” date.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Incentive Fee.</TableCell><TableCell>A set of financial incentives related to cost, schedule, or technical performance of the seller.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Incremental Life Cycle.</TableCell><TableCell>An adaptive project life cycle in which the deliverable is produced through a series of iterations that successively add functionality within a predetermined time frame. The deliverable contains the necessary and sufficient capability to be considered complete only after the final iteration.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Independent Estimates.</TableCell><TableCell>A process of using a third party to obtain and analyze information to support prediction of cost, schedule, or other items.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Influence Diagram.</TableCell><TableCell>A graphical representation of situations showing causal influences, time ordering of events, and other relationships among variables and outcomes.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Information.</TableCell><TableCell>Organized or structured data, processed for a specific purpose to make it meaningful, valuable, and useful in specific contexts.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Information Management Systems.</TableCell><TableCell>Facilities, processes, and procedures used to collect, store, and distribute information between producers and consumers of information in physical or electronic format.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Initiating Process Group.</TableCell><TableCell>Those processes performed to define a new project or a new phase of an existing project by obtaining authorization to start the project or phase.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Input.</TableCell><TableCell>Any item, whether internal or external to the project, which is required by a process before that process proceeds. May be an output from a predecessor process.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Inspection.</TableCell><TableCell>Examination of a work product to determine whether it conforms to documented standards.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Interpersonal and Team Skills.</TableCell><TableCell>Skills used to effectively lead and interact with team members and other stakeholders.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Interpersonal Skills.</TableCell><TableCell>Skills used to establish and maintain relationships with other people.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Interviews.</TableCell><TableCell>A formal or informal approach to elicit information from stakeholders by talking to them directly.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Invitation for Bid (IFB).</TableCell><TableCell>Generally, this term is equivalent to request for proposal. However, in some application areas, it may have a narrower or more specific meaning.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Issue.</TableCell><TableCell>A current condition or situation that may have an impact on the project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Issue Log.</TableCell><TableCell>A project document where information about issues is recorded and monitored.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Iterative Life Cycle.</TableCell><TableCell>A project life cycle where the project scope is generally determined early in the project life cycle, but time and cost estimates are routinely modified as the project team’s understanding of the product increases. Iterations develop the product through a series of repeated cycles, while increments successively add to the functionality of the product.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Knowledge.</TableCell><TableCell>A mixture of experience, values and beliefs, contextual information, intuition, and insight that people use to make sense of new experiences and information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Lag.</TableCell><TableCell>The amount of time whereby a successor activity will be delayed with respect to a predecessor activity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Late Finish Date (LF).</TableCell><TableCell>In the critical path method, the latest possible point in time when the uncompleted portions of a schedule activity can finish based on the schedule network logic, the project completion date, and any schedule constraints.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Late Start Date (LS).</TableCell><TableCell>In the critical path method, the latest possible point in time when the uncompleted portions of a schedule activity can start based on the schedule network logic, the project completion date, and any schedule constraints.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Lead.</TableCell><TableCell>The amount of time whereby a successor activity can be advanced with respect to a predecessor activity. </TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Lessons Learned.</TableCell><TableCell>The knowledge gained during a project which shows how project events were addressed or should be addressed in the future for the purpose of improving future performance.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Lessons Learned Register.</TableCell><TableCell>A project document used to record knowledge gained during a project so that it can be used in the current project and entered into the lessons learned repository.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Lessons Learned Repository.</TableCell><TableCell>A store of historical information about lessons learned in projects.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Level of Effort (LOE).</TableCell><TableCell>An activity that does not produce definitive end products and is measured by the passage of time.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Cycle.</TableCell><TableCell>See project life cycle.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Log.</TableCell><TableCell>A document used to record and describe or denote selected items identified during execution of a process or activity. Usually used with a modifier, such as issue, change, issue, or assumption.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Logical Relationship.</TableCell><TableCell>A dependency between two activities, or between an activity and a milestone.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Make-or-Buy Analysis.</TableCell><TableCell>The process of gathering and organizing data about product requirements and analyzing them against available alternatives including the purchase or internal manufacture of the product.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Make-or-Buy Decisions.</TableCell><TableCell>Decisions made regarding the external purchase or internal manufacture of a product.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Manage Communications.</TableCell><TableCell>Manage Communications is the process of ensuring timely and appropriate collection, creation, distribution, storage, retrieval, management, monitoring, and the ultimate disposition of project information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Management Reserve.</TableCell><TableCell>An amount of the project budget or project schedule held outside of the performance measurement baseline (PMB) for management control purposes, that is reserved for unforeseen work that is within scope of the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Management Skills.</TableCell><TableCell>The ability to plan, organize, direct, and control individuals or groups of people to achieve specific goals.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Manage Project Knowledge.</TableCell><TableCell>The process of using existing knowledge and creating new knowledge to achieve the project’s objectives and contribute to organizational learning.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Manage Quality.</TableCell><TableCell>The process of translating the quality management plan into executable quality activities that incorporate the organization’s quality policies into the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Manage Stakeholder Engagement.</TableCell><TableCell>The process of communicating and working with stakeholders to meet their needs and expectations, address issues, and foster appropriate stakeholder involvement.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Manage Team.</TableCell><TableCell>The process of tracking team member performance, providing feedback, resolving issues, and managing team changes to optimize project performance.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Mandatory Dependency.</TableCell><TableCell>A relationship that is contractually required or inherent in the nature of the work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Master Schedule.</TableCell><TableCell>A summary-level project schedule that identifies the major deliverables and work breakdown structure components and key schedule milestones. See also milestone schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Matrix Diagrams.</TableCell><TableCell>A quality management and control tool used to perform data analysis within the organizational structure created in the matrix. The matrix diagram seeks to show the strength of relationships between factors, causes, and objectives that exist between the rows and columns that form the matrix.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Matrix Organization.</TableCell><TableCell>Any organizational structure in which the project manager shares responsibility with the functional managers for assigning priorities and for directing the work of persons assigned to the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Methodology.</TableCell><TableCell>A system of practices, techniques, procedures, and rules used by those who work in a discipline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Milestone.</TableCell><TableCell>A significant point or event in a project, program, or portfolio.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Milestone Schedule.</TableCell><TableCell>A type of schedule that presents milestones with planned dates. See also master schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Mind-Mapping.</TableCell><TableCell>A technique used to consolidate ideas created through individual brainstorming sessions into a single map to reflect commonality and differences in understanding and to generate new ideas.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monitor.</TableCell><TableCell>Collect project performance data, produce performance measures, and report and disseminate performance information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monitor and Control Project Work.</TableCell><TableCell>The process of tracking, reviewing, and reporting overall progress to meet the performance objectives defined in the project management plan.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monitor Communications.</TableCell><TableCell>The process of ensuring that the information needs of the project and its stakeholders are met.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monitoring and Controlling Process Group.</TableCell><TableCell>Those processes required to track, review, and regulate the progress and performance of the project; identify any areas in which changes to the plan are required; and initiate the corresponding changes.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monitor Risks.</TableCell><TableCell>The process of monitoring the implementation of agreed-upon risk response plans, tracking identified risks, identifying and analyzing new risks, and evaluating risk process effectiveness throughout the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monitor Stakeholder Engagement.</TableCell><TableCell>The process of monitoring project stakeholder relationships, and tailoring strategies for engaging stakeholders through the modification of engagement strategies and plans.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Monte Carlo Simulation.</TableCell><TableCell>An analysis technique where a computer model is iterated many times, with the input values chosen at random for each iteration driven by the input data, including probability distributions and probabilistic branches. Outputs are generated to represent the range of possible outcomes for the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Multicriteria Decision Analysis.</TableCell><TableCell>This technique utilizes a decision matrix to provide a systematic analytical approach for establishing criteria, such as risk levels, uncertainty, and valuation, to evaluate and rank many ideas.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Network.</TableCell><TableCell>See project schedule network diagram. </TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Network Logic.</TableCell><TableCell>All activity dependencies in a project schedule network diagram.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Network Path.</TableCell><TableCell>A sequence of activities connected by logical relationships in a project schedule network diagram.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Networking.</TableCell><TableCell>Establishing connections and relationships with other people from the same or other organizations.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Node.</TableCell><TableCell>A point at which dependency lines connect on a schedule network diagram.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Nominal Group Technique.</TableCell><TableCell>A technique that enhances brainstorming with a voting process used to rank the most useful ideas for further brainstorming or for prioritization.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Objective.</TableCell><TableCell>Something toward which work is to be directed, a strategic position to be attained, a purpose to be achieved, a result to be obtained, a product to be produced, or a service to be performed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Opportunity.</TableCell><TableCell>A risk that would have a positive effect on one or more project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Organizational Breakdown Structure (OBS).</TableCell><TableCell>A hierarchical representation of the project organization, which illustrates units that will perform those activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Organizational Learning.</TableCell><TableCell>A discipline concerned with the way individuals, groups, and organizations develop knowledge.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Organizational Process Assets.</TableCell><TableCell>Plans, processes, policies, procedures, and knowledge bases that are specific to and used by the performing organization.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Output.</TableCell><TableCell>A product, result, or service generated by a process. May be an input to a successor process.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Overall Project Risk.</TableCell><TableCell>The effect of uncertainty on the project as a whole, arising from all sources of uncertainty including individual risks, representing the exposure of stakeholders to the implications of variations in project outcome, both positive and negative.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Parametric Estimating.</TableCell><TableCell>An estimating technique in which an algorithm is used to calculate cost or duration based on historical data and project parameters.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Path Convergence.</TableCell><TableCell>A relationship in which a schedule activity has more than one predecessor.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Path Divergence.</TableCell><TableCell>A relationship in which a schedule activity has more than one successor.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Percent Complete.</TableCell><TableCell>An estimate expressed as a percent of the amount of work that has been completed on an activity or a work breakdown structure component.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Performance Measurement Baseline (PMB).</TableCell><TableCell>Integrated scope, schedule, and cost baselines used for comparison to manage, measure, and control project execution.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Performance Reviews.</TableCell><TableCell>A technique that is used to measure, compare, and analyze actual performance of work in progress on the project against the baseline.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Perform Integrated Change Control.</TableCell><TableCell>The process of reviewing all change requests; approving changes and managing changes to deliverables, organizational process assets, project documents, and the project management plan; and communicating the decisions.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Perform Qualitative Risk Analysis.</TableCell><TableCell>The process of prioritizing individual project risks for further analysis or action by assessing their probability of occurrence and impact as well as other characteristics.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Perform Quantitative Risk Analysis.</TableCell><TableCell>The process of numerically analyzing the combined effect of identified individual project risks and other sources of uncertainty on overall project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Phase.</TableCell><TableCell>See project phase.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Phase Gate.</TableCell><TableCell>A review at the end of a phase in which a decision is made to continue to the next phase, to continue with modification, or to end a project or program.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Communications Management.</TableCell><TableCell>The process of developing an appropriate approach and plan for project communication activities based on the information needs of each stakeholder or group, available organizational assets, and the needs of the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Cost Management.</TableCell><TableCell>The process of defining how the project costs will be estimated, budgeted, managed, monitored, and controlled.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Planned Value (PV).</TableCell><TableCell>The authorized budget assigned to scheduled work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Planning Package.</TableCell><TableCell>A work breakdown structure component below the control account with known work content but without detailed schedule activities. See also control account.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Planning Process Group.</TableCell><TableCell>Those processes required to establish the scope of the project, refine the objectives, and define the course of action required to attain the objectives that the project was undertaken to achieve.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Procurement Management.</TableCell><TableCell>The process of documenting project procurement decisions, specifying the approach, and identifying potential sellers.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Quality Management.</TableCell><TableCell>The process of identifying quality requirements and/or standards for the project and its deliverables, and documenting how the project will demonstrate compliance with quality requirements and/or standards.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Resource Management.</TableCell><TableCell>The process of defining how to estimate, acquire, manage, and utilize physical and team resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Risk Management.</TableCell><TableCell>The process of defining how to conduct risk management activities for a project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Risk Responses.</TableCell><TableCell>The process of developing options, selecting strategies, and agreeing on actions to address overall project risk exposure, as well as to treat individual project risks.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Schedule Management.</TableCell><TableCell>The process of establishing the policies, procedures, and documentation for planning, developing, managing, executing, and controlling the project schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Scope Management.</TableCell><TableCell>The process of creating a scope management plan that documents how the project and product scope will be defined, validated, and controlled.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plan Stakeholder Engagement.</TableCell><TableCell>The process of developing approaches to involve project stakeholders, based on their needs, expectations, interests, and potential impact on the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Plurality.</TableCell><TableCell>Decisions made by the largest block in a group, even if a majority is not achieved.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Policy.</TableCell><TableCell>A structured pattern of actions adopted by an organization such that the organization’s policy can be explained as a set of basic principles that govern the organization’s conduct.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Portfolio.</TableCell><TableCell>Projects, programs, subsidiary portfolios, and operations managed as a group to achieve strategic objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Portfolio Management.</TableCell><TableCell>The centralized management of one or more portfolios to achieve strategic objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Practice.</TableCell><TableCell>A specific type of professional or management activity that contributes to the execution of a process and that may employ one or more techniques and tools.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Precedence Diagramming Method (PDM).</TableCell><TableCell>A technique used for constructing a schedule model in which activities are represented by nodes and are graphically linked by one or more logical relationships to show the sequence in which the activities are to be performed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Precedence Relationship.</TableCell><TableCell>A logical dependency used in the precedence diagramming method.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Predecessor Activity.</TableCell><TableCell>An activity that logically comes before a dependent activity in a schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Predictive Life Cycle.</TableCell><TableCell>A form of project life cycle in which the project scope, time, and cost are determined in the early phases of the life cycle.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Preventive Action.</TableCell><TableCell>An intentional activity that ensures the future performance of the project work is aligned with the project management plan.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Probability and Impact Matrix.</TableCell><TableCell>A grid for mapping the probability of occurrence of each risk and its impact on project objectives if that risk occurs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procedure.</TableCell><TableCell>An established method of accomplishing a consistent performance or result, a procedure typically can be described as the sequence of steps that will be used to execute a process.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Process.</TableCell><TableCell>A systematic series of activities directed towards causing an end result such that one or more inputs will be acted upon to create one or more outputs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procurement Audits.</TableCell><TableCell>The review of contracts and contracting processes for completeness, accuracy, and effectiveness.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procurement Documents.</TableCell><TableCell>The documents utilized in bid and proposal activities, which include the buyer’s Invitation for bid, invitation for negotiations, request for information, request for quotation, request for proposal, and seller’s responses.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procurement Documentation.</TableCell><TableCell>All documents used in signing, executing, and closing an agreement. Procurement documentation may include documents predating the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procurement Management Plan.</TableCell><TableCell>A component of the project or program management plan that describes how </TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procurement Statement of Work.</TableCell><TableCell>Describes the procurement item in sufficient detail to allow prospective sellers to determine if they are capable of providing the products, services, or results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Procurement Strategy.</TableCell><TableCell>The approach by the buyer to determine the project delivery method and the type of legally binding agreement(s) that should be used to deliver the desired results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Product.</TableCell><TableCell>An artifact that is produced, is quantifiable, and can be either an end item in itself or a component item.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Additional words for products are material and goods.</TableCell><TableCell>See also deliverable.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Product Analysis.</TableCell><TableCell>For projects that have a product as a deliverable, it is a tool to define scope that generally means asking questions about a product and forming answers to describe the use, characteristics, and other relevant aspects of what is going to be manufactured.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Product Life Cycle.</TableCell><TableCell>The series of phases that represent the evolution of a product, from concept through delivery, growth, maturity, and to retirement.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Product Scope.</TableCell><TableCell>The features and functions that characterize a product, service, or result.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Product Scope Description.</TableCell><TableCell>The documented narrative description of the product scope.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Program.</TableCell><TableCell>Related projects, subsidiary programs, and program activities that are managed in a coordinated manner to obtain benefits not available from managing them individually.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Program Management.</TableCell><TableCell>The application of knowledge, skills, and principles to a program to achieve the program objectives and obtain benefits and control not available by managing program components individually.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Progressive Elaboration.</TableCell><TableCell>The iterative process of increasing the level of detail in a project management plan as greater amounts of information and more accurate estimates become available.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project.</TableCell><TableCell>A temporary endeavor undertaken to create a unique product, service, or result.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Calendar.</TableCell><TableCell>A calendar that identifies working days and shifts that are available for scheduled activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Charter.</TableCell><TableCell>A document issued by the project initiator or sponsor that formally authorizes the existence of a project and provides the project manager with the authority to apply organizational resources to project activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Communications Management</TableCell><TableCell>Project Communications Management includes the processes required to ensure timely and appropriate planning, collection, creation, distribution, storage, retrieval, management, control, monitoring, and ultimate disposition of project information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Cost Management.</TableCell><TableCell>Project Cost Management includes the processes involved in planning, estimating, budgeting, financing, funding, managing, and controlling costs so the project can be completed within the approved budget.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Funding Requirements.</TableCell><TableCell>Forecast project costs to be paid that are derived from the cost baseline for total or periodic requirements, including projected expenditures plus anticipated liabilities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Governance.</TableCell><TableCell>The framework, functions, and processes that guide project management activities in order to create a unique product, service, or result to meet organizational, strategic, and operational goals. a project team will acquire goods and services from outside of the performing organization.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Initiation.</TableCell><TableCell>Launching a process that can result in the authorization of a new project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Integration Management.</TableCell><TableCell>Project Integration Management includes the processes and activities to identify, define, combine, unify, and coordinate the various processes and project management activities within the Project Management Process Groups.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Life Cycle.</TableCell><TableCell>The series of phases that a project passes through from its start to its completion.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management.</TableCell><TableCell>The application of knowledge, skills, tools, and techniques to project activities to meet the project requirements.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Body of Knowledge.</TableCell><TableCell>A term that describes the knowledge within the profession of project management. The project management body of knowledge includes proven traditional practices that are widely applied as well as innovative practices that are emerging in the profession.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Information System.</TableCell><TableCell>An information system consisting of the tools and techniques used to gather, integrate, and disseminate the outputs of project management processes.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Knowledge Area.</TableCell><TableCell>An identified area of project management defined by its knowledge requirements and described in terms of its component processes, practices, inputs, outputs, tools, and techniques.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Office (PMO).</TableCell><TableCell>A management structure that standardizes the project-related governance processes and facilitates the sharing of resources, methodologies, tools, and techniques.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Plan.</TableCell><TableCell>The document that describes how the project will be executed, monitored and controlled, and closed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Process Group.</TableCell><TableCell>A logical grouping of project management inputs, tools and techniques, and outputs. The Project Management Process Groups include initiating processes, planning processes, executing processes, monitoring and controlling processes, and closing processes. Project Management Process Groups are not project phases.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management System.</TableCell><TableCell>The aggregation of the processes, tools, techniques, methodologies, resources, and procedures to manage a project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Management Team.</TableCell><TableCell>The members of the project team who are directly involved in project management activities. See also Project Team.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Manager (PM).</TableCell><TableCell>The person assigned by the performing organization to lead the team that is responsible for achieving the project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Organization Chart.</TableCell><TableCell>A document that graphically depicts the project team members and their interrelationships for a specific project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Phase.</TableCell><TableCell>A collection of logically related project activities that culminates in the completion of one or more deliverables.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Procurement Management.</TableCell><TableCell>Project Procurement Management includes the processes necessary to purchase or acquire products, services, or results needed from outside the project team.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Quality Management.</TableCell><TableCell>Project Quality Management includes the processes for incorporating the organization’s quality policy regarding planning, managing, and controlling project and product quality requirements, in order to meet stakeholders’ expectations.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Resource Management.</TableCell><TableCell>Project Resource Management includes the processes to identify, acquire, and manage the resources needed for the successful completion of the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Risk Management.</TableCell><TableCell>Project Risk Management includes the processes of conducting risk management planning, identification, analysis, response planning, response implementation, and monitoring risk on a project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Schedule.</TableCell><TableCell>An output of a schedule model that presents linked activities with planned dates, durations, milestones, and resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Schedule Management.</TableCell><TableCell>Project Schedule Management includes the processes required to manage the timely completion of the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Schedule Network Diagram.</TableCell><TableCell>A graphical representation of the logical relationships among the project schedule activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Scope.</TableCell><TableCell>The work performed to deliver a product, service, or result with the specified features and functions.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Scope Management.</TableCell><TableCell>Project Scope Management includes the processes required to ensure that the project includes all the work required, and only the work required, to complete the project successfully.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Scope Statement.</TableCell><TableCell>The description of the project scope, major deliverables, assumptions, and constraints.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Stakeholder Management.</TableCell><TableCell>Project Stakeholder Management includes the processes required to identify the people, groups, or organizations that could impact or be impacted by the project, to analyze stakeholder expectations and their impact on the project, and to develop appropriate management strategies for effectively engaging stakeholders in project decisions and execution.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Team.</TableCell><TableCell>A set of individuals who support the project manager in performing the work of the project to achieve its objectives. See also Project Management Team.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Project Team Directory.</TableCell><TableCell>A documented list of project team members, their project roles, and communication information.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Proposal Evaluation Techniques.</TableCell><TableCell>The process of reviewing proposals provided by suppliers to support contract award decisions.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Prototypes.</TableCell><TableCell>A method of obtaining early feedback on requirements by providing a working model of the expected product before actually building it.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality.</TableCell><TableCell>The degree to which a set of inherent characteristics fulfills requirements.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Audits.</TableCell><TableCell>A quality audit is a structured, independent process to determine if project activities comply with organizational and project policies, processes, and procedures.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Checklists.</TableCell><TableCell>A structured tool used to verify that a set of required steps has been performed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Control Measurements.</TableCell><TableCell>The documented results of control quality activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Management Plan.</TableCell><TableCell>A component of the project or program management plan that describes how applicable policies, procedures, and guidelines will be implemented to achieve the quality objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Management System.</TableCell><TableCell>The organizational framework whose structure provides the policies, processes, procedures, and resources required to implement the quality management plan. The typical project quality management plan should be compatible to the organization’s quality management system.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Metrics.</TableCell><TableCell>A description of a project or product attribute and how to measure it.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Policy.</TableCell><TableCell>A policy specific to the Project Quality Management Knowledge Area, it establishes the basic principles that should govern the organization’s actions as it implements its system for quality management.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Report.</TableCell><TableCell>A project document that includes quality management issues, recommendations for corrective actions, and a summary of findings from quality control activities and may include recommendations for process, project, and product improvements.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Quality Requirement.</TableCell><TableCell>A condition or capability that will be used to assess conformance by validating the acceptability of an attribute for the quality of a result.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Questionnaires.</TableCell><TableCell>Written sets of questions designed to quickly accumulate information from a large number of respondents.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>RACI Chart.</TableCell><TableCell>A common type of responsibility assignment matrix that uses responsible, accountable, consult, and inform statuses to define the involvement of stakeholders in project activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Regression Analysis.</TableCell><TableCell>An analytical technique where a series of input variables are examined in relation to their corresponding output results in order to develop a mathematical or statistical relationship.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Regulations.</TableCell><TableCell>Requirements imposed by a governmental body. These requirements can establish product, process, or service characteristics, including applicable administrative provisions that have government-mandated compliance.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Request for Information (RFI).</TableCell><TableCell>A type of procurement document whereby the buyer requests a potential seller to provide various pieces of information related to a product or service or seller capability.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Request for Proposal (RFP).</TableCell><TableCell>A type of procurement document used to request proposals from prospective sellers of products or services. In some application areas, it may have a narrower or more specific meaning.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Request for Quotation (RFQ).</TableCell><TableCell>A type of procurement document used to request price quotations from prospective sellers of common or standard products or services. Sometimes used in place of request for proposal and, in some application areas, it may have a narrower or more specific meaning.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Requirement.</TableCell><TableCell>A condition or capability that is necessary to be present in a product, service, or result to satisfy a business need.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Requirements Documentation.</TableCell><TableCell>A description of how individual requirements meet the business need for the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Requirements Management Plan.</TableCell><TableCell>A component of the project or program management plan that describes how requirements will be analyzed, documented, and managed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Requirements Traceability Matrix.</TableCell><TableCell>A grid that links product requirements from their origin to the deliverables that satisfy them.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Reserve.</TableCell><TableCell>A provision in the project management plan to mitigate cost and/or schedule risk. Often used with a modifier (e.g., management reserve, contingency reserve) to provide further detail on what types of risk are meant to be mitigated.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Reserve Analysis.</TableCell><TableCell>An analytical technique to determine the essential features and relationships of components in the project management plan to establish a reserve for the schedule duration, budget, estimated cost, or funds for a project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Residual Risk.</TableCell><TableCell>The risk that remains after risk responses have been implemented.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource.</TableCell><TableCell>A team member or any physical item needed to complete the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Breakdown Structure.</TableCell><TableCell>A hierarchical representation of resources by category and type.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Calendar.</TableCell><TableCell>A calendar that identifies the working days and shifts upon which each specific resource is available.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Histogram.</TableCell><TableCell>A bar chart showing the amount of time that a resource is scheduled to work over a series of time periods.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Leveling.</TableCell><TableCell>A resource optimization technique in which adjustments are made to the project schedule to optimize the allocation of resources and which may affect critical path. See also resource optimization technique and resource smoothing.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Management Plan.</TableCell><TableCell>A component of the project management plan that describes how project resources are acquired, allocated, monitored, and controlled.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Manager.</TableCell><TableCell>An individual with management authority over one or more resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Optimization Technique.</TableCell><TableCell>A technique in which activity start and finish dates are adjusted to balance demand for resources with the available supply. See also resource leveling and resource smoothing.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Requirements.</TableCell><TableCell>The types and quantities of resources required for each activity in a work package.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Resource Smoothing.</TableCell><TableCell>A resource optimization technique in which free and total float are used without affecting the critical path. See also resource leveling and resource optimization technique.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Responsibility.</TableCell><TableCell>An assignment that can be delegated within a project management plan such that the assigned resource incurs a duty to perform the requirements of the assignment.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Responsibility Assignment Matrix (RAM).</TableCell><TableCell>A grid that shows the project resources assigned to each work package.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Result.</TableCell><TableCell>An output from performing project management processes and activities. Results include outcomes (e.g., integrated systems, revised process, restructured organization, tests, trained personnel, etc.) and documents (e.g., policies, plans, studies, procedures, specifications, reports, etc.). See also deliverable.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Rework.</TableCell><TableCell>Action taken to bring a defective or nonconforming component into compliance with requirements or specifications.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk.</TableCell><TableCell>An uncertain event or condition that, if it occurs, has a positive or negative effect on one or more project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Acceptance.</TableCell><TableCell>A risk response strategy whereby the project team decides to acknowledge the risk and not take any action unless the risk occurs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Appetite.</TableCell><TableCell>The degree of uncertainty an organization or individual is willing to accept in anticipation of a reward.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Audit.</TableCell><TableCell>A type of audit used to consider the effectiveness of the risk management process.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Avoidance.</TableCell><TableCell>A risk response strategy whereby the project team acts to eliminate the threat or protect the project from its impact.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Breakdown Structure (RBS).</TableCell><TableCell>A hierarchical representation of potential sources of risks.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Categorization.</TableCell><TableCell>Organization by sources of risk (e.g., using the RBS), the area of the project affected (e.g., using the WBS), or other useful category (e.g., project phase) to determine the areas of the project most exposed to the effects of uncertainty.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Category.</TableCell><TableCell>A group of potential causes of risk.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Data Quality Assessment.</TableCell><TableCell>Technique to evaluate the degree to which the data about risks is useful for risk management.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Enhancement.</TableCell><TableCell>A risk response strategy whereby the project team acts to increase the probability of occurrence or impact of an opportunity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Escalation.</TableCell><TableCell>A risk response strategy whereby the team acknowledges that a risk is outside of its sphere of influence and shifts the ownership of the risk to a higher level of the organization where it is more effectively managed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Exploiting.</TableCell><TableCell>A risk response strategy whereby the project team acts to ensure that an opportunity occurs.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Exposure.</TableCell><TableCell>An aggregate measure of the potential impact of all risks at any given point in time in a project, program, or portfolio.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Management Plan.</TableCell><TableCell>A component of the project, program, or portfolio management plan that describes how risk management activities will be structured and performed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Mitigation.</TableCell><TableCell>A risk response strategy whereby the project team acts to decrease the probability of occurrence or impact of a threat.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Owner.</TableCell><TableCell>The person responsible for monitoring the risks and for selecting and implementing an appropriate risk response strategy.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Register.</TableCell><TableCell>A repository in which outputs of risk management processes are recorded.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Report.</TableCell><TableCell>A project document developed progressively throughout the Project Risk Management processes, which summarizes information on individual project risks and the level of overall project risk.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Review.</TableCell><TableCell>A meeting to examine and document the effectiveness of risk responses in dealing with overall project risk and with identified individual project risks.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Sharing.</TableCell><TableCell>A risk response strategy whereby the project team allocates ownership of an opportunity to a third party who is best able to capture the benefit of that opportunity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Threshold.</TableCell><TableCell>The level of risk exposure above which risks are addressed and below which risks may be accepted.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Risk Transference.</TableCell><TableCell>A risk response strategy whereby the project team shifts the impact of a threat to a third party, together with ownership of the response.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Role.</TableCell><TableCell>A defined function to be performed by a project team member, such as testing, filing, inspecting, or coding.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Rolling Wave Planning.</TableCell><TableCell>An iterative planning technique in which the work to be accomplished in the near term is planned in detail, while the work in the future is planned at a higher level.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Root Cause Analysis.</TableCell><TableCell>An analytical technique used to determine the basic underlying reason that causes a variance or a defect or a risk. A root cause may underlie more than one variance or defect or risk.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule.</TableCell><TableCell>See project schedule and schedule model.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Baseline.</TableCell><TableCell>The approved version of a schedule model that can be changed using formal change control procedures and is used as the basis for comparison to actual results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Compression.</TableCell><TableCell>A technique used to shorten the schedule duration without reducing the project scope.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Data.</TableCell><TableCell>The collection of information for describing and controlling the schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Forecasts.</TableCell><TableCell>Estimates or predictions of conditions and events in the project’s future based on information and knowledge available at the time the schedule is calculated.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Management Plan.</TableCell><TableCell>A component of the project or program management plan that establishes the criteria and the activities for developing, monitoring, and controlling the schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Model.</TableCell><TableCell>A representation of the plan for executing the project’s activities including durations, dependencies, and other planning information, used to produce a project schedule along with other scheduling artifacts.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Network Analysis.</TableCell><TableCell>A technique to identify early and late start dates, as well as early and late finish dates, for the uncompleted portions of project activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Performance Index (SPI).</TableCell><TableCell>A measure of schedule efficiency expressed as the ratio of earned value to planned value.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Schedule Variance (SV).</TableCell><TableCell>A measure of schedule performance expressed as the difference between the earned value and the planned value.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Scheduling Tool.</TableCell><TableCell>A tool that provides schedule component names, definitions, structural relationships, and formats that support the application of a scheduling method.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Scope.</TableCell><TableCell>The sum of the products, services, and results to be provided as a project. See also project scope and product scope.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Scope Baseline.</TableCell><TableCell>The approved version of a scope statement, work breakdown structure (WBS), and its associated WBS dictionary, that can be changed using formal change control procedures and is used as a basis for comparison to actual results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Scope Creep.</TableCell><TableCell>The uncontrolled expansion to product or project scope without adjustments to time, cost, and resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Scope Management Plan.</TableCell><TableCell>A component of the project or program management plan that describes how the scope will be defined, developed, monitored, controlled, and validated.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Secondary Risk.</TableCell><TableCell>A risk that arises as a direct result of implementing a risk response.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Self-Organizing Teams.</TableCell><TableCell>A team formation where the team functions with an absence of centralized control.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Seller.</TableCell><TableCell>A provider or supplier of products, services, or results to an organization.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Seller Proposals.</TableCell><TableCell>Formal responses from sellers to a request for proposal or other procurement document specifying the price, commercial terms of sale, and technical specifications or capabilities the seller will do for the requesting organization that, if accepted, would bind the seller to perform the resulting agreement.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Sensitivity Analysis.</TableCell><TableCell>An analysis technique to determine which individual project risks or other sources of uncertainty have the most potential impact on project outcomes, by correlating variations in project outcomes with variations in elements of a quantitative risk analysis model.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Sequence Activities.</TableCell><TableCell>The process of identifying and documenting relationships among the project activities.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Service Level Agreement (SLA).</TableCell><TableCell>A contract between a service provider (either internal or external) and the end user that defines the level of service expected from the service provider.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Simulation.</TableCell><TableCell>An analytical technique that models the combined effect of uncertainties to evaluate their potential impact on objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Source Selection Criteria.</TableCell><TableCell>A set of attributes desired by the buyer which a seller is required to meet or exceed to be selected for a contract.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Specification.</TableCell><TableCell>A precise statement of the needs to be satisfied and the essential characteristics that are required.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Specification Limits.</TableCell><TableCell>The area, on either side of the centerline, or mean, of data plotted on a control chart that meets the customer’s requirements for a product or service. This area may be greater than or less than the area defined by the control limits. See also control limits.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Sponsor.</TableCell><TableCell>A person or group who provides resources and support for the project, program, or portfolio and is accountable for enabling success.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Sponsoring Organization.</TableCell><TableCell>The entity responsible for providing the project’s sponsor and a conduit for project funding or other project resources.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Stakeholder.</TableCell><TableCell>An individual, group, or organization that may affect, be affected by, or perceive itself to be affected by a decision, activity, or outcome of a project, program, or portfolio.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Stakeholder Analysis.</TableCell><TableCell>A technique of systematically gathering and analyzing quantitative and qualitative information to determine whose interests should be taken into account throughout the project.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Stakeholder Engagement Assessment Matrix.</TableCell><TableCell>A matrix that compares current and desired stakeholder engagement levels.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Stakeholder Engagement Plan.</TableCell><TableCell>A component of the project management plan that identifies the strategies and actions required to promote productive involvement of stakeholders in project or program decision making and execution.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Stakeholder Register.</TableCell><TableCell>A project document including the identification, assessment, and classification of project stakeholders.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Standard.</TableCell><TableCell>A document established by an authority, custom, or general consent as a model or example.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Start Date.</TableCell><TableCell>A point in time associated with a schedule activity’s start, usually qualified by one of the following: actual, planned, estimated, scheduled, early, late, target, baseline, or current.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Start-to-Finish (SF).</TableCell><TableCell>A logical relationship in which a successor activity cannot finish until a predecessor activity has started.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Start-to-Start (SS).</TableCell><TableCell>A logical relationship in which a successor activity cannot start until a predecessor activity has started.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Statement of Work (SOW).</TableCell><TableCell>A narrative description of products, services, or results to be delivered by the project. </TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Statistical Sampling.</TableCell><TableCell>Choosing part of a population of interest for inspection.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Successor Activity.</TableCell><TableCell>A dependent activity that logically comes after another activity in a schedule.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Summary Activity.</TableCell><TableCell>A group of related schedule activities aggregated and displayed as a single activity.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>SWOT Analysis.</TableCell><TableCell>Analysis of strengths, weaknesses, opportunities, and threats of an organization, project, or option.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Tacit Knowledge.</TableCell><TableCell>Personal knowledge that can be difficult to articulate and share such as beliefs, experience, and insights.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Tailoring.</TableCell><TableCell>Determining the appropriate combination of processes, inputs, tools, techniques, outputs, and life cycle phases to manage a project.</TableCell>
                      </TableRow><TableRow>
                      <TableCell className={classes.defName}>Task (status).</TableCell><TableCell>TASK is the prolongated, usually 2-4 quarters, item from the watch list.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Team Charter.</TableCell><TableCell>A document that records the team values, agreements, and operating guidelines, as well as establishing clear expectations regarding acceptable behavior by project team members.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Team Management Plan.</TableCell><TableCell>A component of the resource management plan that describes when and how team members will be acquired and how long they will be needed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Technique.</TableCell><TableCell>A defined systematic procedure employed by a human resource to perform an activity to produce a product or result or deliver a service, and that may employ one or more tools.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Templates.</TableCell><TableCell>A partially complete document in a predefined format that provides a defined structure for collecting, organizing, and presenting information and data.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Test and Evaluation Documents.</TableCell><TableCell>Project documents that describe the activities used to determine if the product meets the quality objectives stated in the quality management plan.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Threat.</TableCell><TableCell>A risk that would have a negative effect on one or more project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Three-Point Estimating.</TableCell><TableCell>A technique used to estimate cost or duration by applying an average or weighted average of optimistic, pessimistic, and most likely estimates when there is uncertainty with the individual activity estimates.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Threshold.</TableCell><TableCell>A predetermined value of a measurable project variable that represents a limit that requires action to be taken if it is reached.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Time and Material Contract (T&M).</TableCell><TableCell>A type of contract that is a hybrid contractual arrangement containing aspects of both cost-reimbursable and fixed-price contracts.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>To-Complete Performance Index (TCPI).</TableCell><TableCell>A measure of the cost performance that is required to be achieved with the remaining resources in order to meet a specified management goal, expressed as the ratio of the cost to finish the outstanding work to the remaining budget.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Tolerance.</TableCell><TableCell>The quantified description of acceptable variation for a quality requirement.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Tool.</TableCell><TableCell>Something tangible, such as a template or software program, used in performing an activity to produce a product or result.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Tornado Diagram.</TableCell><TableCell>A special type of bar chart used in sensitivity analysis for comparing the relative importance of the variables.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Total Float.</TableCell><TableCell>The amount of time that a schedule activity can be delayed or extended from its early start date without delaying the project finish date or violating a schedule constraint.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Trend Analysis</TableCell><TableCell>An analytical technique that uses mathematical models to forecast future outcomes based on historical results.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Trigger Condition.</TableCell><TableCell>An event or situation that indicates that a risk is about to occur.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Unanimity.</TableCell><TableCell>Agreement by everyone in the group on a single course of action.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Update.</TableCell><TableCell>A modification to any deliverable, project management plan component, or project document that is not under formal change control.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Validate Scope.</TableCell><TableCell>The process of formalizing acceptance of the completed project deliverables.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Validation.</TableCell><TableCell>The assurance that a product, service, or result meets the needs of the customer and other identified stakeholders. Contrast with verification.</TableCell>
                      </TableRow><TableRow>
                      <TableCell className={classes.defName}>Value Engineering.</TableCell><TableCell>An approach used to optimize project life cycle costs, save time, increase profits, improv quality, expand market share, solve problems, and/or use resources more effectively.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Variance.</TableCell><TableCell>A quantifiable deviation, departure, or divergence away from a known baseline or expected value.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Variance Analysis.</TableCell><TableCell>A technique for determining the cause and degree of difference between the baseline and actual performance.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Variance At Completion (VAC).</TableCell><TableCell>A projection of the amount of budget deficit or surplus, expressed as the difference between the budget at completion and the estimate at completion.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Variation.</TableCell><TableCell>An actual condition that is different from the expected condition that is contained in the baseline plan.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Verification.</TableCell><TableCell>The evaluation of whether or not a product, service, or result complies with a regulation, requirement, specification, or imposed condition. Contrast with validation.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Verified Deliverables.</TableCell><TableCell>Completed project deliverables that have been checked and confirmed for correctness through the Control Quality process.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Virtual Teams.</TableCell><TableCell>Groups of people with a shared goal who fulfill their roles with little or no time spent meeting face to face.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Voice of the Customer.</TableCell><TableCell>A planning technique used to provide products, services, and results that truly reflect customer requirements by translating those customer requirements into the appropriate technical requirements for each phase of project product development.</TableCell>
                      </TableRow><TableRow>
                      <TableCell className={classes.defName}>Watch list.</TableCell><TableCell>The <b>watch list</b> is the list of low priority risks items in the risk register. When risks have been identified, analyzed and prioritized, it is time to start thinking about developing response plans for risks that have a higher value than the thresholds defined in the risk management plan. The risks below a “certain” threshold values are kept on a “watch list”; there is no need to develop a response plan for them until they cross the threshold values or until there is another reason for.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>WBS Dictionary.</TableCell><TableCell>A document that provides detailed deliverable, activity, and scheduling information about each component in the work breakdown structure.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>What-If Scenario Analysis.</TableCell><TableCell>The process of evaluating scenarios in order to predict their effect on project objectives.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Work Breakdown Structure (WBS).</TableCell><TableCell>A hierarchical decomposition of the total scope of work to be carried out by the project team to accomplish the project objectives and create the required deliverables.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Work Breakdown Structure Component.</TableCell><TableCell>An entry in the work breakdown structure that can be at any level.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Work Package.</TableCell><TableCell>The work defined at the lowest level of the work breakdown structure for which cost and duration are estimated and managed.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Work Performance Data.</TableCell><TableCell>The raw observations and measurements identified during activities being performed to carry out the project work.</TableCell>
                      </TableRow><TableRow>
                        <TableCell className={classes.defName}>Work Performance Information.</TableCell><TableCell>The performance data collected from controlling processes, analyzed in comparison with project management plan components, project documents, and other work performance information.</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>)
}

export default DefinitionsList;
