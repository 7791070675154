import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, Grid, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import HistoryTable from './history/HistoryTable'

class HistoryModal extends Component {
    render() {
      const {historyModalOpen, handleHistoryModalClose, classes, row} = this.props;

      return (<Dialog
                fullScreen
                open={historyModalOpen}
                onClose={handleHistoryModalClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">
                  <Grid container>
                    <Grid item xs={1} >
                      History: {row == null ? '' : row.identifier}
                    </Grid> 
                    <Grid item xs={11}>
                      <Button variant="contained" color="secondary" onClick={() => handleHistoryModalClose()}>Close</Button>
                    </Grid> 
                  </Grid>
                </DialogTitle>
                  <DialogContent>
                     <HistoryTable riskEntityId={row == null ? null : row.id }></HistoryTable>
                  </DialogContent>
              </Dialog>);
    }
};

const styles = theme => ({
});

export default compose(
  withStyles(styles),
)(HistoryModal);
    