import gql from 'graphql-tag';

const UsersDeleteMutation = gql`
  mutation UsersDelete($input: DeleteUserMutationInput!) {
    usersDelete(input: $input) {
      user {
        id
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UsersDeleteMutation;
