import React, { PropsWithChildren } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import mapConnection from '../../utils/connectionMapper';
import { ReportDictionaryRow } from '../RiskReport/types';

const REPORTS_QUERY = gql`
  query stats($dictionaryFilter: DictionaryFilterInputObject!) {
    dictionaries(filter: $dictionaryFilter) {
      edges {
        node {
          id
          typeName
          label
          level
          active
          description
          startDate
        }
      }
    }
    currentReport {
      label
    }
  }
`;

export interface IWithReport{
  reports: ReportDictionaryRow[];
  loading?: boolean;
  history?: any;
  reportName?: String;
}

function withQuery(WrappedComponent: React.FC<PropsWithChildren<IWithReport>>) {
  return (props: PropsWithChildren<IWithReport>) => {
    const { loading, error, data } = useQuery<any, {}>(
      REPORTS_QUERY,
      { 
        variables: {
          dictionaryFilter: { "type": "Dictionaries::Report"}
        },
        fetchPolicy: "no-cache"
      }
    );

    if (error) return <p>ERROR</p>;

    const reports = data ? mapConnection(data.dictionaries) : [];
    const newerReportName = data ? data.currentReport.label : "";

    return (
      <WrappedComponent {...props}
        reports={reports}
        loading={loading}
        reportName={newerReportName}
        />
    );
  };
}

export default withQuery;