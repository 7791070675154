import React, { useEffect } from 'react';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../UI/Spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, FormControl, FormLabel, Grid, NativeSelect, TextField, Typography, withStyles } from '@material-ui/core';
import { Theme, createStyles } from '@material-ui/core/styles';
import Layout from '../UI/Layout';
import mapConnection from '../../utils/connectionMapper';
import WarningDialog from './WarningDialog';
import UpdateRiskConfigMutation from '../../mutations/UpdateRiskConfigMutation';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const STATS_QUERY = gql`
  query config {
    priorityConfig {
      id
      label
      priorityMaps {
        impactId
        probabilityId
      }
    }
    dictionaries {
      edges {
        node {
          id
          typeName
          type
          label
          level
          active
          description
          valueFrom
          valueTo
          contextName
        }
      }
    }    
  }
`;


const PriorityConfig = (props) => {
  const { loading, classes, priorityConfig, dictionaries, client, history } = props;

  const [open, setOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const [probabilityDict, setProbabilityDict] = React.useState(
    dictionaries.filter((d) => d.type === 'Dictionaries::Probability').sort((a,b) => a.level - b.level)
  );
  const [priorityConfigDict, setPriorityConfigDict] = React.useState(priorityConfig);
  const [impactDict, setImpactDict] = React.useState(
    dictionaries.filter((d) => d.type === 'Dictionaries::Impact').sort((a,b) => a.level - b.level)
  );
  const [overtimeDict, setOvertimeDict] = React.useState(
    dictionaries.filter((d) => d.type === 'Dictionaries::Overtime' && d.active)
  );
  const [overcostDict, setOvercostDict] = React.useState(
    dictionaries.filter((d) => d.type === 'Dictionaries::Overcost' && d.active)
  );

  const priorityDict = dictionaries.filter((d) => d.type === 'Dictionaries::Priority');

  // useEffect(() => {
  //   setReport(props.report)
  // }, [props.report]);
  
  const getImpactLabel = (level) => {
    let ret = '';
    impactDict.forEach((element) => {
      if(element.level === level && element.active) {
        ret = element.label
        return;
      }
    });

    return (
      <Typography>{ret}</Typography>
    );
  }

  const getCostFrom = (level, phase) => {
    let ret = '';
    overcostDict.forEach((element) => {
      if(element.level === level && element.contextName === phase && element.active) {
        ret = element.valueFrom
        return;
      }
    });

    return (
      <Typography>{ret}</Typography>
    );
  }

  const getCostTo = (level, phase) => {
    let ret = '';
    overcostDict.forEach((element) => {
      if(element.level === level && element.contextName === phase && element.active) {
        ret = element.valueTo
        return;
      }
    });

    return ret;
  }

  const getTimeFrom = (level, phase) => {
    let ret = '';
    overtimeDict.forEach((element) => {
      if(element.level === level && element.contextName === phase && element.active) {
        ret = element.valueFrom
        return;
      }
    });

    return (
      <Typography>{ret}</Typography>
    );
  }

  const getTimeTo = (level, phase) => {
    let ret = '';
    overtimeDict.forEach((element) => {
      if(element.level === level && element.contextName === phase && element.active) {
        ret = element.valueTo
        return;
      }
    });

    return ret;
  }

  const getDesc = (level) => {
    if(level === 1) {
      return (
        <Typography>VERY LOW</Typography>
      );
    } else if (level === 2) {
      return (
        <Typography>LOW</Typography>
      );
    } else if (level === 3) {
      return (
        <Typography>MEDIUM</Typography>
      );
    } else if (level === 4) {
      return (
        <Typography>HIGH</Typography>
      );
    }
  }

  const getPriority = (impact_id, probability_id) => {
    let ret = '';
    priorityConfigDict.forEach(priorityElement => {
      priorityElement.priorityMaps.forEach(ipMap => {
        if(ipMap.impactId === impact_id && ipMap.probabilityId === probability_id) {
          ret = priorityElement.label;
          return;
        }
      });
    });

    return ret;
  }

  const renderImpactTable = (phase) => {
    return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography>
                    Phase: {phase}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Level</TableCell>
                <TableCell>MIN</TableCell>
                <TableCell></TableCell>
                <TableCell>MAX</TableCell>
                <TableCell></TableCell>
                <TableCell>MIN</TableCell>
                <TableCell></TableCell>
                <TableCell>MAX</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {impactDict.sort((a,b) => a.level - b.level).map((imp) => {
                return (
                  <TableRow key={imp.id}>
                    <TableCell align="center">{imp.level}</TableCell>
                    <TableCell>{getCostFrom(imp.level, phase)}</TableCell>
                    <TableCell align="center"><span> &lt; &#916;c &le; </span></TableCell>
                    <TableCell>
                      <TextField
                        id="standard-number"
                        type="number"
                        disabled={imp.level === 4}
                        value={imp.level === 4 ? '' : getCostTo(imp.level, phase)}
                        style={{width: '100px'}}
                        onChange={(event) => handleOVCChanged(phase, imp, event)}
                      />
                    </TableCell>
                    <TableCell align="center">{getImpactLabel(imp.level)}</TableCell>
                    <TableCell>{getTimeFrom(imp.level, phase)}</TableCell>
                    <TableCell align="center"><span> &lt; &#916;t &le; </span></TableCell>
                    <TableCell>
                      <TextField
                        id="standard-number"
                        type="number"
                        disabled={imp.level === 4}
                        value={imp.level === 4 ? '' : getTimeTo(imp.level, phase)}
                        style={{width: '100px'}}
                        onChange={(event) => handleOVTChanged(phase, imp, event)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>        
    );
  }

  const handleProbabilityChanged = (prob, event) => {
    if(event.target.value !== "" && !isNaN(event.target.value)) {
      setProbabilityDict(
        probabilityDict.map((p) => {
          if(p.id === prob.id) {
            p.valueTo = event.target.value;
          }
          if(p.level === prob.level + 1) {
            p.valueFrom = event.target.value;
          }
          return p;
        })
      )
    }
  }

  const handleOVCChanged = (phase, imp, event) => {
    if(event.target.value !== "" && !isNaN(event.target.value)) {
      setOvercostDict(
        overcostDict.map((p) => {
          if(p.level === imp.level && p.contextName == phase ) {
            p.valueTo = event.target.value;
          }
          if(p.level === imp.level + 1 && p.contextName === phase) {
            p.valueFrom = event.target.value;
          }
          return p;
        })
      )
    }
  };

  const handleOVTChanged = (phase, imp, event) => {
    if(event.target.value !== "" && !isNaN(event.target.value)) {
      setOvertimeDict(
        overtimeDict.map((p) => {
          if(p.level === imp.level && p.contextName == phase ) {
            p.valueTo = event.target.value;
          }
          if(p.level === imp.level + 1 && p.contextName === phase) {
            p.valueFrom = event.target.value;
          }
          return p;
        })
      )
    }
  };

  const handlePriorityMatrixChanged = (imp, prob, event) => {
    const sourcePri = priorityConfigDict.find((elem) => elem.priorityMaps.some((e) => e['impactId'] === imp.id && e['probabilityId'] === prob.id ));
    const targetPri = priorityConfigDict.find((elem) => elem.label === event.target.value );

    setPriorityConfigDict(
      priorityConfigDict.map((element) => {
        if(element.id === sourcePri.id) {
          let index = -1;
          let i = 0;
          element.priorityMaps.forEach((e) => {
            if(e['impactId'] === imp.id && e['probabilityId'] === prob.id) {
              index = i;
            }
            i=i+1;
          });


          if (index > -1) {
            element.priorityMaps.splice(index, 1);
          }
        }
        if(element.id === targetPri.id) {
          element.priorityMaps.push({
            impactId: imp.id, probabilityId: prob.id
          })
        }

        return element;
      })
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    handleClose();
    const matrix = [];
    const impacts = [];

    ['CONSTRUCTION','PROJECT','OPERATION'].forEach((phase) => {
      const ovts = overtimeDict.filter((e) => e.contextName === phase).sort((a,b) => a.level - b.level)
      const ovcs = overcostDict.filter((e) => e.contextName === phase).sort((a,b) => a.level - b.level)

      impacts.push({
        phaseId: phase,
        overcosts: [
          parseFloat(ovcs[0].valueTo), 
          parseFloat(ovcs[1].valueTo), 
          parseFloat(ovcs[2].valueTo)
        ],
        overtimes: [
          parseFloat(ovts[0].valueTo), 
          parseFloat(ovts[1].valueTo), 
          parseFloat(ovts[2].valueTo)
        ],
      });
    });

    priorityConfigDict.forEach((priority) => {
      priority.priorityMaps.forEach((pMap) => {
        matrix.push({
          impactId: pMap.impactId,
          probabilityId: pMap.probabilityId,
          priorityId: priority.id
        });
      })
    })

    try {
      let result = await client.mutate({
        mutation: UpdateRiskConfigMutation,
        variables: {
          input: {
            impacts: impacts,
            probabilities: [
              parseFloat(probabilityDict[0].valueTo), 
              parseFloat(probabilityDict[1].valueTo), 
              parseFloat(probabilityDict[2].valueTo)],
            priorityMatrix: matrix
          }
        }
      });

      if ((result.errors != null && result.errors.length > 0) || (result.data.riskConfigUpdate.errors != null && result.data.riskConfigUpdate.errors.length > 0)) {
        setErrorMessage(result.data.riskConfigUpdate.errors[0].message);
      } else {
        setSuccessMessage(`Risk configuration updated !`);
        history.push('/logout');
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
    }
  };

  return (
    <Grid container xs={12} spacing={5}>
      <Grid item xs={12} align="right">
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>Save changes</Button>
      </Grid>
      <Grid item xs={12}>
        {errorMessage ? <Alert severity="warning">{errorMessage}</Alert> : ''}
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>PRIORITY MATRIX</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {probabilityDict.map((prob => {
                  return <TableCell align="center">{prob.label}</TableCell>;
                }))}
              </TableRow>
            </TableHead>
            <TableBody>
                {impactDict.map((imp) => {
                  return (
                    <TableRow key={imp.id}>
                      <TableCell align="center">{imp.label}</TableCell>
                      {probabilityDict.map((prob => {
                        return <TableCell align="center">
                          <FormControl>
                            <NativeSelect
                              value={getPriority(imp.id, prob.id)}
                              name="priority"
                              inputProps={{ 'aria-label': 'priority' }}
                              onChange={(event) => handlePriorityMatrixChanged(imp, prob, event)}
                            >
                              {priorityDict.map((element) => {
                                return (
                                  <option value={element.label}>{element.label}</option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </TableCell>;
                      }))}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>        
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>PROBABILITY</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Level</TableCell>
                <TableCell align="center">MIN</TableCell>
                <TableCell></TableCell>
                <TableCell align="center">MAX</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {probabilityDict.map((prob => {
                return (
                  <TableRow>
                    <TableCell align="center">{prob.level}</TableCell>
                    <TableCell align="center">
                      {prob.valueFrom}
                    </TableCell>
                    <TableCell align="center"><span> &lt; &#916;p &le; </span></TableCell>
                    <TableCell align="center">
                      <TextField
                        id="standard-number"
                        type="number"
                        disabled={prob.level === 4}
                        value={prob.level === 4 ? '100' : prob.valueTo}
                        onChange={(event) => handleProbabilityChanged(prob, event)}
                        style={{width: '100px'}}
                      />
                    </TableCell>
                    <TableCell align="center">{getDesc(prob.level)}</TableCell>
                  </TableRow>
                );
              }))}
            </TableBody>
          </Table>
        </TableContainer>        
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>IMPACT</Typography>
        {renderImpactTable('CONSTRUCTION')}
        {renderImpactTable('PROJECT')}
        {renderImpactTable('OPERATION')}
      </Grid>
      <WarningDialog open={open} handleClose={handleClose} handleAction={handleSave}/>
    </Grid>
  );
};

const withQuery = (WrappedComponent) => (props) => (
  <Query query={STATS_QUERY} variables={{}}>
    {({ data, loading, error }) => {
      const priorityConfig = data ? data.priorityConfig : [];
      const dictionaries = data ? mapConnection(data.dictionaries) : [];
      return <WrappedComponent {...props} 
                loading={loading}
                priorityConfig={priorityConfig}
                dictionaries={dictionaries} />;
    }}
  </Query>
);

const PriorityConfigPage = (props) => {
  const { loading, priorityConfig, classes, client, history, dictionaries } = props;
  return (
    <Layout title="Priority Config" history={history} leftManuType="administration">
      {loading ? <Spinner /> : <PriorityConfig 
                                  priorityConfig={priorityConfig} 
                                  classes={classes}
                                  client={client}
                                  dictionaries={dictionaries}
                                  history={history} />}
    </Layout>
  );
};

const styles = theme => ({
});

export default withStyles(styles)(withQuery(withApollo(PriorityConfigPage)));