import { SET_DICTIONARY } from '../actions/action-types';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DICTIONARY:
      return {
        ...state,
        [payload.name]: payload.dictionary,
      };

    default:
      return state;
  }
};
