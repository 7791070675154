import gql from 'graphql-tag';

const UsersSendResetMutation = gql`
  mutation SendReset($input: SendResetUserMutationInput!) {
    usersSendReset(input: $input) {
      message
      errors {
        name
        message
      }
    }
  }
`;

export default UsersSendResetMutation;
