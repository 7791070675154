import { SET_GRAPHQL_ERRORS, SET_NETWORK_ERROR, HIDE_ERROR_POPUP } from './action-types';

export const setGraphQLErrors = (error, operation) => ({
  type: SET_GRAPHQL_ERRORS,
  payload: {
    error,
    operation,
  },
});

export const setNetworkError = (error, operation) => ({
  type: SET_NETWORK_ERROR,
  payload: {
    error,
    operation,
  },
});

export const hideErrorPopup = () => ({
  type: HIDE_ERROR_POPUP,
});
