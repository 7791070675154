import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';

import rootReducer from '../reducers';

// TODO: Think about a way to persist data per user (user email); Change the library to redux-persist?
const persistedStores = ['token', 'currentUser', 'sidebar', 'preferences', 'healthStatus', 'riskFilters'];

const loggerMiddleware = createLogger();
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (initialState) =>
  createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware), persistState(persistedStores)),
    initialState
  );
