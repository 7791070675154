import gql from 'graphql-tag'

export default gql`
  mutation newReportRiskConfigMutation {
    riskConfigNewReport(input: {}) {
      report {
        id
        label
        startDate
      }
      errors {
        name
        message
      }
    }
  }
`