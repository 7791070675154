import { Link, Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
             

const MaintenanceManual = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" paragraph>
        Maintenence Manual
      </Typography>
      <Typography variant="h6" paragraph>
        AWS Access
      </Typography>
      <Typography paragraph>
        To get personal access to the AWS Account, send request to: <Link component="a" href="mailto: rms-support@rm-project.eu">rms-support@rm-project.eu</Link>
      </Typography>
      <Typography variant="h6" paragraph>
        Backups
      </Typography>
      <Typography paragraph>
        Application is hosted on AWS, and is using RDS as a database. DB backups are configured:
        <ul>
          <li>
            <b>Backup window: </b>22:54-23:24 UTC (GMT)
          </li>
          <li>
            <b>Backup retention period:</b> 30 days
          </li>
        </ul>

        How to manage a backup: <Link target="blank" href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithAutomatedBackups.html">Backup</Link>
      </Typography>
      <Typography variant="h6" paragraph>
        Logs
      </Typography>
      <Typography paragraph>
        Application is storing logs in CloudWatch service, Log Groups: <b>/aws/lambda/rm-backend-prod-graphql_controller-execute</b>
      </Typography>
      <Typography paragraph>
        How to work with AWS CloudWatch: <Link target="blank" href="https://aws.amazon.com/cloudwatch/getting-started/">CloudWatch</Link>
      </Typography>
    </Paper>
  )
};
             
export default MaintenanceManual;
