import { withRouter } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState, useCallback, useMemo } from 'react';
import ReactDataGrid, { Column, SortDirection, Filters } from 'react-data-grid';
import { DictionaryRow } from './types';

// import 'react-data-grid/dist/react-data-grid.css';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFilter: {
      width: 180,
    }
  }),
);

export function DictionariesList(props: any) {
  const classes = useStyles();
  const rows = props.dictionary;
  const [[sortColumn, sortDirection], setSort] = useState<[string, SortDirection]>(['id', 'NONE']);
  const [filters, setFilters] = useState<Filters>({
    typeName: '',
    label: '',
    description: '',
    level: '',
  });

  const columns = useMemo((): Column<DictionaryRow>[] => {
    return [
      { 
        name: "Level",
        key: "level",
        width: 200,
        filterRenderer: p => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={e => p.onChange(e.target.value)}
            />
          </div>
        )
      },
      { 
        name: "Type",
        key: "typeName",
        width: 200,
        filterRenderer: p => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={e => p.onChange(e.target.value)}
            />
          </div>
        )
      },
      { 
        name: "Label",         
        key: "label",
        width: 200,
        filterRenderer: p => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={e => p.onChange(e.target.value)}
            />
          </div>
        )
      },
      { 
        name: "Is active",      
        key: "active",      
        width: 200,
        formatter(props) {
          if(props.row.active === null){
            return <div></div>;
          } else {
            if(props.row.active) {
              return <div style={{textAlign: 'center', width: '100%'}}><CheckIcon /></div>;
            } else {
              return <div style={{textAlign: 'center', width: '100%'}}><ClearIcon /></div>;
            }
          }
        },        
        filterRenderer: p => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={e => p.onChange(e.target.value)}
            />
          </div>
        )
      },
      { 
        name: "Description",         
        key: "description",
        filterRenderer: p => (
          <div className="rdg-filter-container">
            <input
              className={`rdg-filter ${classes.textFilter}`}
              value={p.value}
              onChange={e => p.onChange(e.target.value)}
            />
          </div>
        )
      },
    ];
  }, [classes.textFilter]);  

  const filteredRows = useMemo(() => {
    return rows.filter((r: DictionaryRow) => {
      return (
             (filters.label ? r.label.includes(filters.label) : true)
          && (filters.typeName && r.typeName ? r.typeName.includes(filters.typeName) : true)
          && (filters.description ? r.description.includes(filters.description) : true)
          && (filters.level ? r.level === filters.level : true)
      );
    });
  }, [rows, filters]);

  const sortedRows: DictionaryRow[] = useMemo(() => {
    if (sortDirection === 'NONE') return filteredRows || rows;

    let sortedRows: DictionaryRow[] = [...filteredRows || rows];

    switch (sortColumn) {
      case 'label':
      case 'description':
      case 'typeName':
        sortedRows = sortedRows.sort((a, b) => a[sortColumn] && a[sortColumn].localeCompare(b[sortColumn]));
        break;
      case 'active':
        sortedRows = sortedRows.sort((a, b) => a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1);
        break;
      // case 'level':
      //   sortedRows = sortedRows.sort((a, b) => (a[sortColumn] | 0) - (b[sortColumn]));
      //   break;
      default:
    }

    return sortDirection === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filteredRows, sortDirection, sortColumn]);  

  const handleSort = useCallback((columnKey: string, direction: SortDirection) => {
    setSort([columnKey, direction]);
  }, []);  

  function rowKeyGetter(row: DictionaryRow) {
    return row.id;
  }  

  return (
    <ReactDataGrid
        rowKeyGetter={rowKeyGetter}
        columns={columns}
        defaultColumnOptions={{
          sortable: true,
          resizable: true
        }}            
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={handleSort}
        rows={sortedRows}
        enableFilterRow={true}
        filters={filters}
        onFiltersChange={setFilters}
    />
  );
};

export default withRouter(DictionariesList);
