import { Link, Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
             

const DevelopmentManual = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" paragraph>
        Development manual
      </Typography>
      <Typography variant="h6" paragraph>
        Infrastructure
      </Typography>
      <Typography paragraph>
        Applications in hosted on AWS. Used services:
        <ul>
          <li>
            <b>Frontend: </b> CloudFront
          </li>
          <li>
            <b>Backend: </b> AWS Lambda
          </li>
          <li>
            <b>Database: </b> RDS
          </li>
          <li>
            <b>Logs: </b> CloudWatch
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" paragraph>
        Frameworks
      </Typography>
      <Typography paragraph>
        <ul>
          <li>
            <Link component="a" href="https://rubyonjets.com/">RubyOnJets</Link>
          </li>
          <li>
            <Link component="a" href="https://reactjs.org/">React</Link>
          </li>
          <li>
            <Link component="a" href="https://graphql.org/">Graphql</Link>
          </li>
        </ul>
      </Typography>
      <Typography variant="h6" paragraph>
        Source code
      </Typography>
      <Typography paragraph>
        Repository url: <Link component="a" href="https://gitlab.com/riskmanagment">Gitlab</Link>
      </Typography>
      <Typography paragraph>
        To get personal access to source code repository, send request to: <Link component="a" href="mailto: rms-support@rm-project.eu">rms-support@rm-project.eu</Link>
      </Typography>
    </Paper>
  )
};
             
export default DevelopmentManual;             
