import { Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Typography } from '@material-ui/core';
import React from 'react';
import Layout from '../UI/Layout';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AcronymsList from './AcronymsList';
import DefinitionsList from './DefinitionsList';
import Introduction from './Introduction';
import DevelopmentManual from './DevelopmentManual';
import MaintenanceManual from './MaintenanceManual';
import Manual from './Manual';
import Purpose from './Purpose';
import useStyles from './styles';

const SELECTED_COLOR = 'orange';

function ListItemLink(props) {
  return <ListItem button component="a" primary="primary" {...props} />;
}

const ManualPage = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [helpPage, setHelpPage] = React.useState("introduction");

  return (
    <Layout title="Help" history={history}>
      <Grid xs="10" container item>
        <Grid item xs="2" >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Contents
              </ListSubheader>
            }
            className={classes.list}
          >
            <ListItemLink>
              <ListItemText style={{color: helpPage === "introduction" ? SELECTED_COLOR : ''}} primary="Introduction" onClick={() => setHelpPage("introduction")} />
            </ListItemLink>
            <ListItemLink>
              <ListItemText style={{color: helpPage === "purpose" ? SELECTED_COLOR : ''}} primary="Purpose" onClick={() => setHelpPage("purpose")}/>
            </ListItemLink>
            <ListItemLink>
              <ListItemText style={{color: helpPage === "manual" ? SELECTED_COLOR : ''}} primary="Manual" onClick={() => setHelpPage("manual")}/>
            </ListItemLink>
            <ListItem button >
              <ListItemText style={{color: helpPage === "access" ? SELECTED_COLOR : ''}} primary="Levels of access" onClick={() => setHelpPage("access")}/>
            </ListItem>
            <ListItem button >
              <ListItemText style={{color: helpPage === "permissions" ? SELECTED_COLOR : ''}} primary="Roles and permissions" onClick={() => setHelpPage("permissions")}/>
            </ListItem>
            <ListItem button >
              <ListItemText style={{color: helpPage === "links" ? SELECTED_COLOR : ''}} primary="Links" onClick={() => setHelpPage("links")}/>
            </ListItem>
            <ListItemLink>
              <ListItemText style={{color: helpPage === "glossary" ? SELECTED_COLOR : ''}} primary="Glossary" onClick={() => setHelpPage("glossary")}/>
            </ListItemLink>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemLink className={classes.nested}>
                  <ListItemIcon>
                    <ArrowRightIcon/>
                  </ListItemIcon>
                  <ListItemText style={{color: helpPage === "acronyms" ? SELECTED_COLOR : ''}} primary="Acronyms" onClick={() => setHelpPage("acronyms")}/>
                </ListItemLink>
                <ListItemLink className={classes.nested}>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText style={{color: helpPage === "definitions" ? SELECTED_COLOR : ''}} primary="Definitions" onClick={() => setHelpPage("definitions")}/>
                </ListItemLink>
              </List>
            </Collapse>
            <ListItem button >
              <ListItemText  style={{color: helpPage === "references" ? SELECTED_COLOR : ''}} primary="References" onClick={() => setHelpPage("references")}/>
            </ListItem>
            <ListItem button >
              <ListItemText  style={{color: helpPage === "maintenance" ? SELECTED_COLOR : ''}} primary="Maintenance" onClick={() => setHelpPage("maintenance")}/>
            </ListItem>
            <ListItem button >
              <ListItemText  style={{color: helpPage === "development" ? SELECTED_COLOR : ''}} primary="Development" onClick={() => setHelpPage("development")}/>
            </ListItem>
          </List>
        </Grid>
        <Grid item container xs="10" spacing={5}>
          { helpPage === "introduction" &&
            <Grid item xs={12} alignItems="flex-start">
              <Introduction />
            </Grid>
          }
          { helpPage === "purpose" &&
            <Grid item xs={12} alignItems="flex-start">
              <Purpose />
            </Grid>
          }
          { helpPage === "manual" &&
            <Grid item xs={12} alignItems="flex-start">
              <Manual />
            </Grid>
          }
          { helpPage === "glossary" &&
            <Grid item xs="10" alignItems="flex-start">
              <Paper className={classes.paper}>
                <Typography variant="h5" paragraph>
                  Glossary
                </Typography>
                <Typography paragraph>
                  This glossary includes terms that are:
                </Typography>
                <List>
                  <ListItem>
                    Unique or nearly unique to project and integrated risk management (e.g., project scope statement, work package, work breakdown structure, critical path method).
                  </ListItem>
                  <ListItem>
                    Not unique to integrated risk management, but used differently or with a narrower meaning in project and risk management than in general everyday usage.
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          }
          { helpPage === "acronyms" &&
            <Grid item xs="10" alignItems="flex-start">
              <AcronymsList />
            </Grid>
          }
          { helpPage === "definitions" &&
            <Grid item xs="10" alignItems="flex-start">
              <DefinitionsList />
            </Grid>
          }
          { helpPage === "maintenance" &&
            <Grid item xs="10" alignItems="flex-start">
              <MaintenanceManual />
            </Grid>
          }
          { helpPage === "development" &&
            <Grid item xs="10" alignItems="flex-start">
              <DevelopmentManual />
            </Grid>
          }
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ManualPage;