import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditFormComponent from './EditFormComponent';


class EditModalBox extends Component {
    
    render() {
      const {editModalOpen, handleEditModalClose, handleEditModalSave, classes, dictionaries, users, row, currentUser} = this.props;
      const formRef = React.createRef();

      return (<Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={editModalOpen}
                onClose={handleEditModalClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Edit risk event</DialogTitle>
                <DialogContent>
                  <Grid xs={12} container>
                    <EditFormComponent 
                      ref={formRef}
                      row={row} 
                      users={users} 
                      currentUser={currentUser}
                      dictionaries={dictionaries} />
                  </Grid>
                  <Grid xs={12} container style={{marginTop: 20}}>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleEditModalClose()}>Cancel</Button>        
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button variant="contained" color="primary" onClick={() => handleEditModalSave(formRef.current.state.newRow)}>Save</Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>);
    }
}

const styles = theme => ({
  paper: {
      position: 'absolute',
      width: 700,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }
});

const EditModal = (props) => {
  const { row } = props;
  return (
      <div>
        {row == null ? null : <EditModalBox {...props} />}
      </div>
  );
};

export default compose(
  withStyles(styles),
)(EditModal);
    