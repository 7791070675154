import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import {
  withStyles, Grid,
} from '@material-ui/core';


class LeftReportMenu extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <Grid item container xs={12} className={classes.grid}>
          <Grid item xs={12}>
            {this.props.children}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: window.innerWidth,
    height: '100%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: 20,
  },
  grid: {
    width: '100%',
    height: '100%'
  }
});

export default compose(
  withStyles(styles),
)(LeftReportMenu);