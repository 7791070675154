import { Paper, Typography, Link } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import './Manual.css';
             

const Manual = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography id="manual" variant="h5" paragraph>
        Manual
      </Typography>

      <Typography id="manual" variant="h6" paragraph>
        First login
      </Typography>
      <p style={{marginLeft: 42}}>First login will activate the user’s personal account and will require setting up the password. For the first login will be needed email address confirmed by the Risk Management representative. In order to login for the first time to the XFEL Risk Management system follow these steps:</p>
      <ol>
        <li>Open web browser (recommended browser: Firefox) </li>
        <li>Input web address: <Link component="a" href="https://xfel.rm-project.eu/">https://xfel.rm-project.eu/</Link></li>
        <li>In the field <b>Email Address</b> provide the email address agreed with the Risk Manager (usually xfel email address).</li>
        <li>Click <b>Forgot password?</b> link below the <b>LOGIN</b> button</li>
        <li>In the field <b>Email Address</b> provide the email address agreed with the Risk Manager</li>
        <li>Click <b>SEND RESET LINK</b></li>
        <p style={{marginLeft: 0}}>If the email address was correct the RMS (Risk Management System) will display information: "Reset link sent to: (your email address)" and reset link will be send to the used address</p>
        <li>Open your mailbox</li>
        <li>Use email with subject <b>[XFEL] Risk Management - Reset Password</b></li>
        <li>Use link: <b>Go to this page</b></li>
        <li>Type your (new) password in the field <b>Password</b> and retype in the field: <b>Password Confirmation</b></li>
        <li>Click <b>RESET</b></li>
        <p>If password and confirmed password were provided correctly there will be displayed information <b>Password changed</b>. At this moment you should have rights to get into the RMS. You can use link <b>Login</b> (on the right of the information Password changed) or use web address: https://xfel.rm-project.eu/</p> 
        <p>Usually as login is used xfel email address. Other cases need to be agreed with the Risk Management representatives via <Link component="a" href="mailto: rms-support@rm-project.eu">rms-support@rm-project.eu</Link></p>
      </ol>

      <Typography id="manual" variant="h6" paragraph>
        Login
      </Typography>
      <p style={{marginLeft: 42}}>In order to login to the XFEL Risk Management system follow these steps:</p>
      <ol>
        <li>Open web browser (recommended browser: Firefox)</li>
        <li>Input web address: <Link component="a" href="https://xfel.rm-project.eu/">https://xfel.rm-project.eu/</Link></li>
        <li>In the fields <b>Email Address</b> and <b>Password</b> provide your login data.</li>
        <li>Click <b>LOGIN</b></li>
        <p>If you don’t have login data, contact your Risk Manager via <Link component="a" href="mailto: rms-support@rm-project.eu">rms-support@rm-project.eu</Link></p>
      </ol>

      <Typography id="manual" variant="h6" paragraph>
        Password reset
      </Typography>

      <p style={{marginLeft: 42}}>In order to reset the user password follow these steps:</p>
      <ol>
        <li>Open web browser (recommended browser: Firefox)</li>
        <li>Input web address: <Link component="a" href="https://xfel.rm-project.eu/">https://xfel.rm-project.eu/</Link></li>
        <li>Click <b>Forgot password</b>? link below the <b>LOGIN button</b></li>
        <li>In the field <b>Email Address</b> provide the email address agreed with the Risk Manager</li>
        <li>Click <b>SEND RESET LINK</b></li>
        <p>If the email address was correct the RMS (Risk Management System) will display information: ”Reset link sent to: (your email address)” and reset link will be send to the used address </p>
        <li>Open your mailbox</li>
        <li>Use email with subject <b>[XFEL] Risk Management - Reset Password</b></li>
        <li>Use link: <b>Go to this page</b></li>
        <li>type your (new) password in the field <b>Password</b> and retype in the field: <b>Password Confirmation</b></li>
        <li>Click <b>RESET</b></li>
        <p>If password and confirmed password were provided correctly there will be displayed information <b>Password changed</b>. At this moment you should have rights to get into the RMS. You can use link <b>Login</b> (at the right of the information Password changed) or use web address: <Link component="a" href="https://xfel.rm-project.eu/">https://xfel.rm-project.eu/</Link></p>
      </ol>  
    
      <Typography id="manual" variant="h6" paragraph>
        Dashboard
      </Typography>
      <p style={{marginLeft: 42}}>Dashboard is the first section displayed after login to the RSM. It presents data to which user has given access. Possibility to change the viewed content is here intentionally minimized to one filter’s option. Dashboard presents summary of the data available for an user.</p>
      <p style={{marginLeft: 42}}>Dashboard consists of the following elements:</p>
      <ol>
        <li>Top menu</li>
        <li>Left menu</li>
        <li>User information</li>
        <li>Pie chart – summary of all risk events (available for user)</li>
        <li>Entire Register table decomposed by status: pending (PND), new (NEW), closed (CLS), tasks (TSK) and the priority I (red), II (yellow), III (green)</li>
        <li>Probability - Impact matrix</li>
        <li>Filter used to hide/unhide amount of CLOSED risk events.</li>
      </ol>
      <Typography id="manual" variant="h6" paragraph>
        User Profile 
      </Typography>
      <p style={{marginLeft: 42}}>User Profile contains basic user data such as email, Name, Surname, Phone, Position, Abbreviation, Office, Group Name to which user belongs, Name of the group’s leader and two fields for the password change.</p>
      <p style={{marginLeft: 42}}>Fields: First Name, Last Name, Phone, Position, Abbreviation, Office, Password, Confirm Password can be changed. In order to provide changes type into the chosen field and save changes by clicking the <b>SAVE</b> button at the right top corner of the widow. Once changes are saved the confirmation <b>Profile updated</b> will be displayed.</p>
      <p style={{marginLeft: 42}}>Fields which are grey: Email, Member of Group, Leader of Group cannot be edited via User Profile.</p>
      <Typography id="manual" variant="h6" paragraph>
        Risk Register
      </Typography>      
      <p style={{marginLeft: 42}}>Risk Register is a repository in which outputs of risk management processes are recorded. Entire risk event’s content has been organized with using the following columns:</p>
      <ol>
          <li>ID</li>
          <li>Specification</li>
          <li>Title</li>
          <li>Category</li>
          <li>Subcategory</li>
          <li>Cost</li>
          <li>Time</li>
          <li>Impact</li>
          <li>Probability</li>
          <li>Priority</li>
          <li>Risk Owner</li>
          <li>Response Plan</li>
          <li>Strategy</li>
          <li>Status</li>
          <li>Phase</li>
          <li>Globality</li>
          <li>Range</li>
          <li>Iteration</li>           
      </ol>
      <p style={{marginLeft: 42}}>Data in the table can be modified, sorted, filtered and exported.</p>
    
      <Typography id="manual" variant="h6" paragraph>
        Sorting
      </Typography>      
      <p style={{marginLeft: 42}}>In order to sort out data in a column click the column title using the left mouse button. Black triangle shows direction of sorting, clicking another time changes direction of sorting, clicking third time switches off sorting and black triangle symbol disappears. Sorting can be active in one column at once e.g. if sorting is active in one column and user switches on sorting in another column, then sorting in the first column switches off. There are two sorting options available A-Z or Z-A.</p>

      <Typography id="manual" variant="h6" paragraph>
        Filtering
      </Typography>      
      <p style={{marginLeft: 42}}>RMS system is designed as a database with tailored web-based user interface. One of the most powerful features of a database is the ability to filter data, i.e., to select only those records that match certain criteria.</p>
      <p style={{marginLeft: 42}}>In the Risk Register table we have implemented two options available for filtering:</p>

      <ol>
        <li>
          <b>Typing field.</b> Field where selection criteria need to be provided e.g by keyboard typing. While typing data in the table are filtered. In order to remove provided selection criteria user needs to remove data from the field. When field is empty all available data are visible in the column.
        </li>
        <li>
          <b>Selection list.</b> In this case selection criteria can be selected from the predefined list. In order to choose desired selection criteria user needs to click chosen value using left mouse button.
        </li>
        <p>The Selection list option has been broken down into two versions:</p>
        <ol>
          <li><b>Multichoice.</b> More than one option can be selected and active at once.
          <p>In order to activate a desired filter configuration follow these steps:</p>
          <ol>
            <li>Click the selection field using the left mouse button and open the predefined selection list.</li>
            <li>Use left mouse button to click the selected value. Chosen activated selection value will be displayed in the filter field.</li>
            <li>In order to add more selection criteria repeat steps 2.1.1 and 2.1.2 accordingly. Only one selection criteria can be chosen at once.
              In case of longer predefined selection list there is sliding bar at the right side of the selection list. 
              In order to move the sliding bar use left mouse button and click the sliding bar (dark grey bar). 
              Keep pressed left mouse button and move down or up the mouse in order to move selection list. Once the searched value is visible in the displayed filter range release the mouse button. 
              In order to activate chosen value follow step 2.1.2.
              In order to <b>remove filtering</b> value(s) use left mouse button to click the "<b>x</b>" symbol at the right side of the selection criteria. 
              When all selection criteria are removed and there is no active filter, then <b>Select …</b> prompt is displayed in the selection field and all available data are displayed in the column.
            </li>
          </ol>
          </li>

          <li>
            <b>Single choice.</b> Only one choice can be active at once. In order to select desired selection criteria follow these steps:
          </li>
          <ol>
            <li>Click the selection field using left mouse button and open the predefined selection list.</li>
            <li>Use left mouse button to click the selected value. Chosen activated selection value will be displayed in the filter field.</li>
          </ol>

          <p>In order to <b>remove filtering</b> follow instruction 2.1.2 and select the <b>All</b> value from the selection list. 
            Once the All prompt is displayed at the selection field there is no active filter in the column.</p>
          <p>In order to <b>remove filtering</b> in the <b>Risk Owner</b> column use the left mouse button to click the “x” symbol at the right side of the selected criteria. 
            When all selection criteria are removed and there is no active filter, then <b>Select …</b> prompt is displayed at the selection field and all available data are displayed in the column.</p>
        </ol>
      </ol>
      <Typography id="manual" variant="h6" paragraph>
        <b>Add new</b> risk event
      </Typography>
      <p style={{marginLeft: 42}}>In order to add new identified risk event to the risk register we have two available ways. The first option is to use the <b>ADD button</b> located at the top right of the risk register table:</p>
      <ol>
        <li>
          Click the <b>RISK REGISTER</b> at the top menu
        </li>
        <li>
          Use the left mouse button and click ADD (right top corner of the window)
        </li>
        <li>
          The new window will pop up, titled Create new risk event
        </li>
        <li>
          Provide Specification text, type using keyboard   
        </li>
        <li>
          Click the Group field. Selection list will open. Depending on your access rights, there might be one or several available options. Use the left mouse button to select the Group name.
        </li>
        <li>
          Click the Risk Owner field to open selection list and select Risk Owner
        </li>
        <li>
          Select the Phase affected by the risk event
        </li>
        <li>
          Click CREATE using left mouse button
        </li>
      </ol>  
      
        <p style={{marginLeft: 42}}>The second option to add new identified risk event to the risk register is to use the submenu. In order to activate and use submenu follow these steps:</p>
            
      <ol>
        
        <li>
          Place the mouse pointer at a register table and click the right mouse button.
        </li>
        <li>
          Submenu will open. Submenu contains following positions: Add, Edit, Split, Combine, History, Comments.
        </li>
        <li>
          Use left mouse button to click Add
        </li>
        <li>
          The new window will pop up, titled Create New risk event
        </li>
        <li>
          Provide Specification text, type using keyboard
        </li>
        <li>
          Click the Group field. Selection list will open. Depending on your access rights, there might be one or several available options. Use the left mouse button to select the Group name.
        </li>
        <li>
          Click the Risk Owner field to open selection list and select Risk Owner
        </li>
        <li>
          Select the Phase affected by the risk event
        </li>
        <li>
          Click CREATE using left mouse button
        </li>
      </ol>
        
    <p style={{marginLeft: 42}}>At the bottom of the risk register the new record is displayed at first. In case that risk register does not fit in one displayed window (due to used screen resolution and browser configuration) use sliding bar or mouse scroll in order to move the displayed list.</p>
    <p style={{marginLeft: 42}}>After reloading (Ctrl+R) the new generated risk event will be relocated and placed in a relevant alphabetical position. Please make sure that your filter configuration is relevant.</p>
        <Typography id="manual" variant="h6" paragraph>
            <b>Edit</b> existing risk record
        </Typography>

            <p style={{marginLeft: 42}}>Two type of the data fields have been used to facilitate the edition process of the existing risk events:</p>
                <ol>
                    <li>
                        <b>selection list</b>. In this kind of data user selects a value from the predefined list. Fields such as: <i>Group, Risk Owner, cost (left column), time (left column), probability (left column), Subcategory, Phase, Status, Strategy</i> belong here.
                    </li>            
                    <li>
                        <b>text field</b>. In this kind of data field user provides data typing e.g. using the keyboard. Fields such as: <i>Cost (right column), Time (right column), Probability (right column), Title, Specification, Response plan</i> belong here.
                    </li>            
                </ol>
            <p style={{marginLeft: 42}}>In order to <b>edit</b> an existing <b>risk event</b> follow these steps:</p>
            <p style={{marginLeft: 42}}><b>Option 1</b></p>
                <ol>
                    <li>
                        Use left mouse button to <b>double click</b> chosen risk event’s <b>record</b>
                    </li>
                    <li>
                        The window titled Edit risk event pops up.
                    </li>
                </ol>
            <p style={{marginLeft: 42}}><b>Option 2</b></p>
                <ol>
                    <li>
                        Place the mouse pointer at a register table and click the right mouse button.
                    </li>
                    <li>
                        Submenu will open. Submenu contains following positions: Add, Edit, Split, Combine, History, Comments.
                    </li>
                    <li>
                        Use left mouse button to click Edit
                    </li>
                    <li>
                        The window titled Edit risk event pops up. The following fields are available for edition:
                        <ol>
                            <li>
                                Group (selection list)
                            </li>
                            <li>
                                Risk Owner (selection list)
                            </li>
                            <li>
                                Cost (both options)
                            </li>
                            <li>
                                Time (both options)
                            </li>
                            <li>
                                Probability (both options)
                            </li>
                            <li>
                                Subcategory (selection list)
                            </li>
                            <li>
                                Phase (selection list)
                            </li>
                            <li>
                                Status (selection list)
                            </li>
                            <li>
                                Strategy (selection list)
                            </li>
                            <li>
                                Title (text field)
                            </li>
                            <li>
                                Specification (text field)
                            </li>
                            <li>
                                Response plan (text field)
                            </li>
                        </ol>
                    </li>
                    <p style={{marginLeft: 42}}>In case of Cost, Time and Probability values RMS provides both mentioned above options. For this purpose e.g. cost value can be provided either in the left or right column. Left field use predefined selection list containing certain thresholds ranges, while right field require typing in the field. Both fields work alternatively and exclusively. When user selects value on the left side then previously provided data will be erased on the right side and vice versa. If a value was previously provided on the left side and user begins typing into the field on the right side, the value on the left side will be erased accordingly.</p>
                    <p style={{marginLeft: 42}}>In case that risk evaluation require more precise or exact calculation either of the cost time or probability the right side section of data fields can be used for this purpose.</p>
                        <li>
                            In order to edit the Group assignment follow these steps:
                        <ol>
                            <li>
                                Use left mouse button and click the Group field. The drop-down list will open.
                            </li>
                            <li>
                                Click the chosen group name with the left mouse button. Once the Group assignment is changed the Risk owner change will be obligatory in order to save changes (continue procedure as described in the point 6)
                            </li>
                            <li>
                                Once the Group and Risk Owner changes are implemented click SAVE button which is located at the right bottom corner of the edit window.
                            </li>
                        </ol>
                        </li>
                        <li>
                            In order to edit the Risk Owner assignment follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Risk Owner field. The drop-down list will open.
                                </li>
                                <li>
                                    Select and click the Owner name using the left mouse button.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Cost value follow these steps:
                            <p style={{marginLeft: 42}}><b>Option 1</b></p>
                            <ol>
                                <li>
                                    Use left mouse button and click the Cost field (left side). The drop-down list will open.
                                </li>
                                <li>
                                    Select chosen values range. Units are displayed at the right side of the edited field.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>

                            <p style={{marginLeft: 4}}><b>Option 2</b></p>
                                <li>
                                    Use left mouse button and click the Cost field (right side). Text field will be activated.   
                                </li>
                                <li>
                                    Type into the field. For decimal values use dot “.”, Units are displayed at the right side of the edited field.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Time value follow these steps:
                            <p style={{marginLeft: 42}}><b>Option 1</b></p>
                            <ol>
                                <li>
                                    Use left mouse button and click the Time field (left side). The drop-down list will open.
                                </li>
                                <li>
                                    Select chosen values range. Units are displayed at the right side of the edited field. Units depend on the Phase (see point 4.7) affected by the risk event.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>

                            <p style={{marginLeft: 4}}><b>Option 2</b></p>
                                <li>
                                    Use left mouse button and click the Time field (right side). Text field will be activated.  
                                </li>
                                <li>
                                    Type into the field. For decimal values use dot “.”, Units are displayed at the right side of the edited field. Units depend on the Phase (see point 4.7) affected by the risk event.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Probability value follow these steps:
                            <p style={{marginLeft: 42}}><b>Option 1</b></p>
                            <ol>
                                <li>
                                    Use left mouse button and click the Probability field (left side). The drop-down list will open.
                                </li>
                                <li>
                                    Select chosen values range. Units are displayed at the right side of the edited field.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>

                            <p style={{marginLeft: 4}}><b>Option 2</b></p>
                                <li>
                                    Use left mouse button and click the Probability field (right one). Text fields will be activated.  
                                </li>
                                <li>
                                    Type into the field.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Subcategory follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Subcategory field. The drop-down list will open.
                                </li>
                                <li>
                                    Click the chosen Subcategory name with the left mouse button.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Phase follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Phase field. The drop-down list will open. Three options are visible, two options are available for usage.
                                </li>
                                <p style={{marginLeft: 42}}>CONSTRUCTION phase has already been finished therefore cannot be used for editing any more. In case of existing CONSTRUCTION risk events which are not CLOSED yet the phase can be changed either to PROJECT or OPERATION. There is no possibility to change back to CONSTRUCTION.</p>
                                <li>
                                    Click the chosen Phase name with the left mouse button.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Status follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Status field. The drop-down list will open. Eight options are available.
                                </li>
                                <li>
                                    Click the chosen STATUS name with the left mouse button.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the Strategy follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Strategy field. The drop-down list will open. Seven options are visible, four strategies (three for negative risk events and one neutral) can be used in the current version of the RMS.
                                </li>
                                <li>
                                    Click the chosen Strategy name with the left mouse button.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the risk Title follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Title field. Text field will be activated.
                                </li>
                                <li>
                                    Type the title text into the field. 
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the risk Specification follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Specification field. Text field will be activated.
                                </li>
                                <li>
                                    Type the Specification text into the field.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>
                        <li>
                            In order to edit the risk Response plan follow these steps:
                            <ol>
                                <li>
                                    Use left mouse button and click the Response plan field. Text field will be activated.
                                </li>
                                <li>
                                    Type the Response plan text into the field.
                                </li>
                                <li>
                                    Click SAVE button which is located at the right bottom corner of the edit window.
                                </li>
                            </ol>
                        </li>

                </ol>   
    </Paper>
  )
};
             
export default Manual;             
