import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
             

const AcronymsList = () => {
  const classes = useStyles();

  return ( <Paper className={classes.paper}>
              <Typography id="acronyms" variant="h5" paragraph>
                Acronyms
              </Typography>
              <Table>
                  <TableBody>
                      <TableRow>
                          <TableCell className={classes.acroName}>AC</TableCell><TableCell>actual cost</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>BAC</TableCell><TableCell>budget at completion</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CCB</TableCell><TableCell>change control board</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>COQ</TableCell><TableCell>cost of quality</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>COSO</TableCell><TableCell>Committee of Sponsoring Organizations</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CPAF</TableCell><TableCell>cost plus award fee</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CPFF</TableCell><TableCell>cost plus fixed fee</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CPI</TableCell><TableCell>cost performance index</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CPIF</TableCell><TableCell>cost plus incentive fee</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CPM</TableCell><TableCell>critical path method</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>CV</TableCell><TableCell>cost variance</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>DPL</TableCell><TableCell>duplicated content (risk event)</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>EAC</TableCell><TableCell>estimate at completion</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>EF</TableCell><TableCell>early finish date</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>ERM</TableCell><TableCell>enterprise risk management</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>ERWS</TableCell><TableCell>early risk warning system</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>ES</TableCell><TableCell>early start date</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>ETC</TableCell><TableCell>estimate to complete</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>ETF</TableCell><TableCell>entire facility</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>EV</TableCell><TableCell>earned value</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>EVM</TableCell><TableCell>earned value management</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>FF</TableCell><TableCell>finish-to-finish</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>FFP</TableCell><TableCell>firm fixed price</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>FPE</TableCell><TableCell>PA fixed price with economic price adjustment</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>FPIF</TableCell><TableCell>fixed price incentive fee</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>FS</TableCell><TableCell>finish to start</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>FXE</TableCell><TableCell>femtosecond x-ray experiments</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>GLB</TableCell><TableCell>global</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>HED</TableCell><TableCell>high energy-density</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>IFB</TableCell><TableCell>invitation for bid</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>IRM</TableCell><TableCell>integrated risk management</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>ISO</TableCell><TableCell>The International Organization for Standardization</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>LF</TableCell><TableCell>late finish date</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>LNK</TableCell><TableCell>linked risk event</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>LOE</TableCell><TableCell>level of effort</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>LS</TableCell><TableCell>late start date</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>MID</TableCell><TableCell>materials Imaging and dynamics</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>NEW</TableCell><TableCell>new risk event</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>OBS</TableCell><TableCell>organizational breakdown structure</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>PDM</TableCell><TableCell>precedence diagramming method</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>PMBOK</TableCell><TableCell>Project Management Body of Knowledge</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>PND</TableCell><TableCell>pending risk event</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>PV</TableCell><TableCell>planned value</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>QFD</TableCell><TableCell>quality function deployment</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RACI</TableCell><TableCell>responsible, accountable, consult, and inform</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RAM</TableCell><TableCell>responsibility assignment matrix</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RBDM</TableCell><TableCell>risk based decision making</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RBS</TableCell><TableCell>risk breakdown structure</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RFI</TableCell><TableCell>request for information</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RFP</TableCell><TableCell>request for proposal</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RFQ</TableCell><TableCell>request for quotation</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>PMI</TableCell><TableCell>Project Management Institute</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>RMS</TableCell><TableCell>risk management system</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SCS</TableCell><TableCell>spectroscopy and coherent scattering</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SF</TableCell><TableCell>start-to-finish</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SFX</TableCell><TableCell>serial femtosecond crystallography</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SOW</TableCell><TableCell>statement of work</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SPB</TableCell><TableCell>single particles, clusters, and biomolecules</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SPC</TableCell><TableCell>specific (work package - specific, group-specific) risk event</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SPI</TableCell><TableCell>schedule performance index</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SRC</TableCell><TableCell>source (content of a risk event)</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SUB</TableCell><TableCell>sub-system</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SQS</TableCell><TableCell>small quantum systems</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SS</TableCell><TableCell>start-to-start</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SV</TableCell><TableCell>schedule variance</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SWOT</TableCell><TableCell>strengths, weaknesses, opportunities, and threats</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>SXP</TableCell><TableCell>soft x-ray port</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>T&M</TableCell><TableCell>time and material contract</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>TSK</TableCell><TableCell>task (status of a risk event)</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>WBS</TableCell><TableCell>work breakdown structure</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>VAC</TableCell><TableCell>variance at completion</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className={classes.acroName}>XFEL</TableCell><TableCell>X-ray Free Electron Laser</TableCell>
                      </TableRow>
                  </TableBody>
              </Table>
    </Paper>)
}

export default AcronymsList;
