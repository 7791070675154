import React, {Component} from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../UI/Spinner';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { DEFAULT_GLOBALITY_FILTER, DEFAULT_RANGE_FILTER, DEFAULT_PHASE_FILTER, DEFAULT_STATUS_FILTER, DEFAULT_PRIORITY_FILTER } from '../../utils/consts';


const STATS_QUERY = gql`
  query statistics($reportName: String, $filter: [RiskStatsFilterInputObject!]) {
    priorityReport(reportName: $reportName, filter: $filter) { 
      key1
      key2
      value
    }
    currentReport {
      label
    }
  }
`;

class ImpactProbStats extends Component {
  render() {
    const { classes, stats, reportName } = this.props;

    const findStat = (key2, key1) => {
      let result = "0";
      
      stats.forEach(elem => {
        if(elem.key1 === key1 && elem.key2 === key2 ) {
          result = elem.value;
          return;
        }
      });

      return result;
    };


    return (
      <TableContainer component={Paper} align="center" style={{overflowX: 'visible'}}>
      <Typography color="textSecondary" gutterBottom style={{fontSize: 20}} align="left">
        Report: <span style={{color: 'black', fontWeight: 'bold'}}>{reportName}</span>
      </Typography>        
      <Table style={{width: "80%"}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{fontWeight: 'bold'}} colSpan="6"><span style={{fontSize: 18}}>PROBABILITY [ % ]</span></TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{borderRight: '1px solid rgb(238, 238, 238)', width: 40 }}>&nbsp;</TableCell>
            <TableCell style={{borderRight: '1px solid rgb(238, 238, 238)', width: 60 }}>&nbsp;</TableCell>
            <TableCell align="center" className={classes.theader}>p ≤ 10</TableCell>
            <TableCell align="center" className={classes.theader}>10 &#60; p &#8804; 25 </TableCell>
            <TableCell align="center" className={classes.theader}>25 &#60; p &#8804; 50</TableCell>
            <TableCell align="center" className={classes.theader}>50 &#60; p</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell rowSpan="4" style={{borderRight: '1px solid rgb(238, 238, 238)' }}><span style={{writingMode: 'sideways-lr', textOrientation: 'mixed', fontSize: 18, fontWeight: 'bold', marginRight: 20 }}>IMPACT</span></TableCell>
              <TableCell component="th" scope="row">
                ESSENTIAL
              </TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_essential", "probability_1")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_essential", "probability_2")}</TableCell>
              <TableCell align="center" className={classes.priority1}>{findStat("impact_essential", "probability_3")}</TableCell>
              <TableCell align="center" className={classes.priority1}>{findStat("impact_essential", "probability_4")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                SERIOUS
              </TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_serious", "probability_1")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_serious", "probability_2")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_serious", "probability_3")}</TableCell>
              <TableCell align="center" className={classes.priority1}>{findStat("impact_serious", "probability_4")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                MEDIUM
              </TableCell>
              <TableCell align="center" className={classes.priority3}>{findStat("impact_medium", "probability_1")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_medium", "probability_2")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_medium", "probability_3")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_medium", "probability_4")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                LOW
              </TableCell>
              <TableCell align="center" className={classes.priority3}>{findStat("impact_low", "probability_1")}</TableCell>
              <TableCell align="center" className={classes.priority3}>{findStat("impact_low", "probability_2")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_low", "probability_3")}</TableCell>
              <TableCell align="center" className={classes.priority2}>{findStat("impact_low", "probability_4")}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>      
    );
  }
}

const withQuery = (WrappedComponent) => (props) => (
  <Query query={STATS_QUERY} variables={{
    reportName: props.reportName,
    filter: [
      {
        name: 'phase',
        includeValues: props.filters ? props.filters.phase : DEFAULT_PHASE_FILTER
      },
      {
        name: 'status',
        includeValues: props.filters ? props.filters.status : DEFAULT_STATUS_FILTER
      },
      {
        name: 'globality',
        includeValues: props.filters ? props.filters.globality : DEFAULT_GLOBALITY_FILTER
      },
      {
        name: 'range',
        includeValues: props.filters ? props.filters.range : DEFAULT_RANGE_FILTER
      },
      {
        name: 'priority',
        includeValues: props.filters ? props.filters.priority : DEFAULT_PRIORITY_FILTER
      },
    ]
    }}>
    {({ data, loading, error }) => {
      const stats = data ? data.priorityReport : [];
      const reportName = data ? data.currentReport.label : [];
      return <WrappedComponent {...props} stats={stats} loading={loading} reportName={props.reportName || reportName} />;
    }}
  </Query>
);

const PrioritiesTable = (props) => {
  const { loading, stats, classes } = props;
  return (
    <Card style={{minWidth: 600, height: 400}}>
      <CardContent>
      {loading ? <Spinner /> : stats && <ImpactProbStats stats={stats} classes={classes}  reportName={props.reportName}/>}
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  theader: {
    width: 80,
    borderRight: '1px solid rgb(238, 238, 238)',
    whiteSpace: 'nowrap '
  },
  priority1: {
    width: 80,
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    borderRight: '1px solid rgb(238, 238, 238)'
  },
  priority2: {
    width: 80,
    fontWeight: 'bold',
    backgroundColor: 'yellow',
    borderRight: '1px solid rgb(238, 238, 238)'
  },
  priority3: {
    width: 80,
    fontWeight: 'bold',
    backgroundColor: 'lime',
    borderRight: '1px solid rgb(238, 238, 238)'
  }
});

export default withStyles(styles)(withQuery(PrioritiesTable));