import React from 'react';
import Layout from '../UI/Layout';
import withQuery from './withQuery';
import RiskEntitiesTable from './RiskEntitiesTable';
import { withApollo } from 'react-apollo';


const RiskEntitiesPage = (props: any) => {
  const { history } = props;
  const ListWithData = withApollo(withQuery(RiskEntitiesTable));

  return (
    <Layout title="Risk Events" history={history}>
      <ListWithData {...props} />
    </Layout>
  );
};

export default withQuery(RiskEntitiesPage);
