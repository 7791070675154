import gql from 'graphql-tag'

const LoginMutation = gql`
  mutation logIn($input: LogInInput!) {
    logIn(input: $input) {
      accessToken {
        id
        expiredAt
        user {
          id
          phone
          createdAt
          position
          abbreviation
          office
          name
          email
          role
          myGroups {
            id
            name
            users {
              id
              name
            }
          }    
        }
      }
      errors {
        name
        message
      }
    }
  }
`

export { LoginMutation }
