import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import mapConnection from '../../../utils/connectionMapper';

const FILTERS_QUERY = gql`
  query userFilters {
    userFilters {
      edges {
        node {
          name
          filtersString
          sortDetailsString
        }
      }
    }
  }
`;

const withQuery =
  (WrappedComponent) =>
  ({ storeFilters, ...rest }) => {
    return (
      <Query query={FILTERS_QUERY}>
        {({ data, loading, error }) => {
          let userFilters = [];

          if (data) {
            userFilters = mapConnection(data.userFilters);
          }

          return (
            <WrappedComponent
              userFilters={storeFilters === null ? userFilters : storeFilters}
              loading={loading}
              {...rest}
            />
          );
        }}
      </Query>
    );
  };

export default withQuery;