import gql from 'graphql-tag';

const UsersUpdateMutation = gql`
  mutation UsersUpdate($input: UpdateUserMutationInput!) {
    usersUpdate(input: $input) {
      user {
        id
        email
        firstName
        lastName
        name
        phone
        position
        abbreviation
        office
        leaderGroups
        riskGroup
        createdAt
        role
        status
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UsersUpdateMutation;
