import React, { PropsWithChildren } from 'react';
import Layout from '../UI/Layout';
import withQuery, { IWithMessages } from './withQuery';
import Spinner from '../UI/Spinner';
import MessagesTable from './MessagesTable';


const MessagesPage: React.FC<PropsWithChildren<IWithMessages>> = (props) => {
  const { history, rows, loading } = props;
  return (
    <Layout history={history}>
      {loading ? <Spinner /> : rows && <MessagesTable rows={rows} />}
    </Layout>
  );
};

export default withQuery(MessagesPage);
