import React, { Component } from 'react';
import {
  withStyles, Grid,
} from '@material-ui/core';
import * as compose from 'lodash.flowright';
import NavBar from '../NavBar/NavBar';
import LefDashboardtMenu from '../NavBar/Menus/LeftDashboardMenu';
import LeftAdministrationMenu from '../NavBar/Menus/LeftAdministrationMenu';
import LeftReportMenu from '../NavBar/Menus/LeftReportMenu';
import PaddedPaper from '../styling/PaddedPaper';
import LeftEmptyMenu from '../NavBar/Menus/LeftEmptyMenu';


function LeftMenuSelector({ leftManuType, children, history, title }) {
  switch (leftManuType) {
    case 'dashboard':
      return (
        <Grid item xs={12} container>
          <LefDashboardtMenu xs={2} history={history} title={title}>{children}</LefDashboardtMenu>
        </Grid>
      );
    case 'administration':
      return (
        <Grid item xs={12} container>
          <LeftAdministrationMenu xs={2} history={history} title={title}>{children}</LeftAdministrationMenu>
        </Grid>
      );
    case 'report':
      return (
        <Grid item xs={12} container>
          <LeftReportMenu xs={2} history={history} title={title}>{children}</LeftReportMenu>
        </Grid>
      );
    default:
      return (
        <Grid item xs={12} container>
          <LeftEmptyMenu xs={12} title={title}>{children}</LeftEmptyMenu>
        </Grid>
      );
  }
}

class Layout extends Component {
  render() {
    const { title, children, history, leftManuType } = this.props;
    return (
      <div>
        <NavBar history={history} />
        <PaddedPaper style={{ position: 'relative', minWidth: 1150 }} elevation={10}>
          <Grid alignContent="center" container  spacing={2} direction="column">
            {LeftMenuSelector({leftManuType, children, history, title})}
          </Grid>
        </PaddedPaper>
      </div>
    );
  }
}

const styles = theme => ({
});

export default compose(
  withStyles(styles),
)(Layout);
