import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogActions, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withApollo } from 'react-apollo';
import UsersDeleteMutation from '../../mutations/UsersDeleteMutation';

class DeleteDialog extends Component {
  handleDelete = async () => {
    const { client, handleDialogClose, user } = this.props;

    try {
      let result = await client.mutate({
        mutation: UsersDeleteMutation,
        variables: {
          input: { userId: user ? user.id : null }
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.errors[0].message});
      } else 
      if (result != null && result.data.usersDelete != null && result.data.usersDelete.errors != null && result.data.usersDelete.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.data.usersDelete.errors[0].message});
      } else {
        handleDialogClose(result.data.usersDelete.user);
      }
    } catch (err) {
      console.error(err);
      this.setState({...this.state, errorMessage: err.message});
    }
  };

    render() {
      const {
        dialogOpen,
        handleDialogClose,
        user,
        classes, 
      } = this.props;

      return (<Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Delete user</DialogTitle>
                <DialogContent>
                  <Typography>
                    Do you really want to delete user: {user ? user.email : ''} ?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={() => handleDialogClose()} color="secondary">
                    No
                  </Button>
                  <Button variant="contained" onClick={() => this.handleDelete()} color="primary">
                    Yes
                  </Button>
                </DialogActions>
                </Dialog>)
    }
}
const styles = theme => ({
    formControl: {
        width: '140px'
    }
});


export default compose(
  withStyles(styles),
  withApollo)(DeleteDialog);
    