import React, { PropsWithChildren } from 'react';
import Layout from '../UI/Layout';
import Spinner from '../UI/Spinner';
import RiskGroupsTree from './RiskGroupsTree';
import withQuery from './withQuery';
import { IWithGroups } from './withQuery';


const RiskGroupsPage: React.FC<PropsWithChildren<IWithGroups>> = (props) => {
  const { history, groups, users, loading } = props;
  return (
    <Layout history={history} leftManuType="administration">
      {loading ? <Spinner /> : groups && <RiskGroupsTree groups={groups} users={users} />}
    </Layout>
  );
};

export default withQuery(RiskGroupsPage);
