import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DataGrid, { Column, SortColumn, Row as GridRow, RowRendererProps } from 'react-data-grid';
import UpdateRiskEntityMutation from '../../mutations/UpdateRiskEntityMutation';
import AddModal from './AddModal';
import EditModal from './EditModal';
import CommentsModal from './CommentsModal';
import HistoryModal from './HistoryModal';
import remapEntity from '../../utils/remapRiskTicketFields';
import SplitModal from './SplitModal';
import CombineModal from './CombineModal';
import Spinner from '../UI/Spinner';
import { createPortal } from 'react-dom';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import './react-contextmenu.css';
import MessageDialog from './MessageDialog';
import { CSVLink } from "react-csv";
import store from '../../store/store';
import AttachmentsModal from './AttachmentsModal';
import { Filter, RiskEntityRow, IRiskEntitiesProps } from "./types";
import columnsFun from "./table/columns";
import FilterContext from './table/FilterContext';
import FilterSelector from './FilterSelector';
import defaultFilters from './FilterSelector/defaultFilters';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFilter: {
      width: 180,
      padding: 4,
      inlineSize: '100%',
      fontSize: 14
    },
    selectFilter: {
      width: 170,
    },
    smallSelectFilter: {
      width: 120,
    },
  }),
);

function EmptyRowsRenderer() {
  return <div style={{ textAlign: 'center', margin: 20 }}>No records</div>;
}

export function RiskEntitiesList(props: IRiskEntitiesProps) {

  const { entities, dictionaries, users, currentUser, sortDirection } = props;
  const csvBtn = React.useRef();
  const classes = useStyles();

  const [rows, setRows] = useState<RiskEntityRow[]>(entities);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  const [selectedRows, setSelectedRows] = useState(() => new Set<React.Key>());
  const [openDialogMessage, setOpenDialogMessage] = useState<string | null>("");
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [commentsModalOpen, setCommentsModalOpen] = useState<boolean>(false);
  const [attachmentsModalOpen, setAttachmentsModalOpen] = useState<boolean>(false);
  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [splitModalOpen, setSplitModalOpen] = useState<boolean>(false);
  const [combineModalOpen, setCombineModalOpen] = useState<boolean>(false);
  const [splitRow, setSplitRow] = useState<RiskEntityRow | null>(null);
  const [combineRows, setCombineRows] = useState<RiskEntityRow[] | null>(null);
  const [editModalRow, setEditModalRow] = useState<RiskEntityRow | null>(null);
  const [commentsModalRow, setCommentsModalRow] = useState<RiskEntityRow | null>(null);
  const [historyModalRow, setHistoryModalRow] = useState<RiskEntityRow | null>(null);
  const [attachmentsModalRow, setAttachmentsModalRow] = useState<RiskEntityRow | null>(null);
  const [filterName, setFilterName] = useState('');
  const [storeFilters, setStoreFilters] = useState(null);

  const [filters, setFilters] = useState<Filter>(defaultFilters);

  const columns = useMemo((): Column<RiskEntityRow>[] => {
    return columnsFun(store.getState().currentUser.currentUser.role === 'admin', classes, dictionaries, setFilters, users);
  }, [
    classes.textFilter,
    classes.selectFilter,
    classes.smallSelectFilter,
  ]);

  function handleAddModalClose(rowCreated: RiskEntityRow) { 
    if(rowCreated != null) {
      const myRows = rows.slice();
      myRows.push(rowCreated);
      setRows(myRows);
    }
    setAddModalOpen(false);
  };

  function handleSplitModalClose(splittedRows: RiskEntityRow[]) { 
    if(typeof splittedRows !== undefined && splittedRows != null) {
      const newRows: RiskEntityRow[] = rows.filter(function(elem){ 
        return elem.id != splitRow?.id; 
      });
      setRows([...splittedRows, ...newRows]);
    }
    setSplitModalOpen(false);
    setSplitRow(null);
  };

  function handleCombineModalClose(cRows: RiskEntityRow[]) { 
    if(typeof cRows !== undefined && cRows != null) {
      const combineRowIds = combineRows?.map((c) => c.id);
      const newRows: RiskEntityRow[] = rows.filter(function(elem){ 
        return !combineRowIds?.includes(elem.id);
      });

      setRows([...cRows, ...newRows]);
    }
    setCombineModalOpen(false);
    setCombineRows(null);
  };

  function handleEditModalClose() { 
    setEditModalOpen(false);
    setEditModalRow(null);
  };

  function handleCommentsModalClose() {
     setCommentsModalOpen(false);
     setCommentsModalRow(null);
  };

  function handleHistoryModalClose() { 
    setHistoryModalOpen(false);
    setHistoryModalRow(null);
  };

  function handleAttachmentsModalClose() { 
    setAttachmentsModalOpen(false);
    setAttachmentsModalRow(null);
  };

  function handleEditModalSave(newRow: RiskEntityRow) {
    handleSave(newRow);
    handleEditModalClose();
  };

  async function handleSave(updated: any) {
    const { client } = props;
    let rowId = rows.findIndex((r) => r.id === updated.id);

    try {
      let result = await client.mutate({
        mutation: UpdateRiskEntityMutation,
        variables: {
          input: { ...updated, id: updated.id }
        },
      });

      if (result.data.riskEntitiesUpdate != null && result.data.riskEntitiesUpdate.errors != null && result.data.riskEntitiesUpdate.errors.length > 0 ) {
          setOpenDialogMessage(result.data.riskEntitiesUpdate.errors[0].message);
      } else {
        const myRows = rows.slice();
        if(rowId > -1) {
          myRows[rowId] = remapEntity(result.data.riskEntitiesUpdate.riskEntity);
          setRows(myRows);
        } else {
          setOpenDialogMessage("RowId is null !");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  function handleDialogMessageClose(event: SyntheticEvent<Element, Event>) {
    setOpenDialogMessage(null);
  };

  function onRowDoubleClick(row: any, rowIdx: number) {
    handleEditModalOpen({}, { rowIdx: rowIdx});
  }

  function handleAddModalOpen(e: React.MouseEvent<HTMLDivElement>, { rowIdx }: { rowIdx: number }) {
    setAddModalOpen(true);
  };

  function handleSplitModalOpen(e: React.MouseEvent<HTMLDivElement>, { rowIdx }: { rowIdx: number }) {
    const row = sortedRows[rowIdx];
    if (row.canWrite) {
      setSplitModalOpen(true);
      setSplitRow(rows[rowIdx]);
    } else {
      setOpenDialogMessage("You have no write permisions!");
    }
  };

  function handleCombineModalOpen(e: React.MouseEvent<HTMLDivElement>, { rowIdx }: { rowIdx: number }) {
      const myRows: RiskEntityRow[] = [];
      selectedRows.forEach(function(value) {
        let r = rows.find((r) => (typeof r !== undefined && r.id === value));
        if (r) {
          if (r.canWrite) {
            myRows.push(r);
          } else {
            setOpenDialogMessage("You have no write permisions!");
            return;
          }
        }
      });

      setCombineRows(myRows);
      setCombineModalOpen(true);
  };

  function handleEditModalOpen(e: any, { rowIdx }: { rowIdx: number }) { 
    const row = sortedRows[rowIdx];
    if (row.canWrite) {
      setEditModalRow(row);
      setEditModalOpen(true);
    } else {
      setOpenDialogMessage("You have no write permisions!");
    }
  };

  function handleCommentsModalOpen(e: React.MouseEvent<HTMLDivElement>, { rowIdx }: { rowIdx: number }) { 
    setCommentsModalRow(sortedRows[rowIdx]);
    setCommentsModalOpen(true);
  };

  function handleHistoryModalOpen(e: React.MouseEvent<HTMLDivElement>, { rowIdx }: { rowIdx: number }) { 
    setHistoryModalRow(sortedRows[rowIdx]);
    setHistoryModalOpen(true);
  };

  function handleAttachmentsModalOpen(e: React.MouseEvent<HTMLDivElement>, { rowIdx }: { rowIdx: number }) { 
    setAttachmentsModalRow(sortedRows[rowIdx]);
    setAttachmentsModalOpen(true);
  };

  function RowRenderer(props: RowRendererProps<RiskEntityRow>) {
    return (
      <ContextMenuTrigger id="grid-context-menu" collect={() => ({ rowIdx: props.rowIdx })}>
        { props.row.status === 'CLOSED' || props.row.status === 'LINK' || props.row.status === 'RELOCATED' 
          ? <div className={"row-" + props.row.status.toLowerCase()}><GridRow onDoubleClick={() => onRowDoubleClick(props.row, props.rowIdx)} {...props}/></div>
          : <GridRow onDoubleClick={() => onRowDoubleClick(props.row, props.rowIdx)} {...props} />}
      </ContextMenuTrigger>
    );
  };

  const filteredRows = useMemo(() => {
    return rows.filter((r: RiskEntityRow) => {
      return (
             (filters.title                 ? r.title && r.title.toLowerCase().includes(filters.title.toLowerCase()) : true)
          && (filters.identifier            ? r.identifier.includes(filters.identifier) : true)
          && (filters.specification         ? r.specification.toLowerCase().includes(filters.specification.toLowerCase()) : true)
          && (filters.migrationScenario     ? r.migrationScenario.toLowerCase().includes(filters.migrationScenario.toLowerCase()) : true)

          && (filters.status      && filters.status.length > 0 ? filters.status.includes(r.status) : true )
          && (filters.category    && filters.category.length > 0 ? filters.category.includes(r.category) : true )
          && (filters.subcategory && filters.subcategory.length > 0 ? filters.subcategory.includes(r.subcategory) : true )
          && (filters.strategy    && filters.strategy.length > 0 ? filters.strategy.includes(r.strategy) : true )
          && (filters.phase       && filters.phase.length > 0 ? filters.phase.includes(r.phase) : true )
          && (filters.source      && filters.source.length > 0 ? filters.source.includes(r.source) : true )
          && (filters.priority    && filters.priority.length > 0 ? filters.priority.includes(r.priority) : true )
          && (filters.range       && filters.range.length > 0 ? filters.range.includes(r.range) : true )
          && (filters.report      && filters.report.length > 0 ? filters.report.includes(r.report) : true )
          && (filters.globality   && filters.globality.length > 0 ? filters.globality.includes(r.globality) : true )
          && (filters.impact      && filters.impact.length > 0 ? filters.impact.includes(r.impact) : true )

          && (filters.owner && filters.owner !== 'All' ? r.owner === filters.owner : true )
          && (filters.probability !== 'All' ? r.probability === filters.probability : true)
          && (filters.riskGroup   !== 'All' ? r.riskGroup === filters.riskGroup : true)
          && (filters.overtime    !== 'All' ? r.overtime === filters.overtime : true)
          && (filters.overcost    !== 'All' ? r.overcost === filters.overcost : true)
          && (filters.createdAt[0]!== null  ? new Date(r.createdAt).getTime() >= new Date(filters.createdAt[0]).getTime() : true)
          && (filters.createdAt[1]!== null  ? new Date(r.createdAt).getTime() <= new Date(filters.createdAt[1]).getTime() : true)
          && (filters.updatedAt[0]!== null  ? new Date(r.updatedAt).getTime() >= new Date(filters.updatedAt[0]).getTime() : true)
          && (filters.updatedAt[1]!== null  ? new Date(r.updatedAt).getTime() <= new Date(filters.updatedAt[1]).getTime() : true)
      );
    });
  }, [rows, filters]);

  const sortedRows: RiskEntityRow[] = useMemo(() => {
    if (sortColumns.length === 0) return filteredRows;
    const { columnKey, direction } = sortColumns[0];

    let sortedRows: RiskEntityRow[] = [...filteredRows || rows];

    switch (columnKey) {
      case 'identifier':
      case 'title':
      case 'specification':
      case 'migrationScenario':
      case 'status':
      case 'owner':
      case 'category':
      case 'subcategory':
      case 'strategy':
      case 'phase':
      case 'source':
      case 'priority':
      case 'impact':
      case 'globality':
      case 'range':
      case 'report':
      case 'probability':
      case 'riskGroup':
      case 'overtime':
      case 'overcost':
        sortedRows = sortedRows.sort((a, b) =>  (a[columnKey] || '').localeCompare(b[columnKey]));
        break;
      // case 'active':
      //   sortedRows = sortedRows.sort((a, b) => a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1);
      //   break;
      // case 'level':
      //   sortedRows = sortedRows.sort((a, b) => (a[sortColumn] | 0) - (b[sortColumn]));
      //   break;
      default:
    }

    return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
  }, [rows, filteredRows, sortColumns]);  

  function rowKeyGetter(row: RiskEntityRow) {
    return row.id;
  }  

  return (
      <Grid item container xs={12} id="myGrid">
        { props.loading ? <Spinner /> :
        (
          <Grid item container>
            <Grid item container alignItems='center'>
              <Grid item xs={7}>
                <FilterSelector 
                  storeFilters={storeFilters}
                  setStoreFilters={setStoreFilters}
                  filterName={filterName}
                  setFilterName={setFilterName} 
                  tableFilters={filters}
                  setTableFilters={(f: any) => setFilters(f)}
                  tableSortDetails={sortColumns}
                  setTableSortDetails={(f: any) => setSortColumns(f)} />
              </Grid>
              <Grid container item xs={5} alignItems="center" justify="flex-end" >
                <Button variant="contained" color="primary" onClick={() => setAddModalOpen(true) } style={{marginRight: 15}}>Add</Button>
                <Button variant="contained" color="primary" onClick={() => {
                  if(typeof csvBtn !== undefined && typeof csvBtn.current !== undefined && typeof csvBtn.current.link !== undefined ) {
                    csvBtn.current.link.click();
                  }
                }}>Export to CSV</Button>
                <CSVLink
                  data={sortedRows.map((r) => {
                    return {
                      identifier: r.identifier,
                      title: r.title,
                      specification: r.specification,
                      response_plan: r.migrationScenario,
                      status: r.status,
                      owner: r.owner,
                      category: r.category,
                      subcategory: r.subcategory,
                      strategy: r.strategy,
                      phase: r.phase,
                      source: r.source,
                      priority: r.priority,
                      impact: r.impact,
                      globality: r.globality,
                      range: r.range,
                      report: r.report,
                      probability: r.probability,
                      risk_group: r.riskGroup,
                      overtime: r.overtime,
                      overcost: r.overcost
                    };
                  }) }
                  filename={"export.csv"}
                  style={{display:'none'}}
                  ref={csvBtn}
                  target="_blank"
                  separator={";"}
                >
                  Export to CSV
                </CSVLink>
              </Grid>
            </Grid>
        <FilterContext.Provider value={filters}>
          <DataGrid
            style={{ width: '100%', height: window !== undefined ? window.innerHeight * 0.82 : 100, marginTop: 10}}
            rowKeyGetter={rowKeyGetter}
            columns={columns}
            emptyRowsRenderer={EmptyRowsRenderer}
            sortColumns={sortColumns}
            onSortColumnsChange={onSortColumnsChange}
            direction={sortDirection}
            rows={sortedRows}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            rowClass={row => row.status === 'CLOSED' ? 'highlight' : undefined}
            rowRenderer={RowRenderer}
            rowHeight={28}
            headerRowHeight={filters ? 65 : undefined}
            defaultColumnOptions={{
              sortable: true,
              resizable: true
            }}
          /> 
        </FilterContext.Provider>

          {createPortal(
            <ContextMenu id="grid-context-menu">
              <MenuItem onClick={handleAddModalOpen}>Add</MenuItem>
              <MenuItem onClick={handleEditModalOpen}>Edit</MenuItem>
              <MenuItem onClick={handleSplitModalOpen}>Split</MenuItem>
              <MenuItem onClick={handleCombineModalOpen}>Combine</MenuItem>
              <MenuItem onClick={handleHistoryModalOpen}>History</MenuItem>
              <MenuItem onClick={handleCommentsModalOpen}>Comments</MenuItem>
              <MenuItem onClick={handleAttachmentsModalOpen}>Attachments</MenuItem>
            </ContextMenu>,
            document.body
          )}
        <AddModal       addModalOpen={addModalOpen}
                        handleAddModalClose={handleAddModalClose}
                        currentUser={currentUser}
                        dictionaries={dictionaries} />
        <SplitModal     splitModalOpen={splitModalOpen}
                        handleSplitModalClose={(event: any) => handleSplitModalClose(event)}
                        row={splitRow}
                        dictionaries={dictionaries}
                        currentUser={currentUser} />
        <CombineModal   combineModalOpen={combineModalOpen}
                        handleCombineModalClose={handleCombineModalClose}
                        rows={combineRows}
                        dictionaries={dictionaries}
                        users={users}
                        currentUser={currentUser} />
        <EditModal      editModalOpen={editModalOpen}
                        handleEditModalClose={handleEditModalClose}
                        handleEditModalSave={handleEditModalSave}
                        row={editModalRow}
                        users={users}
                        currentUser={currentUser}
                        dictionaries={dictionaries} />
        <CommentsModal  commentsModalOpen={commentsModalOpen}
                        handleCommentsModalClose={handleCommentsModalClose}
                        row={commentsModalRow} />
        <HistoryModal   historyModalOpen={historyModalOpen}
                        handleHistoryModalClose={handleHistoryModalClose}
                        row={historyModalRow} />
        <MessageDialog  messageDialogOpen={ openDialogMessage !== null && openDialogMessage.length > 0 }
                        handleMessageDialogClose={handleDialogMessageClose}
                        message={openDialogMessage} />
        <AttachmentsModal attachmentsModalOpen={attachmentsModalOpen}
                        handleAttachmentsModalClose={handleAttachmentsModalClose}
                        row={attachmentsModalRow} />
      </Grid>
      )
    }
    </Grid>
  );
};

export default withRouter(RiskEntitiesList);
