import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withApollo } from 'react-apollo';
import RiskGroupsUpdateMutation  from '../../mutations/RiskGroupsUpdateMutation';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditDialog extends Component {
  handleAdd = async () => {
    const { client, handleDialogClose } = this.props;

    try {
      let result = await client.mutate({
        mutation: RiskGroupsUpdateMutation,
        variables: {
          input: { 
            groupId: parseInt(this.state.group.id),
            name: this.state.group.name, 
            abbreviation: this.state.group.abbreviation, 
            leaderId: this.state.group.leader.id, 
            number: parseInt(this.state.group.number),
            parentGroupId: parseInt(this.state.group.parentGroup.id)}
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.errors[0].message});
      } else 
      if (result != null && result.data.riskGroupsUpdate != null && result.data.riskGroupsUpdate.errors != null && result.data.riskGroupsUpdate.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.data.riskGroupsUpdate.errors[0].message});
      } else {
        handleDialogClose(result.data.riskGroupsUpdate.riskGroups);
      }
    } catch (err) {
      console.error(err);
      this.setState({...this.state, errorMessage: err.message});
    }
  };

    constructor(props) {
      super(props);
      this.state = {
        group: props.group,
        errorMessage: null
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.group.id !== this.props.group.id) {
        this.setState({...this.state, group: this.props.group});
      }
    }    

    render() {
      const {
        open,
        handleDialogClose,
        classes,
        groups,
        users,
      } = this.props;

      return (<Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Edit risk group</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Name"
                            value={this.state.group ? this.state.group.name : null}
                            multiline
                            onChange={(event) => {this.setState({
                                                    ...this.state, 
                                                    group: {
                                                      ...this.state.group,
                                                      name: event.target.value
                                                  }})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Abbreviation"
                            value={this.state.group ? this.state.group.abbreviation : null}
                            multiline
                            onChange={(event) => {this.setState({...this.state, group: {...this.state.group, abbreviation: event.target.value}}) }}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Group Leader</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.group ? this.state.group.leader.name : null}
                          onChange={(event) => {
                            this.setState({...this.state, group: { ...this.state.group, leader: users.find((u) => u.name === event.target.value) }})
                          }}
                        >
                          {users && users.map((user) => (
                            <MenuItem value={user.name}>{user.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Parent group</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.group && this.state.group.parentGroup ? this.state.group.parentGroup.name : null}
                          onChange={(event) => 
                            this.setState({...this.state, group: { ...this.state.group, parentGroup: groups.find((g) => g.name === event.target.value)}})
                          }
                        >
                          {groups && groups.map((group) => (
                              <MenuItem key={group.id} value={group.name}>{group.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Group number"
                            value={this.state.group ? this.state.group.number: null}
                            onChange={(event) => {this.setState({
                                                    ...this.state, 
                                                    group: {
                                                      ...this.state.group,
                                                      number: event.target.value
                                                  }})}}
                            number                                                  
                            style={{width: 200}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.errorMessage ? <Alert severity="warning">{this.state.errorMessage}</Alert> : ''}
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleDialogClose(null)}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button variant="contained"  color="primary" onClick={() => this.handleAdd()}>Save</Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                </Dialog>)
    }
}
const styles = theme => ({
    formControl: {
        width: '300px'
    }
});

export default compose(
  withStyles(styles),
  withApollo
)(EditDialog);
    