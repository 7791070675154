import React from 'react';
import { Paper, withStyles } from '@material-ui/core'
import classNames from "classnames";
import Loading from '../Loading';

const styles = () => ({
  root: {
    margin: '30px 10px',
    padding: 25,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
    position: 'relative',
  }
})

const PaddedPaper = ({ classes, className, children, style, loading }) => (
  <Paper style={style} className={classNames(className, classes.root)} elevation={10}>
    <Loading relative active={loading}  />
    {children}
  </Paper>
)

export default withStyles(styles)(PaddedPaper);
