import React, { PropsWithChildren, useEffect } from 'react';
import withQuery, { IWithMessageCounter } from './withQuery';
import BadgeWithCounter from './BadgeWithCounter';


const BadgeComponent: React.FC<PropsWithChildren<IWithMessageCounter>> = (props) => {
  const { history, messagesCount, loading, refetch} = props;

  useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      refetch();
    }, 60000);
    return () => clearInterval(interval);
  }, [messagesCount, refetch]);

  return (<div>
            <BadgeWithCounter messagesCount={loading ? 0 : messagesCount} history={history} /> 
          </div>
  );
};

export default withQuery(BadgeComponent);