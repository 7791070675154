import gql from 'graphql-tag';


const UpdateRiskConfigMutation = gql`
  mutation Update($input: UpdateRiskConfigMutationInput!) {
    riskConfigUpdate(input: $input) {
      status

      errors {
        name
        message
      }
    }
  }
`;

export default UpdateRiskConfigMutation;
