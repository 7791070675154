import React, { useState } from 'react';
import { IRiskGroup } from './types';
import { Paper, Grid, Divider, Button } from '@material-ui/core';
import { IUser } from '../Analysis/types';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';
import DeleteDialog from './DeleteDialog';

interface GroupDetailsProps {
    group?: IRiskGroup;
    users: IUser[];
    groups: IRiskGroup[];
    handleUpdateRiskGroups: any;
}

const renderUsers = (users: IUser[] | undefined) => {
    if(users && users.length > 0) {
        return (users.map((user: IUser) => 
                                    <Chip
                                        key={user.id}
                                        icon={<FaceIcon />}
                                        label={user.name}
                                    />
          )
        );
    } else {
        return (<div />);
    }
}

const GroupDetails = (props: GroupDetailsProps) => {
  const { group, groups, users, handleUpdateRiskGroups } = props;
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  function handleEditDialogClose(groups: IRiskGroup[] | null) {
    if (groups){
      handleUpdateRiskGroups(groups);
    }
    setEditDialogOpen(false);
  }

  function handleDeleteDialogClose(groups: IRiskGroup[] | null) {
    if (groups){
      handleUpdateRiskGroups(groups);
    }
    setDeleteDialogOpen(false);
  }

    return (
        props.group ? 
                <Grid container spacing={2}>
                    <Grid xs={12} item align="right" spacing={3}>
                        <Button variant="contained" color="primary" style={{marginRight: 5}} onClick={() => setEditDialogOpen(true)} >Edit</Button>
                        <Button variant="contained" color="primary" style={{marginRight: 5}} onClick={() => setDeleteDialogOpen(true)}  disabled>Delete</Button>
                    </Grid>
                    <Grid xs={12} item>
                      <Paper elevation={3} style={{paddingLeft: 20, paddingTop: 30}}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>Name:</Grid>          <Grid item xs={10}>{group?.name}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={2}>Leader:</Grid>        <Grid item xs={10}>{renderUsers(group?.leader ? [group.leader] : [])}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={2}>Abbreviation:</Grid>  <Grid item xs={10}>{group?.abbreviation}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={2}>Members:</Grid>       <Grid item xs={10}>{renderUsers(group?.users)}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={2}>Group number:</Grid>  <Grid item xs={10}>{group?.number}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={2}>Default range:</Grid> <Grid item xs={10}>{group?.defaultRange}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={2}>Default globality:</Grid><Grid item xs={10}>{group?.defaultGlobality}</Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <EditDialog open={editDialogOpen}
                              group={group}
                              groups={groups}
                              users={users}
                              handleDialogClose={handleEditDialogClose} />
                    <DeleteDialog open={deleteDialogOpen}
                              group={group}
                              handleDialogClose={handleDeleteDialogClose} />
                </Grid>
        : <div />
        );
}

export default GroupDetails;