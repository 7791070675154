import gql from 'graphql-tag';

const CreateRiskEntityMutation = gql`
  mutation Create($input: CreateMutationInput!) {
    riskEntitiesCreate(input: $input) {
      riskEntity{
          id
          identifier
          specification
          migrationScenario
          owner {
            name
          }
          createdBy {
            name
          }
          updatedBy {
            name
          }

          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          source {
            label
          }
          priority {
            label
          }
          impact {
            label
          }
          globality {
            label
          }
          range {
            label
          }
          report {
            label
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost

          canWrite
          ready
      }

    errors {
      name
      message
    }

    }
  }
`;

export default CreateRiskEntityMutation;
