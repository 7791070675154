import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
  },
  paper: {
    elevation: 0,
    width: '100%',
    margin: 10,
    padding: 20
  },
  acroName: {
      fontWeight: 'bold'
  },
  defName: {
      fontWeight: 'bold',
      width: 250
  }
}));
