import React from 'react';
import Spinner from '../UI/Spinner';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Query, useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const USER_QUERY = gql`
  query myUser {
    currentUser {
      id
      email
      firstName
      lastName
      name
      phone
      position
      abbreviation
      office
      createdAt
    }
  }
`;


function withQuery(WrappedComponent) {
  return (props) => {
    const { loading, error, data } = useQuery(
      USER_QUERY,
      { 
        variables: {},
        fetchPolicy: "network-only",
        errorPolicy: 'all'
      }
    );

    if (error) return <p>ERROR</p>;
    const user = !error && !loading ? data.currentUser : null;

    return <WrappedComponent {...props} user={user} loading={loading} />;
  };
};

const UserCard = (props) => {
  const { user } = props;

  return (
       <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            User Information
          </Typography>
          <Table size="small" aria-label="a dense table">
            <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Name:
                  </TableCell>
                  <TableCell>
                    {user.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Email:
                  </TableCell>
                  <TableCell>
                    {user.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Position:
                  </TableCell>
                  <TableCell>
                    {user.position}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Abbreviation:
                  </TableCell>
                  <TableCell>
                    {user.abbreviation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Office:
                  </TableCell>
                  <TableCell>
                    {user.office}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Phone:
                  </TableCell>
                  <TableCell>
                    {user.phone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    Created at:
                  </TableCell>
                  <TableCell>
                    {moment(user.createdAt).format('yyyy-MM-DD / HH:mm')}
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>          
        </CardContent>
      </Card>
  );
};


const Profile = (props) => {
  const { loading, user } = props;
  return (
    <div>
      {loading ? <Spinner /> : user && <UserCard user={user} />}
    </div>
  );
};

export default withQuery(Profile);
