import React from 'react';
import { red } from '@material-ui/core/colors';

const error = ({ content }) => (
  <div
    style={{
      fontFamily: 'roboto',
      fontSize: '12px',
      color: red[400],
    }}
  >
    {content}
  </div>
);

export default error;
