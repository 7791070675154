import React, { PropsWithChildren } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ReportDictionaryRow, ReportStatRow } from './types';
import mapConnection from '../../utils/connectionMapper';


const TWO_REPORTS_QUERY = gql`
  query stats($first: String!, $second: String!, $dictionaryFilter: DictionaryFilterInputObject!) {
    twoReportsStatistics(firstReportName: $first, secondReportName: $second) {
      first {
        type
        status
        leftPndCount
        leftNewCount
        leftClsCount
        leftTskCount
        leftSum
        leftTotalSum
        centerConSubCount
        centerConEtfCount
        centerConGlbCount
        centerOprSubCount
        centerOprEtfCount
        centerOprGlbCount
        centerProjSubCount
        centerProjEtfCount
        centerProjGlbCount
        centerProjSum        
        centerOprSum
        centerConSum
        centerTotalSum
        rightPndNewCount
        rightClsCount
      }
      second {
        type
        status
        leftPndCount
        leftNewCount
        leftClsCount
        leftTskCount
        leftSum
        leftTotalSum
        centerConSubCount
        centerConEtfCount
        centerConGlbCount
        centerOprSubCount
        centerOprEtfCount
        centerOprGlbCount
        centerProjSubCount
        centerProjEtfCount
        centerProjGlbCount
        centerProjSum        
        centerOprSum
        centerConSum
        centerTotalSum
        rightPndNewCount
        rightClsCount
      }
      diff {
        type
        status
        leftPndCount
        leftNewCount
        leftClsCount
        leftTskCount
        leftSum
        leftTotalSum
        centerConSubCount
        centerConEtfCount
        centerConGlbCount
        centerOprSubCount
        centerOprEtfCount
        centerOprGlbCount
        centerProjSubCount
        centerProjEtfCount
        centerProjGlbCount
        centerProjSum
        centerOprSum
        centerConSum
        centerTotalSum
        rightPndNewCount
        rightClsCount
      }
      firstReportName
      secondReportName
    }
    dictionaries(filter: $dictionaryFilter) {
      edges {
        node {
          id
          typeName
          label
          level
          active
          description
          startDate
        }
      }
    }
  }
`;

export interface IWithReport{
  report1: string;
  report2: string;
  first: ReportStatRow[];
  second: ReportStatRow[];
  diff: ReportStatRow[];
  reports: ReportDictionaryRow[];
  loading?: boolean;
  history?: any;
  refetch?: any;
  firstReportName?: string;
  secondReportName?: string;
  firstOperationItems?: number;
  secondOperationItems?: number;
  firstConstructionItems?: number;
  secondConstructionItems?: number;
  firstProjectItems?: number;
  secondProjectItems?: number;
}

function withQuery(WrappedComponent: React.FC<PropsWithChildren<IWithReport>>) {
  return (props: PropsWithChildren<IWithReport>) => {
    const { loading, error, data, refetch } = useQuery<any, {}>(
      TWO_REPORTS_QUERY,
      { 
        variables: {
          first: props.report1 || "",
          second: props.report2 || "",
          dictionaryFilter: { "type": "Dictionaries::Report"}
        },
        fetchPolicy: "no-cache"
      }
    );

    if (error) return <p>ERROR</p>;

    const first = data ? data.twoReportsStatistics.first : [];
    const second = data ? data.twoReportsStatistics.second : [];
    const diff = data ? data.twoReportsStatistics.diff : [];
    const reports = data ? mapConnection(data.dictionaries) : [];
    const firstReportName = data ? data.twoReportsStatistics.firstReportName : "";
    const secondReportName = data ? data.twoReportsStatistics.secondReportName : "";

    const fSummary: ReportStatRow = first.find((f: ReportStatRow) => f.type === 'summary' && f.status == null);
    const sSummary: ReportStatRow = second.find((f: ReportStatRow) => f.type === 'summary' && f.status == null);
    const fOperationItems = fSummary ? fSummary.centerOprSum / ( (fSummary.rightPndNewCount || 1)) : 0;
    const sOperationItems = sSummary ? sSummary.centerOprSum / ( (sSummary.rightPndNewCount || 1)) : 0;
    const fConstItems = fSummary ? fSummary.centerConSum/ ( (fSummary.rightPndNewCount || 1)) : 0;
    const sConstItems = sSummary ? sSummary.centerConSum / ( (sSummary.rightPndNewCount || 1)) : 0;
    const fProjItems = fSummary ? fSummary.centerProjSum/ ( (fSummary.rightPndNewCount || 1)) : 0;
    const sProjItems = sSummary ? sSummary.centerProjSum / ( (sSummary.rightPndNewCount || 1)) : 0;

    return (
      <WrappedComponent {...props}
        first={first}
        second={second}
        diff={diff}
        reports={reports}
        loading={loading}
        refetch={refetch}
        firstReportName={firstReportName}
        secondReportName={secondReportName}
        firstOperationItems={fOperationItems}
        secondOperationItems={sOperationItems}
        firstConstructionItems={fConstItems}
        secondConstructionItems={sConstItems}
        firstProjectItems={fProjItems}
        secondProjectItems={sProjItems}
        />
    );
  };
}

export default withQuery;
