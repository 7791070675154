import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';


export default ({open, handleClose, handleAction}) => {
    return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning !</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontWeight:'bold', color: 'red'}}>
            PLEASE BE AWARE, that changes provided here have significant influ-ence on the Risk Management System functionality it’s sensitivity aswell   as   general   effectiveness   and   thereof   directly   affect   strategicgoals of the entire Organization.            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained"  onClick={() => handleClose()} color="primary" autoFocus>
            NO
          </Button>
          <Button variant="contained" disabled onClick={handleAction} color="secondary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    )
};      