import React, {Component} from 'react';
import {HorizontalGridLines, VerticalBarSeries, XAxis, FlexibleXYPlot, YAxis} from 'react-vis';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../UI/Spinner';
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { DEFAULT_PRIORITY_FILTER } from '../../../utils/consts';


const TRENDS_QUERY = gql`
  query trends($filter: [RiskStatsFilterInputObject!]) {
    trendsStatistics(filter: $filter) { 
      reportName
      priorityI
      priorityIi
      priorityIii
    }
  }
`;

const PAGE_SIZE = 12;

class Chart extends Component {

  state = {
    page: 0,
  };

  render() {
    const { trends } = this.props;
    const len = trends.length;

    const fromCalc = Math.max(len + (this.state.page -1 ) * PAGE_SIZE, 0);
    const from = fromCalc < PAGE_SIZE / 2 ? 0 : fromCalc;
    const to = len + (this.state.page * PAGE_SIZE);
    const pagedTrends = trends.slice(from, to);

    const data1 = pagedTrends.map((t) => (
      { x: t.reportName, y: t.priorityI }
    ));
    const data2 = pagedTrends.map((t) => (
      { x: t.reportName, y: t.priorityIi }
    ));
    const data3 = pagedTrends.map((t) => (
      { x: t.reportName, y: t.priorityIii }
    ));

    const hasNextPage = to < len;
    const hasPrevPage = from > 0;

    return (
      <>
        <FlexibleXYPlot style={{width: '100%'}} height={600} xType="ordinal" margin={{bottom: 80}} ma>
          <HorizontalGridLines style={{ stroke: "rgba(77, 106, 120, .1)" }} />
          <VerticalBarSeries data={data1} color={'red'} animation />
          <VerticalBarSeries data={data2} color={'yellow'} animation />
          <VerticalBarSeries data={data3} color={'lime'} animation />
          <YAxis />
          <XAxis tickLabelAngle={-90} style={{ fontSize: 10 }} left={44} />
        </FlexibleXYPlot>
        <Button variant="contained" disabled={!hasPrevPage} onClick={() => { this.setState({...this.state, page: this.state.page - 1}) }} color="primary">
          Previous page
        </Button>
        &nbsp;
        <Button variant="contained" disabled={!hasNextPage} onClick={() => {this.setState({...this.state, page: this.state.page + 1})}} color="primary">
          Next page
        </Button>
      </>
    );
  }
};

const withQuery = (WrappedComponent) => (props) => (
  <Query query={TRENDS_QUERY}
  variables={{
    filter: [
      {
        name: 'phase',
        includeValues: props.filters ? props.filters.phase : DEFAULT_PHASE_FILTER
      },
      {
        name: 'status',
        includeValues: props.filters ? props.filters.status : DEFAULT_STATUS_FILTER
      },
      {
        name: 'globality',
        includeValues: props.filters ? props.filters.globality : DEFAULT_GLOBALITY_FILTER
      },
      {
        name: 'range',
        includeValues: props.filters ? props.filters.range : DEFAULT_RANGE_FILTER
      },
      {
        name: 'priority',
        includeValues: props.filters ? props.filters.priority : DEFAULT_PRIORITY_FILTER
      },
    ]
}}>
    {({ data, loading, error }) => {
      const trends = data ? data.trendsStatistics : [];
      return <WrappedComponent {...props} trends={trends} loading={loading}/>;
    }}
  </Query>
);

const TrendsChart = (props) => {
  const { loading, trends, classes } = props;

  return (
      <Grid item style={{marginTop: 20}}>
        {loading ? <Spinner /> : <Chart trends={trends} classes={classes} />}
      </Grid>
  );
};

const styles = theme => ({
});

export default withStyles(styles)(withQuery(TrendsChart));