import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { Dialog, DialogTitle, DialogContent, withStyles, Checkbox, FormControlLabel, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import mapConnection from '../../utils/connectionMapper';
import CommentsAddMutation from '../../mutations/CommentsAddMutation';
import { withApollo } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import store from '../../store/store';


const COMMENTS_GQL = gql`
  query comments($filter: CommentFilterInputObject, $orderBy: [[String!]!]) {
    comments(filter: $filter, orderBy: $orderBy) {
      edges {
        node {
          id
          commentType
          content
          createdAt
          user {
            name
          }
        }
      }
    }
  }`

const withQuery = (WrappedComponent) => (props) => (
  <div>
  {props.commentsModalOpen ? 
  <Query query={COMMENTS_GQL}
      variables={{
        filter: {
          riskEntityId: props.row ? props.row.id : null
        },
        search: {
        },
        orderBy: [["createdAt", "ASC"]]
  }}>
    {({ data, loading, error }) => {
      const comments = data ? mapConnection(data.comments) : [];

      return <WrappedComponent {...props} comments={comments} loading={loading} />;
    }}
  </Query> : null}
  </div>
);


class CommentsModal extends Component {
  constructor(props) {
    super(props);
    const { comments } = props;
    this.state = {
      comments: comments,
      loading: false,
      content: null,
      commentType: 'comment'
    };
  }

  handleAddComment = async () => {
    const { client, row } = this.props;

    try {
      let result = await client.mutate({
        mutation: CommentsAddMutation,
        variables: {
          input: { riskEntityId: row.id, content: this.state.content, commentType: this.state.commentType }
        },
      });

      const newComments = this.state.comments;
      newComments.push(result.data.commentsCreate.comment);

      this.setState({
        ...this.state,
        loading: false,
        content: '',
        comments: newComments
      });
    } catch (err) {
      console.error(err);
    }
  };

    render() {
      const {commentsModalOpen, handleCommentsModalClose, classes, row} = this.props;

      return (<Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={commentsModalOpen}
                onClose={handleCommentsModalClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Comments: {row == null ? '' : row.identifier}</DialogTitle>
                <DialogContent>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} style={{maxHeight: 440}}>
                      <Table className={classes.table} stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell width={'10%'} align="center">When</TableCell>
                            <TableCell width={'10%'} align="center">Who</TableCell>
                            <TableCell width={'40%'} align="center">Comment</TableCell>
                            <TableCell align="center">Recommendation</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.comments.map((comment => (
                            <TableRow key={comment.id}>
                              <TableCell>{moment(comment.createdAt).format('yyyy-MM-DD / HH:mm')}</TableCell>
                              <TableCell align="center">{comment.user ? comment.user.name : '-'}</TableCell>
                              <TableCell>{comment.commentType === 'comment' ? comment.content : ''}</TableCell>
                              <TableCell>{comment.commentType === 'recommendation' ? comment.content : ''}</TableCell>
                            </TableRow>
                          )))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={10} style={{marginBottom: 20, marginTop: 20}}>
                    <TextField
                        id="standard-multiline-flexible"
                        label="Add Comment"
                        multiline
                        rowsMax={5}
                        value={this.state.content}
                        onChange={(event) => {this.setState({...this.state, content: event.target.value}) }}
                        style={{width: '95%'}}
                      />
                  </Grid>
                  <Grid item xs={2} style={{marginBottom: 20, marginTop: 20}}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox value="remember" color="primary"
                            disabled={store.getState().currentUser.currentUser.role !== 'risk_coordinator'}
                            onChange={(event) => {
                              const commentType = event.target.checked ? 'recommendation' : 'comment';
                              this.setState({...this.state, commentType: commentType})
                            }} />
                        }
                        label="Recommendation"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleCommentsModalClose()}>Close</Button>        
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Button variant="contained" color="primary"
                        onClick={() => this.handleAddComment()}
                      > Add </Button>
                  </Grid>
                  </Grid>
                  </DialogContent>
                </Dialog>);
    }
}
const styles = theme => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      }}
);

const CommentsModalWrapper = (props) => {
  const {loading} = props;
  return (
      <div>
        {loading ? null : <CommentsModal {...props} />}
      </div>
  );
};

export default compose(
  withStyles(styles),
  withApollo
)(withQuery(CommentsModalWrapper));