import React from 'react';
import { Query } from 'react-apollo';
import mapConnection from '../../utils/connectionMapper';
import gql from 'graphql-tag';

const DICTIONARIES_QUERY = gql`
  query dictioneries {
    dictionaries {
      edges {
        node {
          id
          typeName
          type
          label
          level
          active
          description
        }
      }
    }
  }
`;

const withQuery = (WrappedComponent) => (props) => (
  <Query query={DICTIONARIES_QUERY}>
    {({ data, loading, error }) => {
      const dicts = data ? mapConnection(data.dictionaries) : [];
      return <WrappedComponent {...props} dictionary={dicts} loading={loading} />;
    }}
  </Query>
);

export default withQuery;
