import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Typography, Link, Grid, Box, Container, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { saveToken } from '../actions/token-actions';
import { setCurrentUser } from '../actions/users-actions';
import Error from './UI/Error';
import { LoginMutation } from '../mutations/LoginMutation';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://karolkosk.pl/">
        RMS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => {
    dispatch(setCurrentUser(user));
  },
  saveToken: (token) => {
    dispatch(saveToken(token));
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      error: false,
      recovery: false,
      recoveryEmail: '',
      logging: false,
    };

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleChangeInput = (prop) => (event) => {
    this.setState({...this.state, [prop]: event.target.value, error: false });
  };

  toggleRecovery = () => this.setState({...this.state, recovery: !this.state.recovery });

  handleSignIn = () => {
    this.setState({
      ...this.state,
      logging: true,
      error: false,
    });

    this.props
      .LoginMutation({
        variables: {
          input: {
            email: this.state.login.toLowerCase(),
            password: this.state.password,
          },
        },
      })
      .then(({ data }) => {
        if (data.logIn.errors) {
          this.setState({ ...this.state, error: data.logIn.errors[0].message, logging: false });
          return;
        }
        const token = data.logIn.accessToken;
        const user = data.logIn.accessToken.user;
        this.props.saveToken(token);
        this.props.setCurrentUser(user);
        this.props.history.push(this.props.location.state ? this.props.location.state.from : '/dashboard');
        this.setState({
          ...this.state,
          logging: false,
        });
      })
      .catch(() => {
        this.setState({ ...this.state, error: 'there was an error sending the mutation', logging: false });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <LoginView
        classes={classes}
        state={this.state}
        handleChangeInput={this.handleChangeInput}
        handleSignIn={this.handleSignIn}
        toggleRecovery={this.toggleRecovery}
      />
    );
  }
}

const LoginTextField = (props) => {
  const { login, recovery, recoveryEmail, handleChangeInput, classes } = props;

  return (<TextField
            id="login"
            value={recovery ? recoveryEmail : login}
            onChange={recovery ? handleChangeInput('recoveryEmail') : handleChangeInput('login')}
            className={classes.loginInput}
            InputLabelProps={{ focused: false }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />);
};

const PasswordTextField = (props) => {
  const { password, handleChangeInput, classes, handleSignIn } = props;

  return (<TextField
              id="password"
              type="password"
              value={password}
              onChange={handleChangeInput('password')}
              onKeyPress={ (event) => {
                if (event.key === 'Enter') {
                  handleSignIn()
                }
              }}
              className={classes.loginInput}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              autoComplete="current-password"
            />);
};

const LoginButton = (props) => {
  const { recovery, handleSignIn, classes, logging } = props;

  return (<Button 
            fullWidth
            variant="contained"
            color="primary"
            disabled={logging}
            className={classes.submit}
            onClick={ 
              () => recovery ? console.log('no action now') : handleSignIn() 
            }>
            <Typography className={classes.loginButtonText}>{recovery ? 'send reset link' : 'login'}</Typography>
          </Button>);
};

const LoginView = ({ classes, state, handleChangeInput, handleSignIn }) => {
  const { login, password, recovery, recoveryEmail, error } = state;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <LoginTextField login={login} recovery={recovery} recoveryEmail={recoveryEmail} handleChangeInput={handleChangeInput} classes={classes} />
          {!recovery && (
            <PasswordTextField password={password} handleChangeInput={handleChangeInput} classes={classes} handleSignIn={handleSignIn} />
          )}

          {error ? <Error content={error} /> : null}
          <LoginButton classes={classes} login={state.login} password={state.password} handleSignIn={handleSignIn} logging={state.logging} />

          <Grid container>
            <Grid item xs>
              <Link href="/recovery">Forgot password?</Link>
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
const LoginFormWithData = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(LoginMutation, {
    name: 'LoginMutation',
    options: () => ({ context: { login: true } }),
  })
)(Login);

export default withRouter(withStyles(styles)(LoginFormWithData));
