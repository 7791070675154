import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonBase from '@material-ui/core/ButtonBase';
import BadgeComponent from './Badge/BadgeComponent';
import store from '../../store/store';

class NavBar extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    notificationsCounter: 0,
    value: '/dashboard'
  };

  handleCallToRouter = (event, newValue) => {
    this.props.history.push(newValue);
  }  

  getNavValue() {
    let val = this.props.history.location.pathname; 

    if (val === "/users") {
      val = '/groups';
    } else if (val === "/dictionary") {
      val = '/groups';
    } else if (val === "/conf-new_report") {
      val = '/groups';
    } else if (val === "/conf-priority") {
      val = '/groups';
    }

    if (val === "/reports") {
      val = '/summary';
    } else if (val === "/analysis") {
      val = '/summary';
    }

    if (val === "/profile") {
      val = '/dashboard';
    }

    return val;
  }

  render() {
    const { classes } = this.props;
    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
  
    const handleProfileMenuOpen = event => {
      this.setState({...this.state, anchorEl: event.currentTarget});
    };
  
    const handleMobileMenuClose = () => {
      this.setState({...this.state, mobileMoreAnchorEl: null});
    };
  
    const handleMenuClose = () => {
      this.setState({...this.state, anchorEl: null});
      handleMobileMenuClose();
    };

    const handleMenuProfilePressed = () => {
      handleMenuClose();
      this.props.history.push('/profile');
    };
  
    const handleMenuLogoutPressed = () => {
      handleMenuClose();
    this.props.history.push('/logout');
    };

    const handleMobileMenuOpen = event => {
      this.setState({...this.state, mobileMoreAnchorEl: event.currentTarget});
    };
  
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuProfilePressed}>Profile</MenuItem>
        <MenuItem onClick={handleMenuLogoutPressed}>Logout</MenuItem>
      </Menu>
    );
  
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );
  
    return (
      <div>
      <AppBar position="static">
        <Toolbar>
	        <ButtonBase className={classes.image}>
            <img className={classes.img} alt="complex" src="/xfel.jpg" />
          </ButtonBase>
          <Typography className={classes.title} variant="h6" noWrap>
            European XFEL, Risk Management System
          </Typography>
          <div className={classes.grow} />
          <Tabs
            value={this.getNavValue()}
            onChange={this.handleCallToRouter}
            variant="fullWidth"
            aria-label="nav tabs example"            
          >            
            <Tab value="/dashboard" label="Dashboard"/>
            <Tab value="/entities"  label="Risk Register"/>
            <Tab value="/summary"   label="Report"/>
            <Tab value="/groups"    label="Administration" disabled={store.getState().currentUser.currentUser.role !== 'admin' 
                                                                  && store.getState().currentUser.currentUser.role !== 'risk_coordinator' } />
            <Tab value="/manual"    label="Help"/>
          </Tabs>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <BadgeComponent history={this.props.history} />
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
            </div>
        </Toolbar>            
        </AppBar>

        {renderMobileMenu}
        {renderMenu}
      </div>        
    );
  }
}

const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  image: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export default compose(
  withStyles(styles),
)(NavBar);
