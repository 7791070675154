import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import UpsertUserFiltersMutation from '../../../mutations/UpsertUserFiltersMutation';
import defaultFilters from './defaultFilters';

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 220,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

export default function (props) {
  const {
    userFilters,
    tableFilters,
    setTableFilters,
    setTableSortDetails,
    tableSortDetails,
    filterName,
    setFilterName,
    setStoreFilters,
  } = props;
  const [newFilterName, setNewFilterName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [filters, setFilters] = useState(userFilters);
  const classes = useStyles();

  const handleChange = (event) => {
    const newFilters = filters.find(
      (fName) => fName.name === event.target.value
    );

    if (
      typeof newFilters === 'undefined' ||
      newFilters === null ||
      newFilters.filtersString === null ||
      newFilters.filtersString === ''
    ) {
      setTableFilters(defaultFilters);
      setTableSortDetails([]);
      setFilterName('');
    } else {
      setTableFilters(JSON.parse(newFilters.filtersString));
      setTableSortDetails(JSON.parse(newFilters.sortDetailsString) || []);
      setFilterName(event.target.value);
    }
  };

  const handleClearNewFilterInput = ({ userFiltersUpsert }) => {
    setStoreFilters(userFiltersUpsert.filters);
    setIsSaving(false);
    setFilterName(newFilterName);
    setNewFilterName('');
  };

  const handleClearFilters = () => {
    setFilterName('');
    setNewFilterName('');
    setTableFilters(defaultFilters);
    setTableSortDetails([]);
  };

  async function handleSave() {
    const { client } = props;

    try {
      setIsSaving(true);
      const { data } = await client.mutate({
        mutation: UpsertUserFiltersMutation,
        variables: {
          input: {
            name: newFilterName,
            filtersString: JSON.stringify(tableFilters),
            sortDetailsString: JSON.stringify(tableSortDetails),
          },
        },
      });
      handleClearNewFilterInput(data);
    } catch (err) {
      setIsSaving(false);
      console.error(err);
    }
  }

  return (
    <Grid item container alignItems="center">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="select-filters-label">Filter</InputLabel>
        <Select
          value={filterName}
          onChange={handleChange}
          labelId="select-filters-label"
          MenuProps={MenuProps}
          style={{ width: 220 }}
          className={classes.selectEmpty}
          label="Filter"
        >
          <MenuItem value={''}>
            <em>Default</em>
          </MenuItem>

          {filters.map((filter) => {
            return <MenuItem value={filter.name}>{filter.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <TextField
        id="new-filter-name-input"
        label="New filter name"
        variant="outlined"
        value={newFilterName}
        onChange={(event) => setNewFilterName(event.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: 15 }}
        disabled={newFilterName.length === 0 || isSaving}
        onClick={() => handleSave()}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: 15 }}
        onClick={() => handleClearFilters()}
      >
        Clear
      </Button>
    </Grid>
  );
}
