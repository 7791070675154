import React from 'react';
import Layout from '../UI/Layout';
import Spinner from '../UI/Spinner';
import UsersTable from './UsersTable';
import withQuery from './withQuery';

const UsersPage = (props) => {
  const { history, loading, users, groups } = props;
  return (
    <Layout title="Users" history={history} leftManuType="administration">
      {loading ? <Spinner /> : users && <UsersTable users={users} groups={groups} />}
    </Layout>
  );
};

export default withQuery(UsersPage);
