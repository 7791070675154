export default {
  identifier: '',
  title: '',
  specification: '',
  migrationScenario: '',
  status: [],
  owner: 'All',
  category: [],
  subcategory: [],
  strategy: [],
  phase: [],
  source: [],
  priority: [],
  impact: [],
  globality: [],
  range: [],
  report: [],
  probability: 'All',
  riskGroup: 'All',
  overtime: 'All',
  overcost: 'All',
  canWrite: '',
  ready: '',
  enabled: true,
  createdAt: [null, null],
  updatedAt: [null, null],
  closedAt: [null, null],
};
