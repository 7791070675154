import React, { PropsWithChildren } from 'react';
import { IWithMessageCounter } from './withQuery';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';


const BadgeWithCounter: React.FC<PropsWithChildren<IWithMessageCounter>> = (props) => {
  return (
    <IconButton aria-label="show new notifications" color="inherit">
      <Badge badgeContent={props.messagesCount} color="secondary">
        <NotificationsIcon 
          onClick={() => props.history.push('messages')}
        />
      </Badge>
    </IconButton>
  );
};

export default BadgeWithCounter;
