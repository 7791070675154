import { SET_GRAPHQL_ERRORS, SET_NETWORK_ERROR, HIDE_ERROR_POPUP } from '../actions/action-types';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NETWORK_ERROR:
      return {
        ...state,
        visible: true,
        networkError: payload.error,
        operation: state.operation ? [...state.operation, payload.operation] : [payload.operation],
      };
    case SET_GRAPHQL_ERRORS:
      return {
        ...state,
        visible: true,
        graphQLErrors: payload.error,
        operation: state.operation ? [...state.operation, payload.operation] : [payload.operation],
      };

    case HIDE_ERROR_POPUP:
      return {
        ...state,
        visible: false,
      };

    default:
      return state;
  }
};
