import React, { PropsWithChildren } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

const GROUPS_QUERY = gql`
  query messagesCounter {
    messagesCount
  }
`;

export interface IWithMessageCounter {
  messagesCount?: number;
  loading?: boolean;
  history?: any;
  refetch?: any;
}

function withQuery(WrappedComponent: React.FC<PropsWithChildren<IWithMessageCounter>>) {
  return (props: PropsWithChildren<IWithMessageCounter>) => {
    const { loading, error, data, refetch } = useQuery<any, {}>(
      GROUPS_QUERY,
      { 
        variables: {},
        fetchPolicy: "no-cache"
      }
    );

    if (error) return <p>ERROR</p>;

    const counter = data ? data.messagesCount : 0;

    return (
      <WrappedComponent {...props}
        messagesCount={counter}
        loading={loading} 
        refetch={refetch}
        />
    );
  };
};

export default withQuery;
