import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
             

const Purpose = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography id="purpose" variant="h5" paragraph>
        Purpose
      </Typography>
      <Typography paragraph>
        The tailored XFEL Risk Management System empowers Management Board, program managers, project managers, executives, departments and sectors to perform timely and effective decision making. A key principle of such a system is that it creates a value and is an inherent part of the decision•making.  The ERM integrated in the entire organization is a coordinated activity that takes place across the entire organization and brings together all risk management activities in an appropriate framework. ERM system harmonizes the risk management processes of the working standards. At the entire organization it provides a common approach in support of procedures dealing with a specific risks.
      </Typography>
      <Typography paragraph>
      </Typography>
      <Typography paragraph>
        The XFEL Risk Management System (RMS) is aimed to support following risk management processes:
      </Typography>
      <Typography paragraph>
            1.	Plan Risk Management;
      </Typography>
      <Typography paragraph>
            2.	Identify Risk(s);
      </Typography>
      <Typography paragraph>
            3.	Perform Qualitative risk analysis;
      </Typography>
      <Typography paragraph>
            4.	Perform Quantitative risk analysis;
      </Typography>
      <Typography paragraph>
            5.	Plan Risk Responses;
      </Typography>
      <Typography paragraph>
            6.	Implement Risk Responses;
      </Typography>
      <Typography paragraph>
            7.	Monitor Risks.
      </Typography>
      <Typography paragraph>
      </Typography>
      <Typography paragraph>
        When implemented and maintained in accordance with international standards (ISO 31000:2009, Practice Standard for Project Risk Management, COSO’s Enterprise Risk Management – Integrated Framework), the risk management system enables an organization, but is not limited to:
      </Typography>
      <Typography paragraph>     
      </Typography>
      <Typography paragraph>
            • ensure the company’s existence;
      </Typography>
      <Typography paragraph>
            • increase the likelihood of achieving objectives;
      </Typography>
      <Typography paragraph>
            • encourage proactive management;
      </Typography>
      <Typography paragraph>
            • improve the identification of opportunities and threats;
      </Typography>
      <Typography paragraph>
            • comply with relevant legal and regulatory requirements and international norms;
      </Typography>
      <Typography paragraph>
            • improve mandatory and voluntary reporting;
      </Typography>
      <Typography paragraph>
            • improve governance;
      </Typography>
      <Typography paragraph>
            • improve stakeholder confidence and trust;
      </Typography>
      <Typography paragraph>
            • establish a reliable basis for decision making and planning;
      </Typography>
      <Typography paragraph>
            • improve controls;
      </Typography>
      <Typography paragraph>
            • effectively allocate and use resources;
      </Typography>
      <Typography paragraph>
            • improve operational effectiveness and efficiency;
      </Typography>
      <Typography paragraph>
            • enhance health and safety performance, as well as environmental protection;
      </Typography>
      <Typography paragraph>
            • minimize losses;
      </Typography>
      <Typography paragraph>
            • raise awareness about risks and foster a responsible attitude toward them;
      </Typography>
      <Typography paragraph>
            • provide the bodies of the company with information about risks at an early stage.
      </Typography>
      <Typography paragraph>     
      </Typography>
      <Typography paragraph>
      </Typography>
      <Typography paragraph>
        Due to the nature of the operation phase the ERM framework is geared to achieving an entity’s objectives, set forth in following categories:
      </Typography>
      <Typography paragraph>
            •	Strategic (high level goals, aligned with and supporting a missions);
      </Typography>
      <Typography paragraph>
            •	Operations (effective and efficient use of resources);
      </Typography>
      <Typography paragraph>
            •	Reporting (reliable, unbiased data in reporting);
      </Typography>
      <Typography paragraph>
            •	Compliance (with applicable law and regulations).
      </Typography>
      <Typography paragraph>
      </Typography>
      <Typography paragraph>
      Risk policy is aimed at detecting risks early on, assessing the impact of risks on the company’s objectives, and implementing a planned risk response where necessary. An internal risk reporting process ensures that the management board has a regularly updated overview of the risks identified and any implemented or possible countermeasures.
      </Typography>
    </Paper>
  )
};
             
export default Purpose;             
