import React from 'react';
import Layout from '../UI/Layout';
import Spinner from '../UI/Spinner';
import DictionariesTable from './DictionariesTable';
import withQuery from './withQuery';

const DictionariesPage = (props: any) => {
  const { history, loading, dictionary } = props;
  return (
    <Layout title="Dictionary" history={history} leftManuType="administration">
      {loading ? <Spinner /> : dictionary && <DictionariesTable dictionary={dictionary} />}
    </Layout>
  );
};

export default withQuery(DictionariesPage);
