import React, { useContext } from 'react';
import { Filter } from "../types";
import { HeaderRendererProps, SortableHeaderCell } from 'react-data-grid';
import { useFocusRef } from "../useFocusRef";
import FilterContext from './FilterContext';

function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
    sortDirection,
    priority,
    onSort,  
    isCellSelected,
    column,
    children
  }: HeaderRendererProps<R, SR> & {
    children: (args: {
      ref: React.RefObject<T>;
      tabIndex: number;
      filters: Filter;
    }) => React.ReactElement;
  }) {
    const filters = useContext(FilterContext)!;
    const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  
    return (
      <>
        {column.sortable ?
          <SortableHeaderCell
            onSort={onSort}
            sortDirection={sortDirection}
            priority={priority}
            isCellSelected={isCellSelected}
          >
            {column.name}
          </SortableHeaderCell> :
          <div style={{paddingLeft: 5}}>{column.name}</div>}
        <div style={{paddingLeft: 5}}>{children({ ref, tabIndex, filters })}</div>
      </>
    );
  };

export default FilterRenderer;