import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withApollo } from 'react-apollo';
import Grid from '@material-ui/core/Grid';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CombineRiskEntityMutation from '../../mutations/CombineRiskEntityMutation';
import remapEntity from '../../utils/remapRiskTicketFields';
import { Alert } from '@material-ui/lab';
import Divider from '@material-ui/core/Divider';
import EditFormComponent from './EditFormComponent';


function getButtonText(stepId) {
  if(stepId === 0) {
    return 'Next';
  } else if(stepId === 1) {
    return 'Combine';
  } else if (stepId === 2) {
    return 'Confirm';
  } else {
    return 'unknown';
  }
}

function getSteps() {
  return ['Select combine options', 'Edit record', 'Summary'];
}

class CombineModal extends Component {
  handleCombine = async () => {
    const { client, handleCombineModalClose } = this.props;

    try {
      let result = await client.mutate({
        mutation: CombineRiskEntityMutation,
        variables: {
          input: {  
                    combineMode: this.state.combineMode,
                    entities: this.state.combineRows,
                    entity: this.state.entity
                 }
        },
      });

      if (result.data.riskEntitiesCombine != null && result.data.riskEntitiesCombine.errors != null && result.data.riskEntitiesCombine.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.data.riskEntitiesCombine.errors[0].message});
      } else {
        handleCombineModalClose(result.data.riskEntitiesCombine.riskEntities.map((entity) => remapEntity(entity)));
      }
    } catch (err) {
      this.setState({...this.state, errorMessage: err});
      console.error(err);
    }
  };

  constructor(props) {
    super(props);
    const {rows, currentUser} = props;
    this.state = {
      formRef: React.createRef(),
      currentUser: currentUser,
      activeStep: 0,
      combineValues: 'upload_all',
      combineMode: 'new',
      combineRows: rows.map((row) => row.id),
      entity: {} 
    };
    this.setEntity(this.state.combineMode, this.state.combineValues);
  }

  setEntity = (mode, values) => {
    const {rows} = this.props;
    let concatenatedSpec = '';
    let concatenatedMS = '';
    let row = this.state.entity;

    if(mode === 'new') {
      if(values === 'upload_all') {
        concatenatedSpec = rows.map((r) => r.specification ).join('; ');
        concatenatedMS = rows.map((r) => r.migrationScenario ).join('; ');
      } else if(this.state.combineValues === 'from_source') {
        /* do nothing */
      }
    } else {
      rows.forEach((r) => { 
        if(r.id === mode) { 
          row = r;
        }});
      if(values === 'upload_all') {
        concatenatedSpec = rows.map((r) => r.specification ).join('; ');
        concatenatedMS = rows.map((r) => r.migrationScenario ).join('; ');
      } else if(values === 'from_source') {
        concatenatedSpec = row.specification;
        concatenatedMS = row.migrationScenario;
      }
    }

    this.setState({...this.state,
      combineMode: mode,
      combineValues: values,
      entity: {...row, specification: concatenatedSpec, migrationScenario: concatenatedMS}
    })
  }

  getStepContent = (stepIndex) => {
    const {rows, dictionaries, users, currentUser} = this.props;

    switch (stepIndex) {
      case 0:
        return (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Combine mode:</FormLabel>
                  <RadioGroup aria-label="combineMode" value={this.state.combineMode} name="combineMode1" 
                      onChange={(event) => {
                        this.setEntity(event.target.value, this.state.combineValues);
                      }}>
                    <FormControlLabel value="new" control={<Radio />} label="New ID" />
                    {rows && rows.map((row) => (
                      <FormControlLabel value={row.id} control={<Radio />} label={row.identifier} />
                    ))}
                  </RadioGroup>
                </FormControl>        
                <Divider />
                </Grid>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Values:</FormLabel>
                  <RadioGroup aria-label="combineValuesMode" value={this.state.combineValues} name="combineValuesMode1" 
                      onChange={(event) => {
                        this.setEntity(this.state.combineMode, event.target.value);
                      }}>
                    <FormControlLabel value="upload_all"  control={<Radio />} label="Upload All" />
                    <FormControlLabel value="from_source" control={<Radio />} label="Upload from source" />
                    <FormControlLabel value="edit_new"    control={<Radio />} label="Edit new" />
                  </RadioGroup>
                </FormControl>        
              </Grid>
              </Grid>
        );
      case 1:
        return (
                <Grid container xs={12}>
                  <EditFormComponent 
                    ref={this.state.formRef}
                    row={this.state.entity} 
                    users={users} 
                    currentUser={currentUser}
                    dictionaries={dictionaries} />
                </Grid>
        );
      case 2:
        return (
                <Grid container xs={12}>
                  <EditFormComponent 
                    readOnly={true}
                    row={this.state.entity} 
                    users={users} 
                    currentUser={currentUser}
                    dictionaries={dictionaries} />
                </Grid>
        );
      default:
        return 'Unknown stepIndex';
    }
  }

  handleNext = () => {
    if(this.state.activeStep === 1) {
      this.setState({...this.state, activeStep: this.state.activeStep + 1, entity: this.state.formRef.current.state.newRow});
    } else {
      this.setState({...this.state, activeStep: this.state.activeStep + 1});
    }
  };
  
  handleBack = () => {
    this.setState({...this.stete, activeStep: this.state.activeStep - 1});
  };
  
  render() {
    const {combineModalOpen, handleCombineModalClose, classes} = this.props;
    const steps = getSteps();

    return (<Dialog
              fullWidth={true}
              maxWidth={'md'}
              open={combineModalOpen}
              onClose={handleCombineModalClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                  <Typography className={classes.instructions}>{this.getStepContent(this.state.activeStep)}</Typography>
                    <Grid container xs={12} style={{marginTop: 20}}>
                    <Grid item xs={12} style={{width: '100%', '& > * + *': { marginTop: 10, marginBottom: 10 }}}>
                      {this.state.errorMessage &&
                      <Alert severity="error">
                        <strong>Error: </strong> {this.state.errorMessage}
                      </Alert>      
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleCombineModalClose()}>Cancel</Button>        
                    </Grid>
                    <Grid item xs={6} align="right">
                    <Button
                      variant="contained"
                      disabled={this.state.activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => {
                      if(this.state.activeStep === steps.length - 1) {
                        this.handleCombine();
                      } else {
                        this.handleNext();
                      }
                    }}
                    >
                      {getButtonText(this.state.activeStep)}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>);
  }
}
const styles = theme => ({
    formControl: {
        width: '250px'
    },
    paper: {
        position: 'absolute',
        width: 900,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }}
);

const CombineModalWrapper = (props) => {
  const {rows} = props;

  return (
      <div>
        {rows && rows.length > 1 ? <CombineModal {...props} /> : null}
      </div>
  );
};

export default compose(
  withStyles(styles),
  withApollo
)(CombineModalWrapper);
    