import React from 'react';
import Layout from '../UI/Layout';
import TrendsChart from './Charts/TrendsChart'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, withStyles } from '@material-ui/core';
import { DEFAULT_GLOBALITY_FILTER, DEFAULT_PHASE_FILTER, DEFAULT_PRIORITY_FILTER, DEFAULT_RANGE_FILTER, DEFAULT_STATUS_FILTER } from '../../utils/consts';

const AnalysisDataPage = (props: any) => {
  const { history, classes } = props;

  const [phaseFilters,      setPhaseFilters] = React.useState(DEFAULT_PHASE_FILTER);
  const [statusFilters,     setStatusFilters] = React.useState(DEFAULT_STATUS_FILTER);
  const [globalityFilters,  setGlobalityFilters] = React.useState(DEFAULT_GLOBALITY_FILTER);
  const [rangeFilters,      setRangeFilters] = React.useState(DEFAULT_RANGE_FILTER);
  const [priorityFilters,   setPriorityFilters] = React.useState(DEFAULT_PRIORITY_FILTER);

  const handlePhaseChange = (event: any) => {
    if(event.target.checked) {
        setPhaseFilters(phaseFilters.concat(event.target.name));
    } else {
      setPhaseFilters(phaseFilters.filter((p) => p !== event.target.name));
    }
  };

  const handleStatusChange = (event: any) => {
    if(event.target.checked) {
      setStatusFilters(statusFilters.concat(event.target.name));
    } else {
      setStatusFilters(statusFilters.filter((p) => p !== event.target.name));
    }
  };

  const handleGlobalityChange = (event: any) => {
    if(event.target.checked) {
      setGlobalityFilters(globalityFilters.concat(event.target.name));
    } else {
      setGlobalityFilters(globalityFilters.filter((p) => p !== event.target.name));
    }
  };

  const handleRangeChange = (event: any) => {
    if(event.target.checked) {
      setRangeFilters(rangeFilters.concat(event.target.name));
    } else {
      setRangeFilters(rangeFilters.filter((p) => p !== event.target.name));
    }
  }  

  const handlePriorityChange = (event: any) => {
    if(event.target.checked) {
      setPriorityFilters(priorityFilters.concat(event.target.name));
    } else {
      setPriorityFilters(priorityFilters.filter((p) => p !== event.target.name));
    }
  }  

  return (
    <Layout history={history} leftManuType="report">
      <Grid container item spacing={2}>
        <Grid xs={12} style={{marginBottom: 30}}>
          <TrendsChart filters={{status: statusFilters, phase: phaseFilters, globality: globalityFilters, range: rangeFilters, priority: priorityFilters }} />
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{padding: 7}}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">PHASE</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" checked={phaseFilters.includes('phase_construction')} onChange={handlePhaseChange} name="phase_construction" />}
                label="Construction"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={phaseFilters.includes('phase_project')} onChange={handlePhaseChange} name="phase_project" />}
                label="Project"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={phaseFilters.includes('phase_operation')} onChange={handlePhaseChange} name="phase_operation" />}
                label="Operation"
              />
            </FormGroup>
          </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{padding: 7}}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">STATUS</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_new')} onChange={handleStatusChange} name="status_new" />}
                label="New"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_pending')} onChange={handleStatusChange} name="status_pending" />}
                label="Pending"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_closed')} onChange={handleStatusChange} name="status_closed" />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_link')} onChange={handleStatusChange} name="status_link" />}
                label="Link"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_relocated')} onChange={handleStatusChange} name="status_relocated" />}
                label="Relocated"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_task')} onChange={handleStatusChange} name="status_task" />}
                label="Task"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_preview')} onChange={handleStatusChange} name="status_preview" />}
                label="Preview"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_next_stage')} onChange={handleStatusChange} name="status_next_stage" />}
                label="Next Stage"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_incomplete')} onChange={handleStatusChange} name="status_incomplete" />}
                label="Incomplete"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={statusFilters.includes('status_test')} onChange={handleStatusChange} name="status_test" />}
                label="Test"
              />
            </FormGroup>
          </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{padding: 7}}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">GLOBALITY</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" checked={globalityFilters.includes('globality_glb')} onChange={handleGlobalityChange} name="globality_glb" />}
                label="GLB"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={globalityFilters.includes('globality_spc')} onChange={handleGlobalityChange} name="globality_spc" />}
                label="SPC"
              />
            </FormGroup>
          </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper elevation={1} style={{padding: 7}}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">RANGE</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" checked={rangeFilters.includes('range_etf')} onChange={handleRangeChange} name="range_etf" />}
                label="ETF"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={rangeFilters.includes('range_sub')} onChange={handleRangeChange} name="range_sub" />}
                label="SUB"
              />
            </FormGroup>
          </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={2}>
                  <Paper elevation={1} style={{padding: 7}}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">PRIORITY</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={priorityFilters.includes('priority_i')} onChange={handlePriorityChange} name="priority_i" />}
                        label="(I)"
                      />
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={priorityFilters.includes('priority_ii')} onChange={handlePriorityChange} name="priority_ii" />}
                        label="(II)"
                      />
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={priorityFilters.includes('priority_iii')} onChange={handlePriorityChange} name="priority_iii" />}
                        label="(III)"
                      />
                    </FormGroup>
                  </FormControl>
                  </Paper>
                </Grid>

      </Grid>
    </Layout>
  );
};

const styles = theme => ({
});

export default withStyles(styles)(AnalysisDataPage);