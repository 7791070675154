import gql from 'graphql-tag';

const RiskGroupsUpdateMutation = gql`
  mutation RiskGroupsUpdate($input: UpdateRiskGroupMutationInput!) {
    riskGroupsUpdate(input: $input) {
      riskGroups {
        id
        name
        extraData
        leader {
          id
          email
          name
        }
        parentGroup {
          id
          name
        }
        abbreviation
        users {
          id
          email
          name
        }
        groupChildrenIds
      }
      errors {
        name
        message
      }
    }
  }
`;

export default RiskGroupsUpdateMutation;
