import React from 'react';
import Layout from '../UI/Layout';
import PrioritiesChart from '../Analysis/Charts/PrioritiesChart';
import UserCard from '../Profile/UserCard';
import PriorityReportTable from '../Analysis/PriorityReportTable';
import EntireRegister from '../Analysis/Tables/EntireRegister';
import { DEFAULT_GLOBALITY_FILTER, DEFAULT_PHASE_FILTER, DEFAULT_RANGE_FILTER, DEFAULT_STATUS_FILTER, DEFAULT_STATUS_FILTER_NO_CLOSED, DEFAULT_PRIORITY_FILTER } from '../../utils/consts';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper } from '@material-ui/core';

const DashboardPage = (props: any) => {
  const { history } = props;
  const [closedStatus, setClosedStatus] = React.useState(true);

  const handleStatusChange = (event) => {
    setClosedStatus(event.target.checked);
  };

  return (
    <Layout history={history} leftManuType="dashboard">
      <Grid item container xs={12} lg={6} xl={4} alignItems="center" justify="center">
        <UserCard/>
      </Grid>
      <Grid item container xs={12} lg={6} xl={4} alignItems="center" justify="center">
          <PrioritiesChart 
            filters={{status: closedStatus ? DEFAULT_STATUS_FILTER : DEFAULT_STATUS_FILTER_NO_CLOSED, phase: DEFAULT_PHASE_FILTER, globality: DEFAULT_GLOBALITY_FILTER, range: DEFAULT_RANGE_FILTER, priority: DEFAULT_PRIORITY_FILTER }}/>
      </Grid>
      <Grid item container xs={12} lg={6} xl={4} alignItems="center" justify="center">
          <EntireRegister
            filters={{status: closedStatus ? DEFAULT_STATUS_FILTER : DEFAULT_STATUS_FILTER_NO_CLOSED, phase: DEFAULT_PHASE_FILTER, globality: DEFAULT_GLOBALITY_FILTER, range: DEFAULT_RANGE_FILTER, priority: DEFAULT_PRIORITY_FILTER  }}/>
      </Grid>
      <Grid item container xs={12} md={12} lg={6} alignItems="center" justify="center" style={{padding: 20}}>
          <PriorityReportTable
            filters={{status: closedStatus ? DEFAULT_STATUS_FILTER : DEFAULT_STATUS_FILTER_NO_CLOSED, phase: DEFAULT_PHASE_FILTER, globality: DEFAULT_GLOBALITY_FILTER, range: DEFAULT_RANGE_FILTER, priority: DEFAULT_PRIORITY_FILTER  }}/>
      </Grid>
      <Grid item xs={2} style={{padding: 20}}>
        <Paper elevation={1} style={{padding: 7}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">STATUS</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color="primary" checked={true} disabled name="status_new" />}
              label="New, Pending, Task"
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={closedStatus} onChange={handleStatusChange} name="status_closed" />}
              label="Closed"
            />
          </FormGroup>
        </FormControl>
        </Paper>
      </Grid>
    </Layout>
  );
};

export default DashboardPage;