import React, { PropsWithChildren } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { HistoryRow } from './types';
import mapConnection from '../../../utils/connectionMapper';
import remapEntity from '../../../utils/remapRiskTicketFields';

const GET_HISTORY_QUERY = gql`
  query GetRiskEntityHistory($filter: RiskEntityHistoryFilterInputObject!) {
    riskEntityHistory(filter: $filter) {
      edges {
        node {
          identifier
          specification
          migrationScenario
          title
          owner {
            name
          }
          createdBy {
            name
          }
          updatedBy {
            name
          }

          createdAt
          updatedAt

          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          source {
            label
          }
          priority {
            label
          }
          impact {
            label
          }
          globality {
            label
          }
          range {
            label
          }
          report {
            label
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost

          canWrite

          ready
        }
      }
    }
  }
`;

export interface IWithHistory {
  riskEntityId: string;
  rows: HistoryRow[];
  loading?: boolean;
}

function withQuery(WrappedComponent: React.FC<PropsWithChildren<IWithHistory>>) {
  return (props: PropsWithChildren<IWithHistory>) => {
    const { loading, error, data } = useQuery<any, {}>(
      GET_HISTORY_QUERY,
      { variables: {
        filter: {
          riskEntityId: props.riskEntityId
        }
      },
      fetchPolicy: "no-cache"
      }
    );

    if (error) return <p>ERROR</p>;

    const rows = data ? mapConnection(data.riskEntityHistory).map( (e: any) => remapEntity(e)) : [];

    return (
      <WrappedComponent {...props}
        rows={rows}
        loading={loading} />
    );
  };
}

export default withQuery;
