import gql from 'graphql-tag';

const UsersCreateMutation = gql`
  mutation UsersCreate($input: CreateUserMutationInput!) {
    usersCreate(input: $input) {
      user {
        id
        email
        firstName
        lastName
        name
        phone
        position
        abbreviation
        office
        leaderGroups
        riskGroup
        createdAt
        role
        status
      }
      errors {
        name
        message
      }
    }
  }
`;

export default UsersCreateMutation;
