import { SET_RISK_FILTERS } from '../actions/action-types';

export default (state = {
    identifier: '',
    title: '',
    specification: '',
    migrationScenario: '',
    status: [],
    owner: 'All',
    category: [],
    subcategory: [],
    strategy: [],
    phase: [],
    source: [],
    priority: [],
    impact: [],
    globality: [],
    range: [],
    report: [],
    probability: 'All',
    riskGroup: 'All',
    overtime: 'All',
    overcost: 'All',
    canWrite: '',
    ready: '',
    enabled: true
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_RISK_FILTERS:

      return {
        ...state,
        ...payload.riskFilters,
      };      

    default:
      return state;
  }
};
