import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

const styles = () => ({
  root: {
    position: 'absolute',
    zIndex: 1200,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffffba'
  }
})

const Loading = ({ classes, active, relative, className }) => (
  active ? <div style={{ top: relative ? 0 : '-80px' }} className={classNames(classes.root, className)}>
    <CircularProgress size={68} />
  </div> : null
)

export default withStyles(styles)(Loading);
