/*eslint-disable no-eval */

import React, { useEffect } from 'react';
import Layout from '../UI/Layout';
import { IWithReport } from './withQuery';
import { Grid, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { ReportStatRow } from './types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Spinner from '../UI/Spinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 200,
    },
    tableSlim: {
      minWidth: 180,
      width: '98%'
    },
    title: {
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    header: {
      textAlign: 'center',
      fontWeight: "bold"
    },
    diffHeader: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    priority_i: {
      backgroundColor: 'red',
      fontWeight: "bold",
      color: 'white',
    },
    priority_ii: {
      backgroundColor: 'yellow',
    },
    priority_iii: {
      backgroundColor: 'lime',
    },
    firstPaper: {
      width: '32%',
      margin: 2,
    },
    secondPaper: {
      width: '25%'
    },
    thirdPaper: {
      width: '25%',
    },
    fourthPaper: {
      width: '17%',
      margin: 2,
    },
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      textAlign: 'center',
      margin: '2px',
      padding: '2px',
      borderRight: '1px solid rgb(238, 238, 238)'
    },
    body: {
      textAlign: 'center',
      margin: '2px',
      padding: '2px',
      borderRight: '1px solid rgb(238, 238, 238)'
    },
  }),
)(TableCell);

interface IReports {
  rows: ReportStatRow[];
  reportName?: String;
  reportStatus?: String | null;
  diff?: Boolean;
  oprItems?: String;
  conItems?: String;
  projItems?: String;
}

function StatsReportsTables(props: IReports) {
  const classes = useStyles();

  return (
      <Grid container spacing={1} xs={12}>
          <Grid item container xs={12} alignItems="center" justify="center">
          <Paper className={classes.firstPaper} style={props.reportStatus == null ? { border: '3px solid gray'} : {} }>
            {props.reportStatus == null ? 
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={6} style={{fontWeight: 'bold', fontSize: 12}}>
                      ENTIRE REGISTER
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell style={{width: 30, borderRight: '1px dashed gray', whiteSpace: 'nowrap'}}>(-TSK)</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>PND</StyledTableCell>
                    <StyledTableCell>NEW</StyledTableCell>
                    <StyledTableCell>CLS</StyledTableCell>
                    <StyledTableCell>TSK</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows.filter((r) => r.status == null).map((row) => (
                    <TableRow>
                      <StyledTableCell style={{borderRight: '1px dashed gray'}}>{row.leftTotalSum}</StyledTableCell>
                      <StyledTableCell style={row.type === 'summary' ? {fontWeight: 'bold'} : {}}>{row.leftSum}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftPndCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftNewCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftClsCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftTskCount}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> : '' }
          </Paper>
          <Grid item container style={{ width: '50%', border: '3px solid gray' }}>
          <Paper style={{width: '33%', borderRight: '1px dashed gray'}}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      {props.reportStatus == null 
                        ? <div>
                              <span style={{fontSize: 12, fontWeight: 'bold', marginRight: 5}}>CON (PND+NEW)</span>
                              <span style={{fontSize: 12, fontWeight: 'bold'}}>{props.conItems ? `[${props.conItems}]` : ''}</span>
                          </div>
                        : <span style={{fontWeight: 'bold'}}>{props.reportName}</span> 
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>SPC/SUB</StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>SPC/ETF</StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>GLB</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows.filter((r) => r.status === props.reportStatus).map((row) => (
                    <TableRow>
                      <StyledTableCell style={row.type === 'summary' && !props.diff ? {fontWeight: 'bold', backgroundColor: '#DCDCDC', outlineColor: 'red', outlineStyle: 'solid', outlineWidth: 1, outlineOffset: -2} : {}}>
                        {row.centerConSum}
                      </StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerConSubCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerConEtfCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)} style={{borderRight: '0'}}>{row.centerConGlbCount}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
              </Paper>              
          <Paper style={{width: '33%', borderRight: '1px dashed gray'}}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                  <TableCell align="center" colSpan={9}>
                    {props.reportStatus == null 
                      ? <div>
                          <span style={{fontSize: 12, fontWeight: 'bold', marginRight: 10}}>OPR (PND+NEW)</span>
                          <span style={{fontSize: 12, fontWeight: 'bold'}}>{props.oprItems ? `[${props.oprItems}]` : ''}</span>
                        </div>
                      : <span style={{fontWeight: 'bold'}}>{props.reportName}</span> 
                    }
                  </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>                    
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>SPC/SUB</StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>SPC/ETF</StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>GLB</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows.filter((r) => r.status === props.reportStatus).map((row) => (
                    <TableRow>
                      <StyledTableCell style={row.type === 'summary' && !props.diff ? {fontWeight: 'bold', backgroundColor: '#DCDCDC', outlineColor: 'red', outlineStyle: 'solid', outlineWidth: 1, outlineOffset: -2} : {}}>
                        {row.centerOprSum}
                      </StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerOprSubCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerOprEtfCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerOprGlbCount}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>          
          </Paper>
          <Paper style={{width: '33%'}}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                  <TableCell align="center" colSpan={9}>
                    {props.reportStatus == null           
                      ? <div>
                          <span style={{fontSize: 12, fontWeight: 'bold', marginRight: 10}}>PRJ (PND+NEW)</span>
                          <span style={{fontSize: 12, fontWeight: 'bold'}}>{props.projItems ? `[${props.projItems}]` : ''}</span>
                        </div>                      
                      : <span style={{fontWeight: 'bold'}}>{props.reportName}</span>
                    }
                  </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>                    
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>SPC/SUB</StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>SPC/ETF</StyledTableCell>
                    <StyledTableCell style={{fontSize: 11, width: 50 }}>GLB</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows.filter((r) => r.status === props.reportStatus).map((row) => (
                    <TableRow>
                      <StyledTableCell style={row.type === 'summary' && !props.diff ? {fontWeight: 'bold', backgroundColor: '#DCDCDC', outlineColor: 'red', outlineStyle: 'solid', outlineWidth: 1, outlineOffset: -2} : {}}>
                        {row.centerProjSum}
                      </StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerProjSubCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerProjEtfCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.centerProjGlbCount}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>          
          </Paper>
          </Grid>
          <Paper className={classes.fourthPaper} style={props.reportStatus == null ? { border: '3px solid gray'} : {} }>
            {props.reportStatus == null ? 
            <TableContainer component={Paper}>
              <Table className={classes.tableSlim} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{width: 60, fontWeight: 'bold', fontSize: 12}}>PND+NEW</StyledTableCell>
                    <StyledTableCell style={{width: 60, fontSize: 12 }}>CLS</StyledTableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows.filter((r) => r.status == null ).map((row) => (
                    <TableRow>
                      <StyledTableCell className={eval(`classes.${row.type}`)} style={row.type === 'summary' && !props.diff ? {fontWeight: 'bold', backgroundColor: '#DCDCDC', outlineColor: 'red', outlineStyle: 'solid', outlineWidth: 1, outlineOffset: -2} : {}}>{row.rightPndNewCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.rightClsCount}</StyledTableCell>
                      <StyledTableCell style={row.type === 'summary' ? {fontWeight: 'bold'} : {}}>{(row.rightClsCount || 0) + (row.rightPndNewCount || 0)}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> : ''}        
          </Paper>
          </Grid>
      </Grid>);
}

const RiskReportTable = (props: IWithReport) => {
  const { history } = props;
  const classes = useStyles();
  const [firstReportName, setFirstReportName] = React.useState(props.firstReportName);
  const [secondReportName, setSecondReportName] = React.useState(props.secondReportName);
  // const [secondReports, setSecondReports] = React.useState(props.reports);

  const handleFirstChange = (event: any) => {
    setFirstReportName(event.target.value);
  }

  const handleSecondChange = (event: any) => {
    setSecondReportName(event.target.value);
  }

  useEffect(() => {
    if ( firstReportName && firstReportName.length > 0 && secondReportName && secondReportName.length > 0) {
      props.refetch(
        {
          first: firstReportName,
          second: secondReportName,
          dictionaryFilter: { "type": "Dictionaries::Report"}
        }
      );
    }
  }, [firstReportName, secondReportName, props]); 

  return (
    <Layout history={history} leftManuType="report">
      {props.loading ? <Spinner /> : (
        <Grid container spacing={2} alignItems="center" justify="center" style={{marginBottom: 20}}>
          <Grid item xs={12}>
            <Typography className={classes.header} component="div">
              REPORTS COMPARISON
            </Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid container item xs={4} alignItems="center" alignContent="center" justify="center">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">First report</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={firstReportName}
                onChange={handleFirstChange}
                label="First Report"
              >
                <MenuItem value={""}>None</MenuItem>
                {props.reports.map((report) => (<MenuItem value={report.label}>{report.label}</MenuItem>))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Second report</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={secondReportName}
                onChange={handleSecondChange}
                label="Second Report"
              >
                <MenuItem value={""}>None</MenuItem>
                {props.reports.map((report) => (<MenuItem value={report.label}>{report.label}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      )}
        {!props.loading && props.first.length > 0 && props.second.length && props.diff.length &&
          <Grid container xs={12}>

            <Grid container xs={12} style={{border: '5px solid blue'}}>
            <Grid item xs={1}>
              <Typography variant="h5">{firstReportName}</Typography>
            </Grid>
            <Grid container item xs={10}>
              <StatsReportsTables rows={props.first} reportName={firstReportName} reportStatus={null} 
                oprItems={`${(Math.round(1000*(props.firstOperationItems || 0)) / 10).toFixed(1)}%`}
                conItems={`${(Math.round(1000*(props.firstConstructionItems || 0)) / 10).toFixed(1)}%`}
                projItems={`${(Math.round(1000*(props.firstProjectItems || 0)) / 10).toFixed(1)}%`}
              />
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={1}></Grid>
            <Grid item container xs={10}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0} alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.first} reportName={"Status: NEW"} reportStatus={"status_new"}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.first} reportName={"Status: PND"} reportStatus={"status_pending"}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.first} reportName={"Status: TSK"} reportStatus={"status_task"}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.first} reportName={"Status: CLS"} reportStatus={"status_closed"}/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={1}></Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.diffHeader} component="div">
                &nbsp;
              </Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography className={classes.diffHeader} variant="h5" component="div">
                DIFFERENCE
              </Typography>
            </Grid>
            <Grid container item xs={10}>
            <StatsReportsTables rows={props.diff} reportStatus={null} diff={true}/>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={12}>
              <Typography className={classes.diffHeader} component="div">
                &nbsp;
              </Typography>
            </Grid>

            <Grid container xs={12} style={{border: '5px solid orange'}}>
            <Grid item xs={1}>
              <Typography variant="h5">{secondReportName}</Typography>
            </Grid>
            <Grid container item xs={10}>
              <StatsReportsTables rows={props.second} reportName={secondReportName} reportStatus={null} 
                oprItems={`${(Math.round(1000*(props.secondOperationItems || 0)) / 10).toFixed(1)}%`} 
                conItems={`${(Math.round(1000*(props.secondConstructionItems || 0)) / 10).toFixed(1)}%`}
                projItems={`${(Math.round(1000*(props.secondProjectItems || 0)) / 10).toFixed(1)}%`}
              />
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={1}></Grid>
            <Grid item container xs={10}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0} alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.second} reportName={"Status: NEW"} reportStatus={"status_new"}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.second} reportName={"Status: PND"} reportStatus={"status_pending"}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.second} reportName={"Status: TSK"} reportStatus={"status_task"}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatsReportsTables rows={props.second} reportName={"Status: CLS"} reportStatus={"status_closed"}/>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={1}></Grid>
            </Grid>
          </Grid>}
    </Layout>
  );
};

export default RiskReportTable;