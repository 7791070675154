import React, { Component } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import store from './store/store';
import Login from './components/Login';
import Recovery from './components/Recovery';
import ResetPassword from './components/ResetPassword';
import Logout from './components/Logout';
import RiskEntitiesPage from './components/RiskEntities/RiskEntitiesPage';
import DashboardPage from './components/Dashboard/DashboardPage';
import DictionariesPage from './components/Dictionaries/DictionariesPage';
import RiskGroupsPage from './components/RiskGroups/RiskGroupsPage';
import UsersPage from './components/Users/UsersPage';
import ProfilePage from './components/Profile/ProfilePage';
import MessagesPage from './components/Messages/MessagesPage';
import AnalysisDataPage from './components/Analysis/AnalysisDataPage';
import RiskReportPage from './components/RiskReport/RiskReportPage';
import StatisticsPage from './components/Statistics/StatisticssPage';
import SummaryTablePage from './components/SummaryTable/SummaryTablePage';
import ManualPage from './components/Manual/ManualPage';
import NewReportConfigPage from './components/Config/NewReportConfigPage';
import PriorityConfigPage from './components/Config/PriorityConfigPage';

const renderMergedProps = (component: any, ...rest: any[]) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const authorized = store.getState().token.authToken;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authorized) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }
        return renderMergedProps(component, props);
      }}
    />
  );
};

const NotFound = () => <Redirect to={{ pathname: '/' }} />;

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/recovery" component={Recovery} />
          <Route exact path="/reset_password/:email/:reset_token" component={ResetPassword} />
          <Switch>
            <PrivateRoute exact path="/dashboard"   component={DashboardPage} />
            <PrivateRoute exact path="/entities"    component={RiskEntitiesPage} />

            <PrivateRoute exact path="/dictionary"  component={DictionariesPage} />
            <PrivateRoute exact path="/groups"      component={RiskGroupsPage} />
            <PrivateRoute exact path="/users"       component={UsersPage} />
            <PrivateRoute exact path="/conf-new_report"  component={NewReportConfigPage} />
            <PrivateRoute exact path="/conf-priority"    component={PriorityConfigPage} />

            <PrivateRoute exact path="/profile"     component={ProfilePage} />
            <PrivateRoute exact path="/messages"    component={MessagesPage} />
            <PrivateRoute exact path="/analysis"    component={AnalysisDataPage} />
            <PrivateRoute exact path="/reports"     component={RiskReportPage} />
            <PrivateRoute exact path="/statistics"  component={StatisticsPage} />
            <PrivateRoute exact path="/summary"     component={SummaryTablePage} />
            <PrivateRoute exact path="/manual"      component={ManualPage} />
            <PrivateRoute exact path="/logout"      component={Logout} />
            <Route component={NotFound} />
          </Switch>
        </Switch>
      </div>
    );
  }
}

export default App;
