import { SAVE_TOKEN, CLEAR_TOKEN } from './action-types';

export const saveToken = (token) => ({
  type: SAVE_TOKEN,
  payload: {
    token,
  },
});

export const clearToken = () => ({
  type: CLEAR_TOKEN,
});
