import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import mapConnection from '../../utils/connectionMapper';
import remapEntity from '../../utils/remapRiskTicketFields';
import { Link } from '@material-ui/core';
import store from '../../store/store';


const RISK_ENTITIES_QUERY = gql`
  query entities {
    dictionaries( orderBy: [["label", "ASC"]]) {
      edges {
        node {
          type
          typeName
          label
          id
          active
          contextName
          level
        }
      }
    }
    users( orderBy: [["first_name", "ASC"], ["last_name", "ASC"]]) {
      edges {
        node {
          id
          name
          myGroups {
            name
          }
        }
      }
    }
    riskEntities {
      edges {
        node {
          id
          identifier
          specification
          migrationScenario
          title
          owner {
            name
          }
          createdBy {
            name
          }
          updatedBy {
            name
          }

          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          source {
            label
          }
          priority {
            label
          }
          impact {
            label
          }
          globality {
            label
          }
          range {
            label
          }
          report {
            label
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost

          canWrite
          ready
          createdAt
          updatedAt
          closedAt
        }
      }
    }
  }
`;

const withQuery = WrappedComponent => ({ ...rest }) => {
  return (
    <Query query={RISK_ENTITIES_QUERY}>
      {({ data, loading, error }) => {
        if (error) return <div>
          <p>[{error.toString()}]</p>
            <Link href="entities">Try again</Link>
          </div>

        let entities = [];
        let dictionaries = [];
        let users = [];

        if (data) {
          users = mapConnection(data.users);
          entities = mapConnection(data.riskEntities);
          entities = entities.map( e => remapEntity(e));
          dictionaries = mapConnection(data.dictionaries);
        }

        return (
          <WrappedComponent
            entities={entities} 
            dictionaries={dictionaries} 
            users={users}
            currentUser={store.getState().currentUser.currentUser}
            loading={loading}
            {...rest}
          />
        );
      }}
    </Query>
  );
};

export default withQuery;

