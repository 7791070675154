import React, {Component} from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../UI/Spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { Grid, withStyles } from '@material-ui/core';
import { DEFAULT_GLOBALITY_FILTER, DEFAULT_RANGE_FILTER, DEFAULT_PHASE_FILTER, DEFAULT_STATUS_FILTER, DEFAULT_PRIORITY_FILTER } from '../../../utils/consts';
import { Theme, createStyles } from '@material-ui/core/styles';


const STATS_QUERY = gql`
  query statistics($reportName: String, $filter: [RiskStatsFilterInputObject!]) {
    entireRegisterTable(reportName: $reportName, filter: $filter) { 
      type
      status
      leftPndCount
      leftNewCount
      leftClsCount
      leftTskCount
      leftSum
      leftTotalSum
    }
    currentReport {
      label
    }
  }
`;

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      textAlign: 'center',
      margin: '2px',
      padding: '2px',
      height: 55,
      borderRight: '1px solid rgb(238, 238, 238)'
    },
    body: {
      textAlign: 'center',
      margin: '2px',
      padding: '2px',
      borderRight: '1px solid rgb(238, 238, 238)',
      height: 55
    },
  }),
)(TableCell);


class EntireRegisterStats extends Component {
  render() {
    const { classes, rows, reportName } = this.props;

    return (
          <Grid item container xs={12} alignItems="center" justify="center">
          <Paper className={classes.paperTable}>
            <TableContainer component={Paper} align="center">
              <Table style={{width: "99%"}} aria-label="simple table" size="medium">
                <TableHead>
                  <TableRow style={{height: 90}}>
                    <TableCell align="center" colSpan={9} style={{fontWeight: 'bold', fontSize: 18}}>
                      ENTIRE REGISTER
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell  style={{width: 30, borderRight: '1px dashed gray', whiteSpace: 'nowrap'}}>(-TSK)</StyledTableCell>
                    <StyledTableCell ></StyledTableCell>
                    <StyledTableCell >PND</StyledTableCell>
                    <StyledTableCell >NEW</StyledTableCell>
                    <StyledTableCell >CLS</StyledTableCell>
                    <StyledTableCell >TSK</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.filter((r) => r.status == null).map((row) => (
                    <TableRow key={row.type}>
                      <StyledTableCell style={{borderRight: '1px dashed gray'}}>{row.leftTotalSum}</StyledTableCell>
                      <StyledTableCell style={row.type === 'summary' ? {fontWeight: 'bold'} : {}}>{row.leftSum}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftPndCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftNewCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftClsCount}</StyledTableCell>
                      <StyledTableCell className={eval(`classes.${row.type}`)}>{row.leftTskCount}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          </Grid>
    );
  }
}

const withQuery = (WrappedComponent) => (props) => (
  <Query query={STATS_QUERY} variables={{
    reportName: props.reportName,
    filter: [
      {
        name: 'phase',
        includeValues: props.filters ? props.filters.phase : DEFAULT_PHASE_FILTER
      },
      {
        name: 'status',
        includeValues: props.filters ? props.filters.status : DEFAULT_STATUS_FILTER
      },
      {
        name: 'globality',
        includeValues: props.filters ? props.filters.globality : DEFAULT_GLOBALITY_FILTER
      },
      {
        name: 'range',
        includeValues: props.filters ? props.filters.range : DEFAULT_RANGE_FILTER
      },
      {
        name: 'priority',
        includeValues: props.filters ? props.filters.priority : DEFAULT_PRIORITY_FILTER
      },
    ]
    }}>
    {({ data, loading, error }) => {
      const stats = data ? data.entireRegisterTable : [];
      const reportName = data ? data.currentReport.label : [];
      return <WrappedComponent {...props} stats={stats} loading={loading} reportName={props.reportName || reportName} />;
    }}
  </Query>
);

const EntireRegisterTable = (props) => {
  const { loading, stats, classes } = props;
  return (
    <Card style={{minWidth: 550, height: 400}}>
      <CardContent>
      {loading ? <Spinner /> : stats && <EntireRegisterStats rows={stats} classes={classes}  reportName={props.reportName}/>}
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
    priority_i: {
      backgroundColor: 'red',
      fontWeight: "bold",
      color: 'white',
    },
    priority_ii: {
      backgroundColor: 'yellow',
    },
    priority_iii: {
      backgroundColor: 'lime',
    },
    paperTable: {
      width: '100%',
    },
});

export default withStyles(styles)(withQuery(EntireRegisterTable));