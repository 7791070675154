import gql from 'graphql-tag';

const SetReadMessageMutation = gql`
  mutation SetReadMessage($input: SetReadMessageMutationInput!) {
    messagesSetRead(input: $input) {
      message {
        id
        title
        content
        createdAt
        hasBeenRead
        issuer {
          id
          name
        }
        riskEntity {
          id
          identifier
        }
      }
      errors {
        name
        message
      }
    }
  }
`;

export default SetReadMessageMutation;
