import { SET_CURRENT_USER } from '../actions/action-types';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload.user,
      };

    default:
      return state;
  }
};
