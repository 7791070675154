import { Link, Paper, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
             

const Introduction = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography id="introduction" variant="h5" paragraph>
        Introduction
      </Typography>
      <Typography paragraph>
	    <b>Risk management</b> consists of the formal processes whereby risk factors are systematically identified, assessed and analyzed, risk response plans defined, deployed and monitored. Risk management can be seen as preparation for possible events in advance.
      </Typography>
      <Typography paragraph>
	    Respecting their legal due diligence pursuant to § 91 (2) of the German Stock Corporation Act (AktG), the European XFEL Management Board has decided to implement a risk management system (RMS) including an early risk warning system (ERWS) that was aimed at the early detection of a developments potentially influencing the company’s existence. The European <b>XFEL</b> has started development of its custom <b>Risk Management System</b> in January 2013.
      </Typography>
      <Typography paragraph>
      The XFEL Risk Management system has been developed basing on the following standards and methodologies:
      </Typography>
      <Typography paragraph>
      1. <b>PMI</b> (Project Management Institute)
      </Typography>
      <Typography paragraph>
      2. <b>ISO</b> 31000:2009 (The International Organization for Standardization)
      </Typography>
      <Typography paragraph>
      3.	<b>COSO</b> (The Committee of Sponsoring Organizations of the Treadway Commission)
      </Typography>
      <Typography paragraph>
      . 
      </Typography>
      <Typography paragraph>
      . 
      </Typography>
      <Typography paragraph>
        Section - under construction…
      </Typography>
      <Typography paragraph>
      . 
      </Typography>
      <Typography paragraph>
      . 
      </Typography>
      <Typography paragraph>
      In case of questions, issues, comments, please use: <Link component="a" href="mailto: rms-support@rm-project.eu">rms-support@rm-project.eu</Link>
      </Typography>
    </Paper>
  )
};
             
export default Introduction;             
