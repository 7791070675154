import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import * as compose from 'lodash.flowright';
import {
  Typography, withStyles, Grid,
} from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class LeftReportMenu extends Component {
  handleCallToRouter = (event, newValue) => {
    this.props.history.push(newValue);
  }  

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          value={this.props.history.location.pathname}
          onChange={this.handleCallToRouter}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Summary"       value="/summary" />
          <Tab label="Comparison"    value="/reports" />
          <Tab label="Data Analysis" value="/analysis" />
        </Tabs>
        <Grid item container xs={12} className={classes.grid}>
          <Grid item xs={12}>
            {this.props.children}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: window.innerWidth
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: 20,
  },
  grid: {
    width: '100%'
  }
});

export default compose(
  withStyles(styles),
)(LeftReportMenu);