import React, { PropsWithChildren } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import mapConnection from '../../utils/connectionMapper';
import { IMessage } from './types';

const MESSAGES_QUERY = gql`
  query messages {
    messages {
      edges {
        node {
          id
          title
          content
          createdAt
          hasBeenRead
          issuer {
            id
            name
          }
          riskEntity {
            id
            identifier
          }
        }
      }
    }
  }
`;


export interface IWithMessages {
  rows: IMessage[];
  loading?: boolean;
  history?: any;
  client: any
}

function withQuery(WrappedComponent: React.FC<PropsWithChildren<IWithMessages>>) {
  return (props: PropsWithChildren<IWithMessages>) => {
    const { loading, error, data } = useQuery<any, {}>(
      MESSAGES_QUERY,
      { 
        variables: {},
        fetchPolicy: "no-cache"
      }
    );

    if (error) return <p>ERROR</p>;

    const rows = data ? mapConnection(data.messages) : [];

    return (
      <WrappedComponent {...props}
        rows={rows}
        loading={loading} />
    );
  };
};

export default withQuery;
