import React from 'react';
import Layout from '../UI/Layout';


const StatisticsPage = (props: any) => {
  const { history } = props;
  return (
    <Layout title="Statistics" history={history}>
      <div>Statistics</div>
    </Layout>
  );
};

export default StatisticsPage;