import { RiskEntityRow } from "../types";
import FilterRenderer from "./filterRenderer";
import Select from '@material-ui/core/Select';
import { SelectColumn } from 'react-data-grid';
import { OvercostFormatter, OvertimeFormatter, PriorityFormatter, ProbabilityFormatter, LongTextFormatter } from '../formatters';
import { Checkbox, Grid, ListItemText, MenuItem, TextField } from "@material-ui/core";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";

const headerCellClass = 'filter-cell';

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function DatetimeFormatter({ datetime }: { datetime: Date }) {
  return <>{moment(datetime).format('yyyy-MM-DD / HH:mm')}</>;
}

export default function(isAdmin: boolean, classes: any, dictionaries: any, setFilters: any, users: any) {
    const categoriesDict    = dictionaries.filter((d: any) => d.type === 'Dictionaries::Category').map((d: any) => { return { value: d.label, label: d.label } });
    const subCategoriesDict = dictionaries.filter((d: any) => d.type === 'Dictionaries::Subcategory').map((d: any) => { return { value: d.label, label: d.label } });
    const overcostsDict     = dictionaries.filter((d: any) => d.type === 'Dictionaries::Overcost' && d.active);
    const overtimesDict     = dictionaries.filter((d: any) => d.type === 'Dictionaries::Overtime' && d.active);
    const impactsDict       = dictionaries.filter((d: any) => d.type === 'Dictionaries::Impact').map((d: any) => { return { value: d.label, label: d.label } });
    const probabilitiesDict = dictionaries.filter((d: any) => d.type === 'Dictionaries::Probability');
    const prioritiesDict    = dictionaries.filter((d: any) => d.type === 'Dictionaries::Priority').map((d: any) => { return { value: d.label, label: d.label } });
    const strategiesDict    = dictionaries.filter((d: any) => d.type === 'Dictionaries::Strategy').map((d: any) => { return { value: d.label, label: d.label } });
    const statusesDict      = dictionaries.filter((d: any) => d.type === 'Dictionaries::Status').map((d: any) => { return { value: d.label, label: d.label } });
    const phasesDict        = dictionaries.filter((d: any) => d.type === 'Dictionaries::Phase').map((d: any) => { return { value: d.label, label: d.label } });
    const rangesDict        = dictionaries.filter((d: any) => d.type === 'Dictionaries::Range').map((d: any) => { return { value: d.label, label: d.label } });
    const globalitiesDict   = dictionaries.filter((d: any) => d.type === 'Dictionaries::Globality').map((d: any) => { return { value: d.label, label: d.label } });
    const sourcesDict       = dictionaries.filter((d: any) => d.type === 'Dictionaries::Source').map((d: any) => { return { value: d.label, label: d.label } });

    const cols = [
        SelectColumn,
        { 
          name: "ID",            
          key: "identifier",        
          headerCellClass,
          width: 200,
          frozen: true,
          resizable: true,
          sortable: true,
          headerRenderer: (p: any) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <TextField
                  {...rest}
                  value={filters.identifier}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      identifier: e.target.value
                    })
                  }
                  variant="standard"
                  size="small"
                  fullWidth={true}
                />
              )}
            </FilterRenderer>)
        },
        { 
          name: "Specification", 
          key: "specification",
          headerCellClass,
          width: 400, 
          frozen: true,
          resizable: true,
          sortable: true,
          formatter: ({ row }) => (<LongTextFormatter value={row.specification} />),
          headerRenderer: (p: any) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <TextField
                  {...rest}
                  value={filters.specification}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      specification: e.target.value
                    })
                  }
                  variant="standard"
                  size="small"
                  fullWidth={true}
                  />
              )}
            </FilterRenderer>)
        },
        { 
          name: "Title",         
          key: "title",             
          headerCellClass,
          width: 200,
          resizable: true,
          sortable: true,
          formatter: ({ row }) => (<LongTextFormatter value={row.title} />),
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <TextField
                  {...rest}
                  value={filters.title}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      title: e.target.value
                    })
                  }
                  variant="standard"
                  size="small"
                  fullWidth={true}
                />
              )}
            </FilterRenderer>)
        },
        { 
          name: "Priority",      
          key: "priority",          
          headerCellClass,
          width: 160,
          formatter: ({ row }) => (<PriorityFormatter value={row.priority} />),
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.priority}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      priority: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {prioritiesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.priority?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Status",        
          key: "status",            
          headerCellClass,
          width: 280,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.status}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      status: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {statusesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.status?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Risk Owner",         
          key: "owner",             
          headerCellClass,
          width: 240,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  fullWidth={true}
                  {...rest}
                  value={filters.owner}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      owner: e.target.value,
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  {users.map((obj: object) => (
                    <MenuItem value={obj.name}>{obj.name}</MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Phase",         
          key: "phase",             
          headerCellClass,
          width: 220,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.phase}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      phase: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {phasesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.phase?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Category",      
          key: "category",          
          headerCellClass,
          width: 230,
          resizable: true,
          sortable: true,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.category}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      category: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {categoriesDict.map((obj: object) => (
                    <MenuItem key={`category_${obj.value}`} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.category?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Subcategory",   
          key: "subcategory",       
          headerCellClass,
          width: 250,
          resizable: true,
          sortable: true,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.subcategory}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      subcategory: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {subCategoriesDict.map((obj: object) => (
                    <MenuItem key={`subcategory_${obj.value}`} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.subcategory?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Cost",
          key: "overcost",          
          headerCellClass,
          width: 140,
          formatter: ({ row }) => (<OvercostFormatter value={row.overcost} />),
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  fullWidth={true}
                  {...rest}
                  value={filters.overcost}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      overcost: e.target.value,
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem key={'overcost_all'} value={'All'}>All</MenuItem>
                  {overcostsDict.map((obj: object) => (
                    <MenuItem key={`overcost_${obj.label}`} value={obj.label}>{obj.label}</MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Time",          
          key: "overtime",          
          headerCellClass,
          width: 140,
          formatter: ({ row }) => (<OvertimeFormatter value={row.overtime} row={row} />),
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  fullWidth={true}
                  {...rest}
                  value={filters.overtime}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      overtime: e.target.value,
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem key={`overtime_all`} value={'All'}>All</MenuItem>
                  {overtimesDict.map((obj: object) => (
                    <MenuItem key={`overtime_${obj.label}`} value={obj.label}>{obj.label}</MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Impact",        
          key: "impact",            
          headerCellClass,
          width: 220, 
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.impact}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      impact: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {impactsDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.impact?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Probability",   
          key: "probability",       
          headerCellClass,
          width: 140,
          formatter: ({ row }) => (<ProbabilityFormatter value={row.probability} />),
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  fullWidth={true}
                  {...rest}
                  value={filters.probability}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      probability: e.target.value,
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem key={`probability_all`} value={'All'}>All</MenuItem>
                  {probabilitiesDict.map((obj: object) => (
                    <MenuItem key={`probability_${obj.label}`} value={obj.label}>{obj.label}</MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Response plan", 
          key: "migrationScenario", 
          headerCellClass,
          width: 260,
          formatter: ({ row }) => (<LongTextFormatter value={row.migrationScenario} />),
          headerRenderer: (p: any) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <TextField
                  {...rest}
                  value={filters.migrationScenario}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      migrationScenario: e.target.value
                    })
                  }
                  variant="standard"
                  size="small"
                  fullWidth={true}
                  />
              )}
            </FilterRenderer>)
        },
        { 
          name: "Strategy",      
          key: "strategy",          
          headerCellClass,
          width: 220,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.strategy}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      strategy: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {strategiesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.strategy?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Globality",     
          key: "globality",         
          headerCellClass,
          width: 200,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.globality}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      globality: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {globalitiesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.globality?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Range",         
          key: "range",             
          headerCellClass,
          width: 200,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.range}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      range: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {rangesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.range?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Created At",         
          key: "createdAt",
          headerCellClass,
          width: 310,
          formatter(props: any) {
            return <DatetimeFormatter datetime={props.row.createdAt} />;
          },          
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      style={{width: 140}}
                      format="yyyy-MM-dd"
                      margin="none"
                      id="created_at-date-picker-from"
                      value={filters.createdAt[0]}
                      onChange={(date) => {
                        setFilters({
                          ...filters,
                          createdAt: [date, filters.createdAt[1]],
                        });
                      }}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      style={{width: 140}}
                      format="yyyy-MM-dd"
                      margin="none"
                      id="created_at-date-picker-to"
                      value={filters.createdAt[1]}
                      onChange={(date) => {
                        setFilters({
                          ...filters,
                          createdAt: [filters.createdAt[0], date],
                        });
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Last Modified At",         
          key: "updatedAt",             
          headerCellClass,
          width: 310,
          formatter(props: any) {
            return <DatetimeFormatter datetime={props.row.updatedAt} />;
          },          
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      style={{width: 140}}
                      format="yyyy-MM-dd"
                      margin="none"
                      id="updated_at-date-picker-from"
                      value={filters.updatedAt[0]}
                      onChange={(date) => {
                        setFilters({
                          ...filters,
                          updatedAt: [date, filters.updatedAt[1]],
                        });
                      }}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      style={{width: 140}}
                      format="yyyy-MM-dd"
                      margin="none"
                      id="updated_at-date-picker-to"
                      value={filters.updatedAt[1]}
                      onChange={(date) => {
                        setFilters({
                          ...filters,
                          updatedAt: [filters.updatedAt[0], date],
                        });
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              )}
            </FilterRenderer>)
        },
        { 
          name: "Iteration",        
          key: "report",            
          width: 140, 
        }
    ];

    if(isAdmin) {
      cols.push(
        { 
          name: "Source",        
          key: "source",            
          width: 200,
          headerRenderer: (p) => (
            <FilterRenderer<RiskEntityRow, unknown, HTMLInputElement> {...p}>
              {({filters, ...rest}) => (
                <Select
                  multiple
                  fullWidth={true}
                  {...rest}
                  value={filters.source}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      source: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => selected.join(', ') }
                  MenuProps={MenuProps}
                >
                  {sourcesDict.map((obj: object) => (
                    <MenuItem key={obj.value} value={obj.value} style={{zIndex: 999}}>
                      <Checkbox checked={ filters.source?.indexOf(obj.value) > -1 } />
                      <ListItemText primary={obj.value} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FilterRenderer>)
        }
      )
    }

  return cols;
};
  