import React from 'react';
import { LinearProgress } from '@material-ui/core/';


const Spinner = ({ relative = true}) =>
  relative ? (
    <div
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        alignContent: 'center',
        width: '100%',
        zIndex: 1000,
      }}
    >
      {'Loading ...'}
      <LinearProgress />{' '}
    </div>
  ) : (
    <LinearProgress />
  );

export default Spinner;
