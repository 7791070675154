import { combineReducers } from 'redux';

import token from './token-reducer';
import message from './message-reducer';
import error from './error-reducer';
import sidebar from './sidebar-reducer';
import misc from './miscReducer';
import currentUser from './users-reducer';
import dictionary from './dictionary-reducer';
import riskFilters from './riskFilters-reducer';

export default combineReducers({
  token,
  message,
  error,
  sidebar,
  misc,
  currentUser,
  dictionary,
  riskFilters,
});
