import React, {Component} from 'react';
import {RadialChart, Hint} from 'react-vis';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../UI/Spinner';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles, Grid } from '@material-ui/core';
import { DEFAULT_GLOBALITY_FILTER, DEFAULT_RANGE_FILTER, DEFAULT_PHASE_FILTER, DEFAULT_STATUS_FILTER, DEFAULT_PRIORITY_FILTER } from '../../../utils/consts';


const STATS_QUERY = gql`
  query statistics($reportName: String, $filter: [RiskStatsFilterInputObject!]) {
    priorityStatistics(reportName: $reportName, filter: $filter) { 
      label
      color
      value
    }
    currentReport {
      label
    }
  }
`;

class Chart extends Component {
  state = {
    value: false,
  };

  render() {
    const { classes, stats, reportName } = this.props;
    const {value} = this.state;

    let total = 0;
    
    if(stats && stats.length > 0) {
      total = stats.map((s) => s.value).reduce(function(previousValue, currentValue) {
        return previousValue + currentValue;
      });
    }      

    return (
      <Grid container item style={{maxWidth: 400}} alignItems="center">
      <Grid container>
        <Grid item xs={6}>
          <Typography color="textSecondary" gutterBottom style={{fontSize: 20}}>
            Report: <span style={{color: 'black', fontWeight: 'bold'}}>{reportName}</span>
          </Typography>        
        </Grid>
        <Grid item xs={6}>
          <Typography color="textSecondary" style={{fontSize: 20, textAlign: 'right'}}>
            Total: <span style={{color: 'black', fontWeight: 'bold'}}>{total}</span>
          </Typography>        
        </Grid>
      </Grid>
      <RadialChart
        style={{border: '3 solid red'}}
        colorType="category"
        colorDomain={[0, 1, 2]}
        colorRange={['lime', 'yellow', 'red']}
        className={classes.chart}
        innerRadius={100}
        radius={140}
        getAngle={d => d.value}
        data={ stats || [] }
        onValueMouseOver={v => this.setState({value: v})}
        onSeriesMouseOut={v => this.setState({value: false})}
        width={340}
        height={340}
        padAngle={0.04}
        showLabels={true}
        labelsRadiusMultiplier={1.2}
        labelsStyle={{ fontWeight: 'bold' }}
      >
        {value !== false && false && 
          <Hint value={value}>
          <div style={{background: 'green'}}>
            <h3>{value.label}</h3>
            <p>{value.description}</p>
          </div>
        </Hint>
        }
      </RadialChart>
      </Grid>
    );
  }
}

const withQuery = (WrappedComponent) => (props) => (
  <Query query={STATS_QUERY} 
    variables={{
        reportName: props.reportName,
        filter: [
          {
            name: 'phase',
            includeValues: props.filters ? props.filters.phase : DEFAULT_PHASE_FILTER
          },
          {
            name: 'status',
            includeValues: props.filters ? props.filters.status : DEFAULT_STATUS_FILTER
          },
          {
            name: 'globality',
            includeValues: props.filters ? props.filters.globality : DEFAULT_GLOBALITY_FILTER
          },
          {
            name: 'range',
            includeValues: props.filters ? props.filters.range : DEFAULT_RANGE_FILTER
          },
          {
            name: 'priority',
            includeValues: props.filters ? props.filters.priority : DEFAULT_PRIORITY_FILTER
          },
        ]
    }}>
    {({ data, loading, error }) => {
      const stats = data ? data.priorityStatistics : [];
      const reportName = data ? data.currentReport.label : '';
      return <WrappedComponent {...props} stats={stats} loading={loading} reportName={props.reportName || reportName}/>;
    }}
  </Query>
);

const PrioritiesChart = (props) => {
  const { loading, stats, classes } = props;
  return (
    <Card style={{minWidth: 360, height: 400}}>
      <CardContent>
      {loading ? <Spinner /> : stats && <Chart stats={stats} classes={classes} reportName={props.reportName} />}
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  chart: {
    'rv-radial-chart__series--pie__slice:hover': {
      stroke: '$black !important',
      strokeWidth: '2px !important'
    }    
  }
});

export default withStyles(styles)(withQuery(PrioritiesChart));