import React, { PropsWithChildren } from 'react';
import RiskReportTable from './RiskReportTable';
import withQuery, { IWithReport } from './withQuery';


const HistoryTable: React.FC<PropsWithChildren<IWithReport>> = (props) => {
  return (<RiskReportTable {...props} />);
}

export default withQuery(HistoryTable);
