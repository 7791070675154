import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import * as compose from 'lodash.flowright';


class Logout extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.root} spacing={2} alignItems="center" justify="center">
        <Grid item xs={3}>
          <Paper elevation={5} className={classes.paper}>
            <Typography component="div">
              <Box textAlign="justify" m={1} fontWeight="fontWeightMedium" fontStyle="italic" fontSize={20}>
                Thank you for using the application. 
              </Box>

              <Box textAlign="justify" m={1} fontWeight="fontWeightMedium" fontStyle="italic" fontSize={16}>
                <Link href="/login">
                  Login
                </Link>&nbsp;again
              </Box>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  root: {
    height: 400
  },
  paper: {
    height: 200,
    paddingTop: 40,
    paddingLeft: 20

  },
});

export default compose(
  withStyles(styles),
)(Logout);
