import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withApollo } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import RiskGroupsCreateMutation from '../../mutations/RiskGroupsCreateMutation';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AddDialog extends Component {
  handleAdd = async () => {
    const { client, handleAddDialogClose } = this.props;

    try {
      let result = await client.mutate({
        mutation: RiskGroupsCreateMutation,
        variables: {
          input: { name: this.state.name, abbreviation: this.state.abbreviation, leaderId: this.state.leader.id, parentGroupId: parseInt(this.state.riskGroup.id) }
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.errors[0].message});
      } else 
      if (result != null && result.data.riskGroupsCreate != null && result.data.riskGroupsCreate.errors != null && result.data.riskGroupsCreate.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.data.riskGroupsCreate.errors[0].message});
      } else {
        handleAddDialogClose(result.data.riskGroupsCreate.riskGroups);
      }
    } catch (err) {
      console.error(err);
      this.setState({...this.state, errorMessage: err.message});
    }
  };

    constructor(props) {
      super(props);
      this.state = {
        name: null,
        abbreviation: null,
        leader: null,
        parentGroup: null,
        number: null,
        default_range: null,
        default_globality: null,
        errorMessage: null
      }
    }

    render() {
      const {
        open,
        handleAddDialogClose,
        classes, 
        groups,
        users,
      } = this.props;

      return (<Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleAddDialogClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Create new risk group</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Name"
                            multiline
                            onChange={(event) => {this.setState({...this.state, name: event.target.value}) }}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Abbreviation"
                            multiline
                            onChange={(event) => {this.setState({...this.state, abbreviation: event.target.value})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Group Leader</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(event) => {
                            this.setState({...this.state, leader: users.find((u) => u.name === event.target.value)})
                          }}
                        >
                          {users && users.map((user) => (
                            <MenuItem value={user.name}>{user.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Parent group</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(event) => 
                            this.setState({...this.state, riskGroup: groups.find((g) => g.name === event.target.value)})
                          }>
                          {groups && groups.map((group) => (
                              <MenuItem key={group.id} value={group.name}>{group.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.errorMessage ? <Alert severity="warning">{this.state.errorMessage}</Alert> : ''}
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleAddDialogClose(null)}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button variant="contained"  color="primary" onClick={() => this.handleAdd()}>Create</Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                </Dialog>)
    }
}
const styles = theme => ({
    formControl: {
        width: '300px'
    }
});

export default compose(
  withStyles(styles),
  withApollo
)(AddDialog);
    