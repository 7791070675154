import { withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import ReactDataGrid, { Column } from 'react-data-grid';
import { IWithHistory } from './withQuery';
import moment from 'moment';
import { LongTextFormatter, OvercostFormatter, OvertimeFormatter, PriorityFormatter, ProbabilityFormatter } from '../formatters';
import { HistoryRow } from './types';
import { Row as GridRow } from 'react-data-grid';
import { ContextMenuTrigger } from 'react-contextmenu';


function RowRenderer(props: any) {
  return (
    <ContextMenuTrigger id="grid-context-menu" collect={() => ({ rowIdx: props.rowIdx })}>
      { props.row.status === 'CLOSED' || props.row.status === 'LINK' || props.row.status === 'RELOCATED'
        ? <div className={"row-" + props.row.status.toLowerCase()}><GridRow {...props}/></div>
        : <GridRow {...props} />}
    </ContextMenuTrigger>
  );
};


const HistoryRows: React.FC<IWithHistory> = ({ rows }) => {
  const columns = useMemo((): Column<HistoryRow>[] => {
    return [
      { name: "Updated at",    key: "updatedAt",         resizable: true, width: 200 },
      { name: "Updated by",    key: "updatedBy",         resizable: true, width: 180 },
      { name: "ID",            key: "identifier",        resizable: true, width: 100 },
      { name: "Title",         key: "title",             resizable: true, width: 180 },
      { name: "Specification", key: "specification",     resizable: true, width: 500, formatter: (({row}) => <LongTextFormatter value={row.specification} /> )},
      { name: "Category",      key: "category",          resizable: true, width: 180 },
      { name: "Subcategory",   key: "subcategory",       resizable: true, width: 160 },
      { name: "Cost",          key: "overcost",          resizable: true, width: 140, formatter: ({ row }) => (<OvercostFormatter value={row.overcost} />)},
      { name: "Time",          key: "overtime",          resizable: true, width: 140, formatter: ({ row }) => (<OvertimeFormatter value={row.overtime} row={row} />)},
      { name: "Impact",        key: "impact",            resizable: true, width: 140 },
      { name: "Probability",   key: "probability",       resizable: true, width: 140, formatter: ({ row }) => (<ProbabilityFormatter value={row.probability} />), },
      { name: "Priority",      key: "priority",          resizable: true, width:  80, formatter: ({ row }) => (<PriorityFormatter value={row.priority} />) },
      { name: "Risk Owner",    key: "owner",             resizable: true, width: 180 },
      { name: "Response plan", key: "migrationScenario", resizable: true, width: 300, formatter: (({row}) => <LongTextFormatter value={row.migrationScenario} /> )},
      { name: "Strategy",      key: "strategy",          resizable: true, width: 140 },
      { name: "Status",        key: "status",            resizable: true, width: 140 },
      { name: "Phase",         key: "phase",             resizable: true, width: 140 },
      { name: "Globality",     key: "globality",         resizable: true, width: 140 },
      { name: "Range",         key: "range",             resizable: true, width: 140 },
      { name: "Iteration",     key: "report",            resizable: true, width: 140 }
    ]
  }, []);

  const historyRows = rows.map((r) => {
    return {
      ...r,
      updatedAt: moment(r.updatedAt).format('yyyy-MM-DD / HH:mm')
    }
  }); 

  return (
        <ReactDataGrid
          style={{ width: '100%', height: window !== undefined ? window.innerHeight * 0.9 : 100}}
          columns={columns}
          rows={historyRows}
          rowClass={row => row.status === 'CLOSED' ? 'highlight' : undefined}
          rowRenderer={RowRenderer}
        />
  );
}

const styles = () => ({
  cell: {
    padding: '20px 5px 10px 5px',
  },
});

export default withStyles(styles)(HistoryRows);
