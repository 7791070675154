import React, { Component } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { withStyles, Grid, Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RESET_PASSWORD_MUTATION = gql`
  mutation UsersResetPassword($email: String!, $resetToken: String!, $password: String!, $passwordConfirmation: String!) {
    usersResetPassword(input: { email: $email, resetToken: $resetToken, newPassword: $password, newPasswordConfirmation: $passwordConfirmation } ) {
      user {
        id
        name
        email
      }
      errors {
        name
        message
      }
    }
  }
`;

const withResetMutation = (WrappedComponent) => (props) => {
  const [usersResetPassword] = useMutation(RESET_PASSWORD_MUTATION);
  return <WrappedComponent {...props} reset_password_handler={usersResetPassword} />;
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
      message: null,
      password: '',
      password_confirmation: ''
    };  
  }

  onSubmitReset = async () => {
    const { reset_password_handler } = this.props;

    if (this.state.password !== this.state.password_confirmation) {
      this.setState({...this.state, message: "Passwords do not match"})
    } else {
      try {
        let response = await reset_password_handler({ variables: { 
          resetToken: this.props.match.params.reset_token,
          email: this.props.match.params.email,
          password: this.state.password,
          passwordConfirmation: this.state.password_confirmation 
        }});

        let data = response.data.usersResetPassword;

        if(data.errors && data.errors.length > 0) {
          let msg = data.errors[0].message;
          this.setState({...this.state, message: msg});
        } if(data.user && data.user.id) {
          this.setState({...this.state, finished: true })
        }
      } catch (error) {
        this.setState({...this.state, message: error})
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} spacing={2} alignItems="center" justify="center">
        <Grid item xs={3}>
          <Paper elevation={5} className={classes.paper}>
            <Typography component="div">
              <Box textAlign="justify" m={1} fontWeight="fontWeightMedium" fontStyle="italic" fontSize={20}>
                Reset Password 
              </Box>
            </Typography>
            {this.state.finished ? 
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p>Password changed&nbsp;-&nbsp;
                  <Link href="/login">
                    Login
                  </Link>
                </p>
              </Grid>
            </Grid>
              :
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <TextField
                id="standard-password-email"
                label="Email"
                value={this.props.match.params.email}
                disabled
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                id="standard-password-input"
                label="Password"
                type="password"
                required
                onChange={(event) => {this.setState({...this.state, password: event.target.value}) }}
              />
              </Grid>
              <Grid item xs={12}>
              <TextField
                id="standard-password-confirmation-input"
                label="Password Confirmation"
                type="password"
                required
                onChange={(event) => {this.setState({...this.state, password_confirmation: event.target.value}) }}
              />
              </Grid>
              <Grid item xs={12}>
                {this.state.message ? <Alert severity="warning">{this.state.message}</Alert> : <p className={classes.placeholder}></p>}
              </Grid>
              <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={this.onSubmitReset}>Reset</Button>
              </Grid>
            </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  root: {
    height: 500
  },
  paper: {
    height: 400,
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20
  },
  placeholder: {
    height: 20
  }
});

export default withStyles(styles)(withResetMutation(ResetPassword));
