import withQuery from './withQuery';
import Selector from './Selector';
import { withApollo } from 'react-apollo';


const RiskEntitiesPage = (props) => {
  const FilterSelector = withApollo(withQuery(Selector));

  return <FilterSelector {...props} />;
};

export default withQuery(RiskEntitiesPage);