import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';


class MessageDialog extends Component {
    render() {
      const {messageDialogOpen, handleMessageDialogClose, classes, message} = this.props;
      const open = messageDialogOpen === null ? false : messageDialogOpen;

      return (<Dialog
                maxWidth={'sm'}
                open={open}
                onClose={handleMessageDialogClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Warning</DialogTitle>
                  <DialogContent>
                    <Alert severity="error">
                      <strong>Error: </strong> {message}
                    </Alert>      
                    <Button style={{marginTop: 15}} variant="contained" onClick={() => handleMessageDialogClose()}>Close</Button>
                  </DialogContent>
              </Dialog>);
    }
};

const styles = theme => ({
});

export default compose(
  withStyles(styles),
)(MessageDialog);
    