import React, { useEffect } from 'react';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../UI/Spinner';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import Layout from '../UI/Layout';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import NewReportRiskConfigMutation from '../../mutations/NewReportRiskConfigMutation';
import Alert from '@material-ui/lab/Alert';


const REPORT_QUERY = gql`
  query currentReport {
    currentReport {
      id
      label
      startDate
    }
  }
`;

const NewReportConfig = (props) => {
  const { loading, inReport, classes, client } = props;

  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState(inReport);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    setReport(props.report)
  }, [props.report]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };  

  const handleAdd = async () => {
    handleClose();
  
    try {
      let result = await client.mutate({
        mutation: NewReportRiskConfigMutation,
      });

      if (result.errors != null && result.errors.length > 0) {
        setErrorMessage(result.errors[0].message);
      } else {
        setReport(result.data.riskConfigNewReport.report);
        setSuccessMessage(`New report [${result.data.riskConfigNewReport.report.label}] created !`);
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
    }
  };

  return (<Grid container xs={12} alignContent="center" alignItems="center" direction="column" justify="center" spacing={3}>
    <Grid item>
      <Typography>
        Current quarter: &nbsp;
        <span style={{fontWeight: 'bold'}}>{report ? report.label : ''}</span>
      </Typography>
      <Typography>
        [start date: &nbsp;
        <span style={{fontWeight: 'bold'}}>{report ? report.startDate : ""}</span>]
      </Typography>
    </Grid>
    <Grid item>
      <Button variant="contained" color="secondary" onClick={() => handleClickOpen()}>
        New Reassessment Period
      </Button>
    </Grid>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning !</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontWeight:'bold', backgroundColor: '#FFFFCC'}}>

            <Alert severity="error">
            You are starting new Risk Reassessement iteration.
            </Alert>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained"  onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button variant="contained"  onClick={handleAdd} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  </Grid>);
};


const withQuery = (WrappedComponent) => (props) => (
  <Query query={REPORT_QUERY}>
    {({ data, loading, error }) => {
      const report = data ? data.currentReport : {};
      return <WrappedComponent {...props} report={report} loading={loading} />;
    }}
  </Query>
);

const NewReportConfigPage = (props) => {
  const { history, loading, report, classes, client } = props;
  return (
    <Layout title="New Report" history={history} leftManuType="administration">
      {loading ? <Spinner /> : <NewReportConfig report={report} classes={classes} client={client} />}
    </Layout>
  );
};

const styles = theme => ({
});

export default withStyles(styles)(withQuery(withApollo(NewReportConfigPage)));