import gql from 'graphql-tag';

const CombineRiskEntityMutation = gql`
  mutation Combine($input: CombineMutationInput!) {
    riskEntitiesCombine(input: $input) {
      riskEntities {
          id
          identifier
          specification
          migrationScenario
          owner {
            name
          }
          createdBy {
            name
          }
          updatedBy {
            name
          }

          category {
            label
          }
          subcategory {
            label
          }
          strategy {
            label
          }
          phase {
            label
          }
          status {
            label
          }
          source {
            label
          }
          priority {
            label
          }
          impact {
            label
          }
          globality {
            label
          }
          range {
            label
          }
          report {
            label
          }
          riskGroup {
            name
          }
          probability
          overtime
          overcost
          title
          canWrite
          ready          
      }

    errors {
      name
      message
    }
    }
  }
`;

export default CombineRiskEntityMutation;
