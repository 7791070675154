export default function(e) {
  return {
    ...e,
    status:       e.status ? e.status.label : '',
    probability:  ( e.probability !== null && e.probability !== '') ? e.probability : '--',
    overtime:     ( e.overtime    !== null && e.overtime    !== '') ? e.overtime    : '--',
    overcost:     ( e.overcost    !== null && e.overcost    !== '') ? e.overcost    : '--',
    category:     e.category ? e.category.label : '',
    subcategory:  e.subcategory ? e.subcategory.label : '',
    strategy:     e.strategy ? e.strategy.label : '',
    impact:       e.impact ? e.impact.label : '',
    phase:        e.phase ? e.phase.label : '',
    source:       e.source ? e.source.label : '',
    priority:     e.priority ? e.priority.label : '',
    owner:        e.owner ? e.owner.name : '',
    updatedBy:    e.updatedBy ? e.updatedBy.name : '',
    riskGroup:    e.riskGroup ? e.riskGroup.name : '',
    globality:    e.globality ? e.globality.label : '',
    range:        e.range ? e.range.label : '',
    report:       e.report ? e.report.label : ''
  };
};
