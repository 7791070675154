import { Button, Card, CardContent, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import React from 'react';
import { Query, withApollo } from 'react-apollo';
import Layout from '../UI/Layout';
import Spinner from '../UI/Spinner';
import UsersProfileUpdateMutation from '../../mutations/UsersProfileUpdateMutation';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const USER_QUERY = gql`
  query user {
    currentUser {
      id
      email
      firstName
      lastName
      name
      phone
      position
      abbreviation
      office
      leaderGroups
      riskGroup
      createdAt
    }
  }
`;

const ProfilePage = (props) => {
  const { client, classes } = props;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [user, setUser] = React.useState(props.user);
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

  async function handleSave() {
    if((password && password.length > 0) || ( passwordConfirmation && passwordConfirmation.length > 0 )) {
      if(password !== passwordConfirmation) {
        setErrorMessage("Password does not match confirmation")
        return;
      }
    }

    try {
      let result = await client.mutate({
        mutation: UsersProfileUpdateMutation,
        variables: {
          input: {
            userId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            position: user.position,
            abbreviation: user.abbreviation,
            phone: user.phone,
            office: user.office,
            password: password,
            passwordConfirmation: passwordConfirmation
          }
        }
      });

    if ((result.errors != null && result.errors.length > 0) || (result.data.usersProfileUpdate.errors != null && result.data.usersProfileUpdate.errors.length > 0)) {
      setErrorMessage(result.data.usersProfileUpdate.errors[0].message);
    } else {
      setErrorMessage('');
      setSuccessMessage('Profile updated');
      setUser(result.data.usersProfileUpdate.user);
    }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
    }
  };

  return (
      <Grid container xs={12} justify="center">
      <Grid item>
      <Card style={{maxWidth: 800, minWidth: 500, display: 'flex'}}>
        <CardContent>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={10}>
              <Typography gutterBottom variant="h5" component="h2">
                User Profile
              </Typography>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              <Button variant="contained" color="primary" onClick={() => handleSave()}>Save</Button>
            </Grid>
            <Grid item xs={12}>
              {errorMessage ? <Alert severity="warning">{errorMessage}</Alert> : ''}
              {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-email"
                label="Email"
                value={user.email}
                className={classes.textField}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-first-name"
                label="First Name"
                className={classes.textField}
                value={user.firstName}
                onChange={(event) => setUser({...user, firstName: event.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-last-name"
                label="Last Name"
                className={classes.textField}
                value={user.lastName}
                onChange={(event) => setUser({...user, lastName: event.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-phone"
                label="Phone"
                className={classes.textField}
                value={user.phone}
                onChange={(event) => setUser({...user, phone: event.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-position"
                label="Position"
                className={classes.textField}
                value={user.prosition}
                onChange={(event) => setUser({...user, prosition: event.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-abbreviation"
                label="Abbreviation"
                className={classes.textField}
                value={user.abbreviation}
                onChange={(event) => setUser({...user, abbreviation: event.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-office"
                label="Office"
                className={classes.textField}
                value={user.office}
                onChange={(event) => setUser({...user, office: event.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-member"
                label="Member of group"
                className={classes.textField}
                value={user.riskGroup}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-member"
                label="Leader of group"
                className={classes.textField}
                value={user.leaderGroups}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-password"
                label="Password"
                className={classes.textField}
                type="password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-password-confirmation"
                className={classes.textField}
                label="Password Confirmation"
                type="password"
                onChange={(event) => setPasswordConfirmation(event.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

const withQuery = (WrappedComponent) => (props) => (
  <Query query={USER_QUERY} variables={{}}>
    {({ data, loading, error }) => {
      const user = data ? data.currentUser : [];
      return <WrappedComponent {...props} 
                loading={loading}
                user={user} />
    }}
  </Query>
);

const ProfilePageWrapper = (props) => {
  const { loading, classes, client, history, user} = props;
  return (
    <Layout title="User profile" history={history} leftManuType="dashboard">
      {loading && user ? <Spinner /> : <ProfilePage
                                  classes={classes}
                                  user={user}
                                  history={history}
                                  client={client} />}
    </Layout>
  );
};

const styles = () => ({
  textField: {
    width: 400
  }
});

export default withStyles(styles)(withQuery(withApollo(ProfilePageWrapper)));