import React, { Component } from 'react';
import { TextField, Button, Typography, Link, Grid, Box, Container, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { graphql } from "react-apollo";
import * as compose from 'lodash.flowright';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import UsersSendResetMutation from '../mutations/UsersSendResetMutation';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://karolkosk.pl/">
        RMS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

class Recovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      recoveryEmail: '',
      message: '',
    };
  }

  handleChangeInput = (prop) => (event) => {
    this.setState({...this.state, [prop]: event.target.value });
  };

  handleReset = () => {
    this.props
      .UsersSendResetMutation({
        variables: {
          input: {
            email: this.state.recoveryEmail.toLowerCase(),
          },
        },
      })
      .then(({ data }) => {
        if (data.usersSendReset.errors) {
          console.log(data.usersSendReset.errors[0].message)
          this.setState({ ...this.state, error: true, message: data.usersSendReset.errors[0].message });
          return;
        }
        // this.props.history.push(this.props.location.state ? this.props.location.state.from : '/dashboard');
        this.setState({...this.state, error: false, message: `Reset link sent to: ${this.state.recoveryEmail}` })
      })
      .catch(() => {
        this.setState({...this.state, error: true, message: 'there was an error sending the mutation' });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <LoginView
        classes={classes}
        state={this.state}
        handleChangeInput={this.handleChangeInput}
        handleReset={this.handleReset}
      />
    );
  }
}

const LoginTextField = (props) => {
  const { recoveryEmail, handleChangeInput, classes } = props;

  return (<TextField
            id="login"
            value={recoveryEmail}
            onChange={handleChangeInput('recoveryEmail')}
            className={classes.loginInput}
            InputLabelProps={{ focused: false }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />);
};

const LoginButton = (props) => {
  const { handleReset, classes } = props;

  return (<Button 
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleReset() }>
            <Typography className={classes.loginButtonText}>Send reset link</Typography>
          </Button>);
};

const LoginView = ({ classes, state, handleChangeInput, handleReset }) => {
  const { recoveryEmail, error, message } = state;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Resetting password
        </Typography>
        <form className={classes.form} noValidate>
          <LoginTextField recoveryEmail={recoveryEmail} handleChangeInput={handleChangeInput} classes={classes} />
          <p style={{color: error ? 'red' : 'green'}}>{message}</p>
          <LoginButton classes={classes} handleReset={handleReset} />
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
const LoginFormWithData = compose(
  graphql(UsersSendResetMutation, {
    name: 'UsersSendResetMutation',
    options: () => ({ context: { login: true } }),
  })
)(Recovery);

export default withRouter(withStyles(styles)(LoginFormWithData));
