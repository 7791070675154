import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { from } from 'apollo-link';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BACKEND_URL, SENTRY_DSN, ENV_NAME } from './utils/config';
import store from './store/store';
import { logout } from './utils/logout';
import { setGraphQLErrors, setNetworkError } from './actions/error-actions';
import { updateServiceWorker } from './actions/misc-actions';


Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
  environment: ENV_NAME,
});

const authLink = setContext((_, { headers }) => {
  const redux = localStorage.getItem('redux');
  let token = (redux && JSON.parse(redux).token) || {};
  token = token.authToken && token.authToken.id;
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const errorsLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    store.dispatch(setGraphQLErrors(graphQLErrors, operation));
  }

  // Dispatch the received network error only when the statusCode is not 401 (Unauthorized)
  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === 401) {
      logout();
    } else {
      store.dispatch(setNetworkError(networkError, operation));
    }
  }
});

console.log(BACKEND_URL);
const link = new HttpLink({ uri: `${BACKEND_URL}/graphql` });

// const defaultOptions = {
//   watchQuery: {
//     errorPolicy: 'all',
//     fetchPolicy: 'network-only',
//   },
//   query: {
//     errorPolicy: 'all',
//     fetchPolicy: 'network-only',
//   },
//   mutate: {
//     errorPolicy: 'all',
//   },
// };

const client = new ApolloClient({
  link: from([authLink, errorsLink, link]),
  cache: new InMemoryCache(),
});

const onServiceWorkerUpdate = () => {
  store.dispatch(updateServiceWorker());
};

const ReduxApp = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </Provider>
);

ReactDOM.render(<ReduxApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register(onServiceWorkerUpdate);
