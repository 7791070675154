import React, { PropsWithChildren } from 'react';
import Spinner from '../../UI/Spinner';
import HistoryRows from './HistoryRows';
import withQuery, { IWithHistory } from './withQuery';
import { HistoryRow } from './types';


export interface HistoryTableProps {
  loading?: boolean;
  rows: HistoryRow[];
}

const HistoryTable: React.FC<PropsWithChildren<IWithHistory>> = (props) => {
  return (
    <div>
      {props.loading ? <Spinner /> : props.rows && <HistoryRows {...props} />}
    </div>
  );
}

export default withQuery(HistoryTable);
