import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { Checkbox, DialogContent, DialogTitle, FormControlLabel, FormLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withApollo } from 'react-apollo';
import UsersUpdateMutation  from '../../mutations/UsersUpdateMutation';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class EditDialog extends Component {
  handleSave = async () => {
    const { client, handleDialogClose, groups } = this.props;
    const group = groups.find((g) => g.name === this.state.user.riskGroup)

    try {
      let result = await client.mutate({
        mutation: UsersUpdateMutation,
        variables: {
          input: {
            userId: this.state.user.id,
            firstName: this.state.user.firstName, 
            lastName: this.state.user.lastName, 
            email: this.state.user.email,
            position: this.state.user.position, 
            abbreviation: this.state.user.abbreviation, 
            phone: this.state.user.phone,
            office: this.state.user.office, 
            role: this.state.user.role, 
            status: this.state.user.status,
            riskGroupId: group ? parseInt(group.id) : null 
        }},
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.errors[0].message});
      } else 
      if (result != null && result.data.usersUpdate != null && result.data.usersUpdate.errors != null && result.data.usersUpdate.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.data.usersUpdate.errors[0].message});
      } else {
        handleDialogClose(result.data.usersUpdate.user);
      }
    } catch (err) {
      console.error(err);
      this.setState({...this.state, errorMessage: err.message});
    }
  };

    constructor(props) {
      super(props);
      this.state = {
        user: props.user,
        errorMessage: null
      }
    }

    componentDidUpdate(prevProps) {
      if ( (prevProps.user ? prevProps.user.id : -1) !== ( this.props.user ? this.props.user.id : -1 )) {
        this.setState({...this.state, user: this.props.user});
      }
    }    

    render() {
      const {
        dialogOpen,
        handleDialogClose,
        classes,
        groups,
      } = this.props;

      return (<Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Edit user</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Id"
                            value={this.state.user ? this.state.user.id : ''}
                            style={{width: 500}}
                            disabled
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="First name"
                            value={this.state.user ? this.state.user.firstName : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, firstName: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Last name"
                            value={this.state.user ? this.state.user.lastName : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, lastName: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Email"
                            value={this.state.user ? this.state.user.email : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, email: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Position"
                            value={this.state.user ? this.state.user.position : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, position: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Abbreviation"
                            value={this.state.user ? this.state.user.abbreviation : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, abbreviation: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Phone"
                            value={this.state.user ? this.state.user.phone : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, phone: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Office"
                            value={this.state.user ? this.state.user.office : ''}
                            onChange={(event) => {this.setState({...this.state, user: {...this.state.user, office: event.target.value}})}}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Risk group</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.user ? this.state.user.riskGroup : ''}
                          onChange={(event) => {
                            this.setState({
                                      ...this.state, 
                                      user: {...this.state.user, riskGroup: groups.find((g) => g.name === event.target.value).name}
                            })
                          }}
                        >
                          {groups && groups.map((group) => (
                              <MenuItem key={group.id} value={group.name}>{group.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Role</FormLabel>
                      <RadioGroup row aria-label="position" name="role" value={this.state.user ? this.state.user.role : ''}
                        onChange={(event) => {
                          this.setState({...this.state, user: {...this.state.user, role: event.target.value}})
                        }}
                      >
                        <FormControlLabel
                          value="member"
                          control={<Radio color="primary" />}
                          label="Member"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="admin"
                          control={<Radio color="primary" />}
                          label="Admin"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="risk_coordinator"
                          control={<Radio color="primary" />}
                          label="Risk Coordinator"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox value="remember" color="primary" checked={this.state.user ? ( this.state.user.status === 'locked' ) : false}
                            onChange={(event) => {
                              const status = event.target.checked ? 'locked' : 'active';
                              this.setState({...this.state, user: {...this.state.user, status: status}})
                            }} />
                        }
                        label="Locked"
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.errorMessage ? <Alert severity="warning">{this.state.errorMessage}</Alert> : ''}
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleDialogClose(null)}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button variant="contained"  color="primary" onClick={() => this.handleSave()}>Save</Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                </Dialog>)
    }
}
const styles = theme => ({
    formControl: {
        width: '300px'
    }
});

export default compose(
  withStyles(styles),
  withApollo
)(EditDialog);
    