import React, { Component } from 'react';
import * as compose from 'lodash.flowright';
import { DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CreateRiskEntityMutation from '../../mutations/CreateRiskEntityMutation';
import remapEntity from '../../utils/remapRiskTicketFields';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DictionaryMapper from '../../utils/dictionaryMapper';
import { Alert } from '@material-ui/lab';
import { withApollo } from 'react-apollo';


function findGroup(name, groups) {
  let result = null;
  groups.forEach((group) => { if(group.name === name) {result = group}} );

  return result;
};

class AddModal extends Component {
  handleAdd = async () => {
    const { client, handleAddModalClose } = this.props;

    this.setState({ loading: true });

    try {
      let result = await client.mutate({
        mutation: CreateRiskEntityMutation,
        variables: {
          input: { riskGroup: this.state.group, specification: this.state.specification, phase: this.state.phase, owner: this.state.owner }
        },
      });

      if (result.errors != null && result.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.errors[0].message});
      } else 
      if (result != null && result.data.riskEntitiesCreate != null && result.data.riskEntitiesCreate.errors != null && result.data.riskEntitiesCreate.errors.length > 0) {
        this.setState({...this.state, errorMessage: result.data.riskEntitiesCreate.errors[0].message});
      } else {
        handleAddModalClose(remapEntity(result.data.riskEntitiesCreate.riskEntity));
      }
    } catch (err) {
      console.error(err);
      this.setState({...this.state, errorMessage: err.message});
    }

    this.setState({
      loading: false,
    });
  };

    constructor(props) {
      super(props);
      this.state = {
        group: null,
        specification: null,
        owner: null,
        phase: null,
        selectedGroup: null,
        errorMessage: null
      }
    }

    render() {
      const {
        addModalOpen,
        handleAddModalClose,
        classes, 
        currentUser,
        dictionaries
      } = this.props;

      const groups = currentUser.myGroups;

      return (<Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={addModalOpen}
                onClose={handleAddModalClose}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Create new risk event</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Specification"
                            multiline
                            rowsMax={5}
                            onChange={(event) => {this.setState({...this.state, specification: event.target.value}) }}
                            style={{width: 500}}
                          />
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Group</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.group}
                          onChange={(event) => this.setState({...this.state, group: event.target.value, selectedGroup: findGroup(event.target.value, groups)}) }
                        >
                          {groups.map((group) => (
                              <MenuItem key={group.id} value={group.name}>{group.name}</MenuItem>
                          ))
                          }
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Risk Owner</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.owner}
                          onChange={(event) => {
                            this.setState({...this.state, owner: event.target.value })
                          }}
                        >
                          { this.state.selectedGroup && this.state.selectedGroup.users.map((user) => (
                            <MenuItem key={user.id} value={user.name}>{user.name}</MenuItem>
                          ))
                          }
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Phase</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.phase}
                          onChange={(event) => {
                            this.setState({...this.state, phase: event.target.value }) 
                          }}
                        >
                          {DictionaryMapper(dictionaries, 'Dictionaries::Phase').map((item) => (
                              <MenuItem key={item.id} value={item.value} disabled={item.value === 'CONSTRUCTION'}>{item.value}</MenuItem>
                          ))
                          }
                        </Select>
                      </FormControl>          
                    </Grid>
                    <Grid item xs={12} style={{width: '100%', '& > * + *': { marginTop: 10, marginBottom: 10 }}}>
                      {this.state.errorMessage &&
                      <Alert severity="error">
                        <strong>Error: </strong> {this.state.errorMessage}
                      </Alert>      
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleAddModalClose(null)}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button variant="contained"  color="primary" onClick={() => this.handleAdd()}>Create</Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                </Dialog>)
    }
}
const styles = theme => ({
    formControl: {
        width: '140px'
    }
});

const AddModalWrapper = (props) => {
  const {loading} = props;
  return (
      <div>
        {loading ? null : <AddModal {...props} />}
      </div>
  );
};

export default compose(
  withStyles(styles),
  withApollo)(AddModalWrapper);
    